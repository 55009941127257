import { format } from "date-fns";
import {
  ActivityDetailsView,
  ActivityTemplateAttributeView,
  CampaignResponseView,
  CampaignSaveAction,
  CampaignStep,
  HeaderAction,
  Step,
} from "./CampaignPage.types";
import {
  Template,
  TemplateType,
} from "../../../models/template/template.types";

export const defaultTemplateAttributes = [];

export function getCampaignSteps(
  id: string | undefined,
  detailsCompleted: boolean,
  designCompleted: boolean,
  audienceCompleted: boolean,
  scheduleCompleted: boolean
): CampaignStep[] {
  return [
    {
      name: "Details",
      completed: detailsCompleted,
      step: "details",
      link: "/",
    },
    {
      name: "Design",
      completed: designCompleted,
      step: "design",
      link: "/",
    },
  ];
}

interface HeaderActions {
  changeTemplate: () => void;
  openSendEmailTest: () => void;
  openPreviewDialog: () => void;
  openSavetemplateDialog: () => void;
}

function isAllowedToChangeTemplate(
  status: CampaignResponseView["campaignStatus"]
) {
  return !["QUEUED", "LAUNCHED", "PAUSED", "FAILED"].includes(status || "");
}

export function getHeaderActions(
  activeStep: Step,
  templateEditing: boolean,
  templateLoaded: boolean,
  activityId: string | undefined,
  actions: HeaderActions
): HeaderAction[] {
  if (activeStep === "design") {
    return [
      {
        type: "icon",
        text: "BarTop",
        disabled: !activityId && !templateEditing,
        children: [
          {
            text: "Change template",
            onClick: actions.changeTemplate,
            disabled: !templateEditing || !templateLoaded,
          },
        ],
      },
      {
        type: "icon",
        text: "Show",
        onClick: actions.openPreviewDialog,
        disabled: !activityId && !templateEditing,
      },
      {
        type: "text",
        text: "Send test",
        onClick: actions.openSendEmailTest,
        disabled: !activityId && !templateEditing,
      },
    ];
  }
  return [];
}

export function createCampaignInitialValues(): CampaignResponseView {
  const dateFormated = format(new Date(), "[dd MMM, yyyy - hh:mm:ss aaa]");
  return {
    name: `Email name ${dateFormated}`,
  };
}

interface HeaderControlActions {
  gotoNextStep: () => void;
  saveDesign: () => void;
}

export function getHeaderControlAction(
  activeStep: Step,
  nextStepDisabled: boolean,
  actions: HeaderControlActions
): HeaderAction {
  if (activeStep === "design") {
    return {
      type: "text",
      text: "Save",
      onClick: actions.saveDesign,
      disabled: nextStepDisabled,
    };
  } else {
    return {
      type: "text",
      text: "Continue",
      onClick: actions.gotoNextStep,
      disabled: nextStepDisabled,
    };
  }
}

export function createChangeTemplateConfirmOptions(onClick: () => void) {
  return {
    notShowAgainId: "not-show-again-campaign-change-template",
    title: "Change template",
    message:
      "We have automatically saved your changes in the version history. \n\nBy changing templates your edits will not be ported over to the new template. Please confirm if you would like to change your template.",
    actions: [
      { label: "No thanks" },
      {
        label: "Change template",
        onClick: onClick,
        primary: true,
      },
    ],
  };
}

export function requireLeaveConfirmation(
  step: Step | undefined,
  requireSave: CampaignSaveAction
) {
  return false;
}

export async function resolveEjsTags(
  template: Template,
  templateId?: string,
  type?: "HTML" | "DRAG_AND_DROP"
): Promise<ActivityDetailsView> {
  let html = template.templateHtml;
  let css = template.templateCss;
  return {
    id: templateId,
    templateHtml: html,
    templateCss: css,
    editorType: type,
  };
}
