import { ErrorBoundaryGoBack } from "@/components/ErrorBoundary/ErrorBoundaryGoBack";
import { ErrorBoundaryRevalidation } from "@/components/ErrorBoundary/ErrorBoundaryRevalidation";
import { senderDetailsAction } from "@/routes/AccountSettings/SenderDetails/action";
import Subscribers, { subscribersLoader } from "@/routes/Audience/Subscribers";
import CampaignTemplateEditorDetailsPage from "@/routes/Campaigns/Editor/TemplateEditorDetailsPage";
import CampaignTemplateEditorDesignPage from "@/routes/Campaigns/Editor/TemplateEditorDesignPage";
import CampaignTemplateBuilderPage from "@/routes/Campaigns/Builder";
import AutomationTemplateEditorDetailsPage from "@/routes/Automations/Editor/TemplateEditorDetailsPage";
import AutomationTemplateEditorDesignPage from "@/routes/Automations/Editor/TemplateEditorDesignPage";
import { aiTuningRootLoader } from "@/routes/common";
import { formAction } from "@/routes/Forms/Form/action";
import { FormPreviewDialog } from "@/routes/Forms/Form/FormPreviewDialog";
import { FormInfo } from "@/routes/Forms/FormInfo";
import {
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";
import ErrorBoundary from "@/components/ErrorBoundary";
import DashboardLayout from "@/routes/DashboardLayout";
import GeneratedRevenue from "@/routes/GeneratedRevenue";
import OrdersTableRevenue from "@/routes/OrdersTableRevenue";
import { sourcesTableRevenueLoader } from "@/routes/SourcesTableRevenue/loader";
import { ordersTableRevenueLoader } from "@/routes/OrdersTableRevenue/loader";
import SourcesTableRevenue from "@/routes/SourcesTableRevenue";
import { automationsActions } from "@/routes/Automations/action";
import { campaignsActions } from "@/routes/Campaigns/action";
import campaignTemplateEditorLoader from "./Campaigns/Editor/templateEditorLoader";
import automationTemplateEditorLoader from "./Automations/Editor/templateEditorLoader";
import LayoutOnboarding, { OnboardingIndex } from "./LayoutOnboarding";
import PlansPage, {
  planSelectionLoader,
  planSelectionActions,
} from "./PlansPage";
import BrandVoice, { einsteinizeAction } from "./BrandVoice";
import LayoutMain, { mainLayoutLoader } from "./LayoutMain";
import DashboardPage, { dashboardLoader } from "./Dashboard";
import RootRouteIndex from "./RootRoute/RootRouteIndex";
import Automations, { automationsLoader } from "./Automations";
import WelcomeDialog from "./Dashboard/WelcomeDialog";
import Campaigns, { campaignsLoader, draftCampaignsLoader } from "./Campaigns";
import Forms, { formsLoader, formsActions } from "./Forms";
import Audience from "./Audience";
import RootRoute, { rootLoader } from "./RootRoute";
import {
  AccountSettings,
  AiTuning,
  DKIM,
  Plans,
  Profile,
  SenderDetails,
  submitSetupStrategyAction,
} from "./AccountSettings";
import AudienceSources, { audiencesLoader } from "./Audience/Sources";
import { createBrowserRouterWithSentry } from "./routesUtils";
import Calendar from "./Campaigns/Calendar";
import { campaignsCalendarLoader } from "./Campaigns/Calendar/loader";
import Statistics, { StatisticsLoader } from "./Campaigns/Statistics";
import CampaignContainer from "./Campaigns/Campaign";
import CampaignTableContainer from "./Campaigns/Calendar/Table/CampaignTableContainer";
import GridView from "./Campaigns/Calendar/Grid";
import BrandKit from "./AccountSettings/BrandKit";
import HeroImage from "./AccountSettings/HeroImage";
import { heroImageLoader } from "./AccountSettings/HeroImage/loader";
import { BrandKitLoader } from "./AccountSettings/BrandKit/loader";
import { recurringCalendarLoader } from "./Campaigns/Calendar/recurringLoader";
import Workflow from "./Workflow/Workflow";

const router: any = createBrowserRouterWithSentry(
  createRoutesFromElements(
    <Route
      id="root"
      path="/"
      errorElement={<ErrorBoundary />}
      loader={rootLoader}
      shouldRevalidate={({ currentUrl }) =>
        !currentUrl.pathname.startsWith("/main")
      }
      element={<RootRoute />}
    >
      <Route index element={<RootRouteIndex />} />
      <Route path="onboarding" element={<LayoutOnboarding />}>
        <Route index element={<OnboardingIndex />} />
        <Route
          path="plan-selection"
          element={<PlansPage />}
          loader={planSelectionLoader}
          action={planSelectionActions}
          errorElement={<ErrorBoundary />}
          shouldRevalidate={({ formData }) => {
            const couponCode = formData?.get("couponCode");
            return !couponCode;
          }}
        />
        <Route
          id="brand-voice"
          path="brand-voice"
          loader={aiTuningRootLoader}
          shouldRevalidate={({ currentUrl }) =>
            currentUrl.pathname !== "/main/onboarding/brand-voice"
          }
          action={einsteinizeAction}
          errorElement={<ErrorBoundary />}
          element={<BrandVoice />}
        />
      </Route>
      <Route
        path="workflow/:id"
        element={<Workflow page="BUILDER" />}
        errorElement={<ErrorBoundaryGoBack />}
      />
      <Route
        path="main"
        element={<LayoutMain />}
        id="main"
        errorElement={<ErrorBoundary />}
        loader={mainLayoutLoader}
        shouldRevalidate={(params) => {
          const { currentUrl, nextUrl } = params;
          return (
            currentUrl.pathname !== nextUrl.pathname ||
            currentUrl.pathname.includes("/main/account-settings") ||
            currentUrl.pathname.includes("/main/dashboard")
          );
        }}
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route
          path="dashboard"
          element={<DashboardLayout />}
          handle={{
            crumb: () => ({ to: "./dashboard/overview", name: "Dashboard" }),
          }}
          errorElement={<ErrorBoundaryRevalidation />}
        >
          <Route index element={<Navigate to="overview" />} />

          <Route
            path="overview"
            element={<DashboardPage />}
            loader={dashboardLoader}
            errorElement={<ErrorBoundary />}
          >
            <Route
              path="welcome"
              element={<WelcomeDialog redirectPath=".." />}
            />
          </Route>
          <Route
            path="generated-revenue"
            element={<GeneratedRevenue />}
            handle={{
              crumb: () => {
                const { location } = window;
                return { to: location.toString(), name: "Generated revenue" };
              },
            }}
          >
            <Route index element={<Navigate to="sources" />} />
            <Route
              path="sources"
              element={<SourcesTableRevenue />}
              errorElement={<ErrorBoundaryGoBack />}
              loader={sourcesTableRevenueLoader}
            >
              <Route path=":activityType" element={<Outlet />} />
            </Route>
            <Route
              path="orders"
              element={<OrdersTableRevenue />}
              errorElement={<ErrorBoundaryGoBack />}
              loader={ordersTableRevenueLoader}
            />
          </Route>
        </Route>
        <Route
          id="automations"
          path="automations"
          errorElement={<ErrorBoundaryRevalidation />}
          element={<Outlet />}
          handle={{
            crumb: () => ({
              to: "./automations/overview",
              name: "Automations",
            }),
          }}
        >
          <Route
            id="automations-overview"
            path="overview"
            element={<Automations />}
            errorElement={<ErrorBoundary />}
            loader={automationsLoader}
            action={automationsActions}
          >
            <Route index element={<Outlet />} />
            <Route
              path=":id"
              element={<Outlet />}
              errorElement={<ErrorBoundaryGoBack />}
            >
              <Route path="info" />
            </Route>
          </Route>
          <Route path=":id/edit">
            <Route
              id="automations-edit-details"
              loader={automationTemplateEditorLoader}
              element={<AutomationTemplateEditorDetailsPage />}
              path="details"
            />
            <Route
              id="automations-edit-design"
              loader={automationTemplateEditorLoader}
              element={<AutomationTemplateEditorDesignPage />}
              path="design"
            />
          </Route>
        </Route>

        <Route
          id="campaigns"
          path="campaigns"
          errorElement={<ErrorBoundaryRevalidation />}
          element={<Outlet />}
          handle={{
            crumb: () => ({ to: "./campaigns/overview", name: "Campaigns" }),
          }}
        >
          <Route
            id="campaigns-overview"
            path="overview"
            element={<Campaigns />}
            errorElement={<ErrorBoundary />}
          >
            <Route index element={<Navigate to="future-campaigns" />} />
            <Route
              path="statistics"
              loader={StatisticsLoader}
              element={<Statistics />}
            />
            <Route
              path="future-campaigns"
              loader={campaignsLoader}
              action={campaignsActions}
              element={<CampaignContainer />}
            />
            <Route
              path="draft-campaigns"
              loader={draftCampaignsLoader}
              action={campaignsActions}
              element={<CampaignContainer />}
            />
            <Route
              path="past-campaigns"
              loader={campaignsLoader}
              action={campaignsActions}
              element={<CampaignContainer />}
            />
            <Route
              path="recurring-campaigns"
              loader={campaignsLoader}
              action={campaignsActions}
              element={<CampaignContainer />}
            />
          </Route>
          <Route
            path="builder"
            id="campaign-builder"
            element={<CampaignTemplateBuilderPage />}
            errorElement={<ErrorBoundary />}
          />
          <Route path="calendar" id="calender" element={<Calendar />}>
            <Route index element={<Navigate to="future/calendar" />} />
            <Route
              path="future/list"
              loader={campaignsCalendarLoader}
              element={<CampaignTableContainer />}
            />
            <Route
              path="future/calendar"
              loader={campaignsCalendarLoader}
              element={<GridView />}
            />
            <Route
              path="recurring/list"
              loader={recurringCalendarLoader}
              element={<CampaignTableContainer />}
            />
            <Route
              path="recurring/calendar"
              loader={recurringCalendarLoader}
              element={<GridView />}
            />
          </Route>
          <Route path=":id/edit">
            <Route
              id="campaigns-edit-details"
              loader={campaignTemplateEditorLoader}
              element={<CampaignTemplateEditorDetailsPage />}
              path="details"
            />
            <Route
              id="campaigns-edit-design"
              loader={campaignTemplateEditorLoader}
              element={<CampaignTemplateEditorDesignPage />}
              path="design"
            />
          </Route>
        </Route>
        <Route
          id="forms"
          path="forms"
          loader={formsLoader}
          action={formsActions}
          errorElement={<ErrorBoundary />}
          element={<Forms />}
          handle={{
            crumb: () => ({ to: "./forms", name: "Forms" }),
          }}
        >
          <Route
            path=":id"
            element={<Outlet />}
            errorElement={<ErrorBoundaryGoBack />}
          >
            <Route
              path="preview"
              action={formAction}
              element={<FormPreviewDialog />}
            />
            <Route path="info" element={<FormInfo />} />
          </Route>
        </Route>
        <Route
          id="workflows"
          path="workflows"
          errorElement={<ErrorBoundary />}
          element={<Workflow page="LANDING" />}
        />
        <Route
          path="audience"
          element={<Audience />}
          handle={{
            crumb: () => ({ to: "./audience", name: "Audience" }),
          }}
        >
          <Route
            index
            element={<AudienceSources />}
            errorElement={<ErrorBoundary />}
            loader={audiencesLoader}
          />
          <Route
            path=":audienceId/subscribers"
            element={<Subscribers />}
            errorElement={<ErrorBoundaryGoBack />}
            loader={subscribersLoader}
            handle={{
              crumb: () => {
                const { location } = window;
                return { to: location.toString(), name: "Subscribers" };
              },
            }}
          />
          <Route path="*" element={<Navigate to="/audience" />} />
        </Route>
        <Route
          path="account-settings"
          shouldRevalidate={({ currentUrl }) =>
            currentUrl.pathname !== "/main/account-settings/ai-tuning"
          }
          element={<AccountSettings />}
          errorElement={<ErrorBoundaryRevalidation />}
          handle={{
            crumb: () => {
              const { location } = window;
              return { to: location.toString(), name: "My Account" };
            },
          }}
        >
          <Route index element={<Navigate to="ai-tuning" />} />
          <Route
            path="ai-tuning"
            loader={aiTuningRootLoader}
            action={submitSetupStrategyAction}
            errorElement={<ErrorBoundaryGoBack />}
            element={<AiTuning />}
          />
          <Route path="profile" element={<Profile />} />
          <Route
            path="plans"
            element={<Plans />}
            loader={planSelectionLoader}
            errorElement={<ErrorBoundaryGoBack />}
            action={planSelectionActions}
          />
          <Route
            path="*"
            element={<Navigate to="/account-settings/ai-tuning" />}
          />
          <Route
            id="sender-details"
            path="sender-details"
            element={<SenderDetails />}
            errorElement={<ErrorBoundaryGoBack />}
            action={senderDetailsAction}
          />
          <Route
            id="dkim"
            path="dkim"
            element={<DKIM />}
            errorElement={<ErrorBoundaryGoBack />}
          />
          <Route
            id="brand-kit"
            path="brand-kit"
            element={<BrandKit />}
            errorElement={<ErrorBoundaryGoBack />}
            loader={BrandKitLoader}
          />
          <Route
            id="hero-image"
            path="hero-image"
            element={<HeroImage />}
            errorElement={<ErrorBoundaryGoBack />}
            loader={heroImageLoader}
          />
          <Route
            path="*"
            element={<Navigate to="/account-settings/ai-tuning" />}
          />
        </Route>
        <Route path="*" element={<Navigate to="/main/dashboard/overview" />} />
      </Route>
    </Route>
  )
);

export default router;

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}
