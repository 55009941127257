import { TinyStyleGuideColors } from "../types/palette.types";
import { asMuiColor, createPaletteColor } from "../utils";

const greyScale = {
  0: "#FFFFFF",
  10: "#FBFCFD",
  20: "#F4F6FB",
  30: "#E7E9EE",
  40: "#DFE0E5",
  50: "#CFD0D4",
  60: "#C9CACE",
  70: "#9FA1A8",
  80: "#6F737D",
  90: "#3F4451",
  100: "#0F1526",
};

const tinyColors: TinyStyleGuideColors = {
  red: {
    50: "#FFE7E6",
    75: "#FCBEBE",
    100: "#FB8B8A",
    125: "#F53636",
    contrastText: "#ffffff",
  },
  fuchsia: {
    50: "#FFE2FE",
    75: "#FFB5DE",
    100: "#EF9ACC",
    125: "#DC89B9",
    150: "#DD4CA1",
    contrastText: "#ffffff",
  },
  yellow: {
    50: "#FFF3D6",
    75: "#FFE7AD",
    100: "#FFD97D",
    125: "#EAC263",
    150: "#FDAC07",
    contrastText: "#ffffff",
  },
  blue: {
    50: "#BDF4FF",
    75: "#6EDEF4",
    100: "#48CAE4",
    125: "#41B8D0",
    contrastText: "#ffffff",
  },
  green: {
    50: "#EAF8E5",
    75: "#AFFFF6",
    100: "#BFE8AC",
    125: "#3BB700",
    contrastText: "#ffffff",
  },
  purple: {
    50: "#F0EBFF",
    75: "#DBCFFE",
    100: "#C4AFFF",
    125: "#734FF7",
    150: "#815AF0",
    contrastText: "#ffffff",
  },
};

const primaryColor = asMuiColor({
  10: "#EAF8F4",
  20: "#DBF5EE",
  40: "#AFDACF",
  50: "#98DDCA",
  60: "#7BCAC1",
  75: "#299E90",
  80: "#399D84",
  100: "#008060",
  120: "#005741",
  125: "#005741",
  contrastText: "#ffffff",
});
const muiColors = createPaletteColor(tinyColors);

const palette = {
  primary: primaryColor,
  grey: greyScale,
  ...muiColors,
  background: {
    paper: greyScale["0"],
    default: greyScale["20"],
  },
  error: {
    lighter: tinyColors.red["50"],
    main: tinyColors.red["125"],
  },
};

export { palette };
