import { GraphStructure, Node } from "./WorkflowsBuilder/types";

function getIdFromUrl() {
  const pathname = window.location.pathname;
  const segments = pathname.split("/");
  const id = segments[segments.length - 1];
  return id;
}

const findTopEdge = (node_id: string, structure: GraphStructure) =>
  structure?.edges?.find((edge) => edge?.target === node_id);

const findBottomEdge = (node_id: string, structure: GraphStructure) =>
  structure?.edges?.find((edge) => edge?.source === node_id);

const findPreviousNode = (node_id: string, structure: GraphStructure) => {
  const topEdge = findTopEdge(node_id, structure);
  if (!topEdge) {
    return undefined;
  }
  return structure?.nodes?.find((node) => node.id === topEdge.source);
};

const findNextNode = (node_id: string, structure: GraphStructure) => {
  const bottomEdge = findBottomEdge(node_id, structure);
  if (!bottomEdge) {
    return undefined;
  }
  return structure?.nodes?.find((node) => node.id === bottomEdge.target);
};

const getParentNodes = (node_id: string, structure: GraphStructure) => {
  let parentArray: Array<Node | undefined> = [];

  function traverse(id: string): void {
    const topEdge = findTopEdge(id, structure);
    if (topEdge) {
      parentArray.push(
        structure?.nodes?.find((node) => node.id === topEdge.source)
      );
      return traverse(topEdge.source);
    } else {
      return;
    }
  }
  traverse(node_id);
  return parentArray;
};

const getLinearChildrenNodes = (node_id: string, structure: GraphStructure) => {
  let leanerChildrenArray: Array<Node | undefined> = [];

  function traverseDown(id: string): void {
    const bottomNode = findNextNode(id, structure);
    if (
      bottomNode &&
      bottomNode?.type !== "LOGIC" &&
      bottomNode?.type !== "EXIT"
    ) {
      leanerChildrenArray.push(bottomNode);
      return traverseDown(bottomNode.id);
    } else {
      return;
    }
  }
  traverseDown(node_id);
  return leanerChildrenArray;
};

function getImmediateChildNodes(node: Node, structure: GraphStructure) {
  return structure.edges
    .filter((edge) => edge.source === node.id)
    .map((edge) => structure.nodes.find((n) => n.id === edge.target));
}

const getTriggerNode = (structure: GraphStructure) => {
  return structure?.nodes?.find((node) => node?.type === "TRIGGER");
};

export {
  getIdFromUrl,
  getParentNodes,
  findBottomEdge,
  findTopEdge,
  getTriggerNode,
  getImmediateChildNodes,
  findNextNode,
  findPreviousNode,
  getLinearChildrenNodes,
};
