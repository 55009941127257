import ELK, { ElkEdge, ElkExtendedEdge, ElkNode } from "elkjs";
import { Edge, Node } from "./types";

const elkLayout = (nodes: Node[], edges: Edge[]): Promise<any> => {
  const elk = new ELK();
  const graph = {
    id: "root",
    layoutOptions: {
      "elk.algorithm": "elk.mrtree",
      "elk.direction": "DOWN",
      "elk.spacing.nodeNode": "100",
    },
    children: nodes.map((node) => {
      const height = node.type === "LOGIC" ? 240 : 140;
      return {
        ...node,
        ports: [],
        width: 400,
        height,
      };
    }),
    edges: edges as any,
  };

  return elk.layout(graph);
};
export default elkLayout;
