import React from "react";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { ChevronDown, CloseSmall } from "@tiny/icons";
import { AudienceOption } from "../../TriggerForm.types";

interface AudienceSelectorProps {
  value: AudienceOption[];
  onChange: (value: AudienceOption[]) => void;
  options: AudienceOption[];
  onInputChange: (searchText: string) => void;
  isLoading: boolean;
  hasMore?: boolean;
  onScrollEnd?: () => void;
  placeholder?: string;
  label?: string;
  enableInfiniteScroll?: boolean;
}

const AudienceSelector: React.FC<AudienceSelectorProps> = ({
  value,
  onChange,
  options,
  onInputChange,
  isLoading,
  hasMore = false,
  onScrollEnd,
  placeholder = "Search Audience",
  label = "Audience",
  enableInfiniteScroll = false,
}) => {
  const handleListboxScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollHeight -
        listboxNode.scrollTop -
        listboxNode.clientHeight <
        50 &&
      hasMore &&
      !isLoading &&
      onScrollEnd
    ) {
      onScrollEnd();
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          px: 2,
        })}
        shrink
      >
        {label}
      </InputLabel>
      <Autocomplete
        multiple
        options={options || []}
        value={value}
        loading={isLoading}
        limitTags={3}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        filterSelectedOptions
        popupIcon={<ChevronDown />}
        onChange={(_, newValue) => onChange(newValue)}
        onInputChange={(_, newInputValue) => onInputChange(newInputValue)}
        ListboxProps={
          enableInfiniteScroll
            ? {
                onScroll: handleListboxScroll,
                style: { maxHeight: 200, overflowY: "auto" },
              }
            : undefined
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            size="small"
            variant="outlined"
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.id}>
            <Typography variant="caption">{option.name}</Typography>
          </Box>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name}
              {...getTagProps({ index })}
              size="small"
              sx={(theme) => ({
                background: theme.palette.grey[30],
                color: theme.palette.grey[90],
                fontSize: 12,
                border: 0,
                fontWeight: 400,
              })}
              deleteIcon={<CloseSmall />}
            />
          ))
        }
        ListboxComponent={(props) => (
          <ul {...props}>
            {props.children}
            {isLoading && (
              <ListItem sx={{ justifyContent: "center", py: 1 }}>
                <CircularProgress size={20} />
              </ListItem>
            )}
            {!isLoading &&
              !hasMore &&
              options.length > 0 &&
              enableInfiniteScroll && (
                <ListItem sx={{ justifyContent: "center", py: 1 }}>
                  <Typography variant="caption" color="text.secondary">
                    No more results
                  </Typography>
                </ListItem>
              )}
          </ul>
        )}
      />
    </FormControl>
  );
};

export default AudienceSelector;
