import { createNewEdge, createNewNode } from "../Helpers/commons";
import { Edge, Node } from "../types";
import { v4 as uuid } from "uuid";
import { useWorkflowsBuilderPageView } from "../../WorkflowsBuilderPage.context";

const useDeleteNode = (): { deleteNode: (nodeId: string) => void } => {
  const { workflowData, setGraphData } = useWorkflowsBuilderPageView();
  const { nodes, edges } = workflowData.structure;

  const findChildrenAndEdges = (
    nodeId: string,
    nodes: Node[],
    edges: Edge[]
  ): [Node[], Edge[]] => {
    const children: Node[] = [];
    const childEdges: Edge[] = [];

    for (const edge of edges) {
      if (edge.source === nodeId) {
        const targetNodeId = edge.target;
        const targetNode = nodes.find((node) => node.id === targetNodeId);

        if (targetNode && edge) {
          children.push(targetNode);
          childEdges.push(edge);
        }

        const [grandchildren, grandchildEdges] = findChildrenAndEdges(
          targetNodeId,
          nodes,
          edges
        );
        children.push(...grandchildren);
        childEdges.push(...grandchildEdges);
      }
    }

    return [children, childEdges];
  };

  const deleteNode = (nodeId: string) => {
    const nodeToDelete = nodes.find((node) => node.id === nodeId);
    if (!nodeToDelete) return;

    if (nodeToDelete.type === "LOGIC") {
      const [nodesToDelete, edgesToDelete] = findChildrenAndEdges(
        nodeToDelete.id,
        nodes,
        edges
      );

      //filter out children nodes and their edges
      const updatedEdges = edges.filter(
        (edge) => !edgesToDelete.includes(edge)
      );
      const updatedNodes = nodes.filter(
        (node) => !nodesToDelete.includes(node)
      );

      //create a new node called exitNode
      const exitNode = createNewNode(
        uuid(),
        "EXIT",
        { x: 0, y: 0 },
        {
          label: "Exit",
          config: {
            configured: true,
          },
        }
      );

      const nodesToDeleteIndex = updatedNodes.findIndex((e) => e.id === nodeId);
      const inputEdge = updatedEdges.findIndex((e) => e.target === nodeId);
      //replace the logic-node with edit node
      updatedNodes.splice(nodesToDeleteIndex, 1, ...[exitNode]);

      //update the input edge
      updatedEdges[inputEdge].target = exitNode.id;

      setGraphData({
        nodes: updatedNodes,
        edges: updatedEdges,
      });
    } else {
      // remove the node
      const updatedNodes = nodes.filter((node) => node.id !== nodeId);

      // previous edge e1
      const previousEdge = edges.find((edge) => edge.target === nodeId);

      //next edge e2
      const nextEdge = edges.find((edge) => edge.source === nodeId);

      if (previousEdge && nextEdge) {
        const newEdge = createNewEdge(
          previousEdge.source,
          nextEdge.target,
          previousEdge.sourceHandle
        );
        //remove next edge
        const updatedEdges = edges.filter((edge) => edge.id !== nextEdge.id);
        const previousEdgeIndex = updatedEdges.findIndex(
          (e) => e.id === previousEdge.id
        );
        //update the previous edge with new edge
        updatedEdges[previousEdgeIndex] = newEdge;
        setGraphData({
          nodes: updatedNodes,
          edges: updatedEdges,
        });
      }
    }
  };

  return { deleteNode };
};

export default useDeleteNode;
