import React from "react";
import { Box } from "@mui/material";
import { PlusCircleOutline } from "@tiny/icons";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from "reactflow";
import useDragAndDrop from "../Hooks/useDragAndDrop";

export default function AddEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const { isDragging, setIsDragging, handleDropEvent } = useDragAndDrop(id);

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <Box
          sx={(theme) => ({
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
            backgroundColor: isDragging
              ? theme.palette.grey[30]
              : "transparent",
            border: isDragging
              ? `1px dashed ${theme.palette.grey[80]}`
              : "none",
            borderRadius: 3,
            width: "400px",
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          })}
          className="nodrag nopan"
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={() => {
            setIsDragging(false);
          }}
          onDrop={handleDropEvent}
        >
          {!isDragging && (
            <PlusCircleOutline
              sx={(theme) => ({
                zIndex: -1,
                color: theme.palette.grey[80],
                background: theme.palette.background.default,
                m: 1,
              })}
            />
          )}
        </Box>
      </EdgeLabelRenderer>
    </>
  );
}
