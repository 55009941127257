import { Edge, Node, NodeConfiguration, NodeData } from "../types";

export const createNewNode = (
  id: string,
  type: "TRIGGER" | "ACTION" | "TIMING" | "LOGIC" | "EXIT",
  position: { x: number; y: number },
  data: NodeData
): Node => ({
  id,
  type,
  position,
  data,
});

export const createNewEdge = (
  sourceNodeId: string,
  targetNodeId: string,
  sourceHandle?: string | null
): Edge => ({
  id: `${sourceNodeId}_${targetNodeId}`,
  type: "ADD",
  source: sourceNodeId,
  target: targetNodeId,
  ...(sourceHandle && { sourceHandle }),
});
