import React from "react";
import useDebounce from "../../../hooks/useDebounce";
import { sortWorkflowsAnaliticsOptions } from "../WorkflowList.helpers";
import { Search, TrashFull } from "@tiny/icons";
import {
  Button,
  Checkbox,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { SearchField, SelectField } from "../../../components";

interface WorkflowListHeaderProps {
  allSelected: boolean;
  allSelectedDisabled: boolean;
  bulkDeleteEnabled: boolean;
  onDeleteClick: () => void;
  onDeselectAll: () => void;
  onSearchChange: (value: string) => void;
  onSortChange: (sort: string[]) => void;
  onToggleAll: (checked: boolean) => void;
  selected: string[];
  sort: string[];
}

function WorkflowListHeader(props: WorkflowListHeaderProps) {
  const {
    allSelected,
    allSelectedDisabled,
    bulkDeleteEnabled,
    onDeleteClick,
    onDeselectAll,
    onSearchChange,
    onSortChange,
    onToggleAll,
    selected,
    sort,
  } = props;

  const [search, setSearch] = React.useState("");
  const searchDebounced = useDebounce(search);

  React.useEffect(() => {
    onSearchChange(searchDebounced);
  }, [onSearchChange, searchDebounced]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 4,
        "@media (min-width: 900px)": {
          marginBottom: 4,
          flexDirection: "row",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginBottom: 4,
          minHeight: 40,
          "@media (min-width: 900px)": {
            flex: 1,
            marginBottom: 0,
          },
        }}
      >
        {/* <Box
          sx={{
            flex: "0 0 32px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox
            color="primary"
            checked={allSelected}
            onChange={(e, checked) => onToggleAll(checked)}
            disabled={allSelectedDisabled}
            size="small"
          />
        </Box> */}
        {selected.length > 0 ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            {bulkDeleteEnabled && (
              <Button
                variant="outlined"
                color="error"
                endIcon={<TrashFull />}
                onClick={onDeleteClick}
                sx={{ marginRight: 2 }}
              >
                Delete
              </Button>
            )}
            <Button onClick={onDeselectAll} variant="text">
              Deselect all
            </Button>
            <Typography color="textSecondary" sx={{ ml: 5 }} variant="label2">
              {selected.length} selected
            </Typography>
          </Box>
        ) : (
          <Box sx={{ flex: 1 }}>
            <SearchField
              value={search}
              placeholder="Search"
              onChangeValue={setSearch}
              sx={{
                "@media (min-width: 900px)": {
                  width: 270,
                  borderRadius: 20,
                },
              }}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          "@media (min-width: 900px)": {
            flex: 1,
            justifyContent: "flex-end",
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            "@media (min-width: 900px)": {
              flex: "0 0 140px",
            },
          }}
        >
          <Box width={140}>
            <FormControl size="small" fullWidth>
              <Select
                fullWidth
                //@ts-ignore
                onChange={(e) => onSortChange(e.target.value)}
                value={sort}
                aria-label="Sort"
              >
                {sortWorkflowsAnaliticsOptions.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default WorkflowListHeader;
