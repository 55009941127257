import { GraphStructure } from "../pages/WorkflowsBuilderPage/WorkflowsBuilder/types";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from "axios";

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  secure?: boolean;
  path: string;
  type?: ContentType;
  query?: QueryParamsType;
  format?: ResponseType;
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export type QueryParamsType = Record<string | number, any>;

export interface LeadIdentityView {
  id?: string;
}

export interface SenderDetailsUpdateView {
  name?: string;

  email?: string;

  address?: string;

  city?: string;

  region?: string;

  country?: string;

  postalCode?: string;

  defaults?: boolean;

  sendConfirmationEmail?: boolean;
}

export interface CreateSuppressionRequestView {
  name: string;
  type: string;
  scope: string;
}

export interface suppressionDetailsItem {
  value: string;
  description: string;
}

export interface CreateSuppressionResponseView {
  id?: string;
  name: string;
  type: string;
  scope: string;
}

export interface AudiencesResponseView {
  audiences?: AudienceResponseView[];
}
export interface AudienceResponseView {
  id: string;
  name: string;
  audienceType: "UPLOAD" | "CUSTOM";
  numberOfCustomers: number;
  createdAt: number;
  lastAddedAt: number;
}

export interface SenderDetailsView {
  id?: string;

  name?: string;

  email?: string;

  address?: string;

  city?: string;

  region?: string;

  country?: string;

  postalCode?: string;

  emailConfirmed?: boolean;

  dkimSigned?: boolean;

  defaults?: boolean;

  assignedToCampaigns?: boolean;
}

export interface CampaignContentSourceRequestView {
  contentSourceUrl: string;
}

export interface SenderDetailsFilterView {
  searchTextName?: string;
}

export interface PageSenderDetailsView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  numberOfElements?: number;

  pageable?: Pageable;

  first?: boolean;

  last?: boolean;

  size?: number;

  content?: SenderDetailsView[];

  number?: number;

  empty?: boolean;
}

export interface Pageable {
  page?: number;

  size?: number;

  sort?: string[];
}

export interface Sort {
  sorted?: boolean;

  unsorted?: boolean;

  empty?: boolean;
}

export interface ExportInvoicesView {
  invoiceIds?: string[];
}

export interface IntegrationFilterView {
  name?: string;

  systemNames?: string[];

  integrationType?:
    | "ALL"
    | "CRM"
    | "E_COMMERCE"
    | "EMAIL_PROVIDER"
    | "PAYMENTS"
    | "FORMS"
    | "OTHERS";
}

export interface IntegrationItemView {
  id?: string;

  name?: string;

  systemName?: string;

  siteLink?: string;

  logoDefault?: string;

  logoChecked?: string;

  description?: string;

  integrationTypes?: (
    | "ALL"
    | "CRM"
    | "E_COMMERCE"
    | "EMAIL_PROVIDER"
    | "PAYMENTS"
    | "FORMS"
    | "OTHERS"
  )[];

  connectionType?: "OAUTH" | "ACCESS_KEY" | "CUSTOM" | "REDIRECT";

  parameters?: Record<string, string>;

  priority?: number;

  guideLink?: string;
}

export interface IntegrationsView {
  integrations?: IntegrationItemView[];
}

export interface IdentityLogoView {
  identityId?: string;

  logoUrl?: string;
}

export interface IdentityView {
  id?: string;

  logoUrl?: string;

  backgroundColor?: string;

  linksColor?: string;

  bodyTextColor?: string;

  buttonsColor?: string;

  topMenuColor?: string;

  titleFont?: string;

  paragraphFont?: string;

  socialLinks?: SocialLinkView[];

  links?: LinkView[];
}

export interface LinkView {
  id?: string;

  text?: string;

  url?: string;
}

export interface SocialLinkView {
  id?: string;

  type?: string;

  url?: string;
}

export interface CampaignUpdateRequestView {
  name?: string;

  subject?: string;

  preview?: string;

  smartAssistant?: boolean;
}

export interface PredefinedSegmentsCountsResponseView {
  numberOfCustomers: number;
}

export interface customFontsResponseView {
  id: string;
  fontName: string;
  fontFamily: string;
  fontUrl: string;
  createdAt: string;
  updatedAt: string;
}

export interface customFontsRequestView {
  fontName: string;
  fontFamily: string;
  fontUrl: string;
}
export interface CampaignResponseView {
  id?: string;

  senderDetailsId?: string;

  senderEmail?: string;

  senderName?: string;

  name?: string;

  subject?: string;

  preview?: string;

  smartAssistant?: boolean;

  start?: number;

  completed?: number;

  campaignStatus?:
    | "DRAFT"
    | "LAUNCHED"
    | "QUEUED"
    | "COMPLETED"
    | "SCHEDULED"
    | "PAUSED"
    | "DELETED"
    | "FAILED"
    | "RECURRING"
    | "STOPPED";

  template?: boolean;

  parentTemplateId?: string;

  requests?: number;

  sent?: number;

  open?: number;

  totalOpen?: number;

  clicked?: number;

  totalClicked?: number;

  unsubscribed?: number;

  delivered?: number;

  bounced?: number;

  spam?: number;

  total?: number;

  sentProgress?: number;

  openProgress?: number;

  totalOpenProgress?: number;

  clickedProgress?: number;

  totalClickedProgress?: number;

  unsubscribedProgress?: number;

  deliveredProgress?: number;

  bouncedProgress?: number;

  spamProgress?: number;

  totalProgress?: number;

  remainedTestEmails?: number;

  autoTrackingEnabled?: boolean;

  contentSourceUrl?: string;
}

export interface WorkflowCreateRequestView {
  name: string;
  description?: string;
  triggerType?: string;
  structure?: GraphStructure;
  templateId?: string;
}

export interface CreateWorkflowResponseView {
  id: string;
  status: "DRAFT" | "ACTIVE" | "PAUSED" | "DELETED";
}

export interface WorkflowDetailsView {
  id: string;
  name: string;
  description: string;
  trigger_event: string;
  structure: GraphStructure;
  status: "DRAFT" | "ACTIVE" | "PAUSED" | "DELETED";
}

export interface CampaignTestEmailsView {
  testEmails: string[];

  activityId?: string;
}

export interface CampaignRemainedTestEmailsView {
  remainedTestEmails?: number;

  remainedTestEmailsPerDay?: number;
}

export interface CampaignStartView {
  start?: number;

  isScheduled?: boolean;

  days?: string[];

  time?: string;
}

export interface SenderDetailsIdentityView {
  senderDetailsId?: string;

  senderName?: string;

  senderEmail?: string;
}

export interface SegmentIdentitiesView {
  segments?: SegmentIdentityView[];
}

export interface SuppressionIdentitiesView {
  suppressions?: SuppressionView[];
}

export interface AudienceIdentitiesView {
  audiences?: SegmentIdentityView[];
}

export interface AudienceIdentityView {
  id?: string;

  name?: string;

  audienceType?: string;

  numberOfCustomers?: number;
}

export interface SegmentIdentityView {
  id?: string;

  name?: string;

  segmentType?: string;

  numberOfCustomers?: number;
}

export interface EnabledView {
  enabled?: boolean;
}

export interface ActivityIdentitiesView {
  activities?: ActivityIdentityView[];
}

export interface ActivityIdentityView {
  id?: string;

  name?: string;

  templateCss?: string;

  templateHtml?: string;

  createdAt?: number;

  updatedAt?: number;

  assignedToCampaigns?: boolean;

  isFavorite?: boolean;

  editorType?: "DRAG_AND_DROP" | "HTML";

  ampErrors?: string[];
}

export interface ActivityDetailsView {
  id?: string;

  name?: string;

  subject?: string;

  html?: string;

  css?: string;

  templateCss?: string;

  templateHtml?: string;

  tags?: TemplateTagView[];

  editorType?: "DRAG_AND_DROP" | "HTML";

  activityTypeView?: "EMAIL_TEMPLATE" | "EMAIL_MESSAGE";

  ampErrors?: string[];

  skipAmpErrorsCheck?: boolean;

  compressTemplate?: boolean;
}

export interface TemplateTagView {
  id?: string;

  name?: string;
}

export interface IdView {
  id?: string;

  ampErrors?: string[];
}

export interface IdsView {
  ids?: string[];
}

export interface WorkflowTemplateFilterView {
  pageNumber: number;
  size: number;
}
export interface CampaignFilterView {
  from?: number;

  to?: number;

  searchTextName?: string;

  campaignStatuses?: (
    | "DRAFT"
    | "LAUNCHED"
    | "QUEUED"
    | "COMPLETED"
    | "SCHEDULED"
    | "PAUSED"
    | "DELETED"
    | "FAILED"
    | "RECURRING"
    | "STOPPED"
  )[];

  ids?: string[];
  parentCampaignId?: string;
}
export interface DomainEngagementFilterView {
  domainName?: string;
  campaignId: string;
  allowRegexSearch?: boolean;
}

export interface RecurringChildrenFilterView {
  from?: number;

  to?: number;

  searchTextName?: string;

  campaignStatuses?: (
    | "DRAFT"
    | "LAUNCHED"
    | "QUEUED"
    | "COMPLETED"
    | "SCHEDULED"
    | "PAUSED"
    | "DELETED"
    | "FAILED"
    | "RECURRING"
    | "STOPPED"
  )[];

  ids?: string[];
}

export interface AlbertAudience {
  id: string;
  name: string;
  audienceType?: string;
  numberOfCustomers: number;
  createdAt: number;
}

export interface AlbertAudienceResponseView {
  audiences: AlbertAudience[];
}

export interface DomainDeliverabilityFilterView {
  domainName?: string;
  campaignId: string;
  allowRegexSearch?: boolean;
}
export interface WorkflowEmailTemplateView {
  id?: string;

  name?: string;

  subject?: string;

  previewText?: string;

  senderDetailsId?: string;

  templateHtml?: string;

  templateCss?: string;

  status?: "DRAFT" | "ACTIVE" | "DELETED";
}

export interface WorkflowEmailTemplateUpdateRequestView {
  name?: string;

  subject?: string;

  previewText?: string;

  senderDetailsId?: string;

  templateHtml?: string;

  templateCss?: string;

  status?: string;
}

export type WorkflowEmailTemplateUpdateResponseView = {};
export interface WorkflowEmailTemplateCreateRequestView {
  name: string;

  subject: string;

  previewText?: string;

  workflowId: string;

  nodeId: string;
}

export interface WorkflowEmailTemplateCreateResponseView {
  id?: string;

  name?: string;

  subject?: string;

  previewText?: string;

  status?: "DRAFT" | "ACTIVE" | "DELETED";
}
export interface WorkflowFilterView {
  searchTextName?: string;

  workflowStatuses?: ("DRAFT" | "ACTIVE" | "INACTIVE" | "PAUSED" | "DELETED")[];
}

export interface WorkflowInfoView {
  id: string;
  name: string;
  createdAt?: number;
  updatedAt: number;
  status: "DRAFT" | "ACTIVE" | "INACTIVE" | "PAUSED" | "DELETED";
}

export interface CampaignInfoView {
  id?: string;

  accountId?: string;

  name?: string;

  smartAssistant?: boolean;

  start?: number;

  completed?: number;

  sent?: number;

  requests?: number;

  totalOpen?: number;

  open?: number;

  totalClicked?: number;

  clicked?: number;

  unsubscribed?: number;

  delivered?: number;

  bounced?: number;

  spam?: number;

  total?: number;

  sentProgress?: number;

  openProgress?: number;

  totalOpenProgress?: number;

  clickedProgress?: number;

  totalClickedProgress?: number;

  unsubscribedProgress?: number;

  deliveredProgress?: number;

  bouncedProgress?: number;

  spamProgress?: number;

  totalProgress?: number;

  campaignStatus?:
    | "DRAFT"
    | "LAUNCHED"
    | "QUEUED"
    | "COMPLETED"
    | "SCHEDULED"
    | "PAUSED"
    | "DELETED"
    | "FAILED"
    | "RECURRING"
    | "STOPPED";

  createdAt?: number;

  updatedAt?: number;
}

export interface DomainEngagementInfoView {
  send: number;
  domainName: string;
  delivered: number;
  opens: number;
  clicks: number;
}
export interface DomainDeliverabilityInfoView {
  send: number;
  domainName: string;
  delivered: number;
  spams: number;
  unsubscribes: number;
}
export interface PageDomainEngagementInfoView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  numberOfElements?: number;

  pageable?: Pageable;

  first?: boolean;

  last?: boolean;

  size?: number;

  content?: DomainEngagementInfoView[];

  number?: number;

  empty?: boolean;
}

export interface PageDomainDeliverabilityInfoView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  numberOfElements?: number;

  pageable?: Pageable;

  first?: boolean;

  last?: boolean;

  size?: number;

  content?: DomainDeliverabilityInfoView[];

  number?: number;

  empty?: boolean;
}
export interface PageCampaignInfoView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  numberOfElements?: number;

  pageable?: Pageable;

  first?: boolean;

  last?: boolean;

  size?: number;

  content?: CampaignInfoView[];

  number?: number;

  empty?: boolean;
}

export interface PageWorkflowTemplateInfoView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  numberOfElements?: number;

  pageable?: Pageable;

  first?: boolean;

  last?: boolean;

  size?: number;

  content?: WorkflowTemplateView[];

  number?: number;

  empty?: boolean;
}

export interface PageWorkflowInfoView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  numberOfElements?: number;

  pageable?: Pageable;

  first?: boolean;

  last?: boolean;

  size?: number;

  content?: WorkflowInfoView[];

  number?: number;

  empty?: boolean;
}

export interface ActivityFilterView {
  searchNameText?: string;

  tagIds?: string[];

  isFavorite?: boolean;

  editorType?: "DRAG_AND_DROP" | "HTML";
}

export interface PageActivityIdentityView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  numberOfElements?: number;

  pageable?: Pageable;

  first?: boolean;

  last?: boolean;

  size?: number;

  content?: ActivityIdentityView[];

  number?: number;

  empty?: boolean;
}

export interface EmailActivityTemplateFilterView {
  searchNameText?: string;

  types?: ("BASIC" | "CUSTOM" | "LIBRARY" | "AUTOMATION" | "STAFF_PICKED")[];

  tagIds?: string[];

  isFavorite?: boolean;
}

export interface EmailActivityTemplateIdentityView {
  id?: string;

  name?: string;

  templateCss?: string;

  templateHtml?: string;

  createdAt?: number;

  updatedAt?: number;

  isFavorite?: boolean;
}

export interface PageEmailActivityTemplateIdentityView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  numberOfElements?: number;

  pageable?: Pageable;

  first?: boolean;

  last?: boolean;

  size?: number;

  content?: EmailActivityTemplateIdentityView[];

  number?: number;

  empty?: boolean;
}

export interface AccountView {
  id?: string;

  userEmail?: string;

  firstName?: string;

  lastName?: string;

  phoneNumber?: string;

  site?: string;

  company?: string;

  address?: string;

  country?: string;

  postalCode?: string;

  city?: string;

  state?: string;

  accountStatus?:
    | "ACTIVE"
    | "FROZEN"
    | "PAUSED"
    | "LATE"
    | "LATE_TRIAL"
    | "CANCELED";

  dkimAdded?: boolean;
}

export interface AccountIntegrationExecutionView {
  integrationSystemName?: string;

  errorMessage?: string;

  errorMessageDetails?: string;

  importedCustomers?: number;
}

export interface ConfigurableNotificationUpdateView {
  name?: string;

  disabled?: boolean;
}

export interface ConfigurableNotificationCategoryUpdateView {
  name?: string;

  disabled?: boolean;
}

export interface SubscriptionCostCalculateParametersView {
  tierId?: string;

  promotionCode?: string;

  yearly?: boolean;

  country?: string;

  postalCode?: string;

  isTrial?: boolean;
}

export interface SubscriptionCostView {
  subtotal?: number;

  tax?: number;

  discount?: number;

  taxDetails?: TaxRateView[];

  total?: number;

  nextCyclePrice?: number;
}

export interface TaxRateView {
  id?: string;

  name?: string;

  inclusive?: boolean;

  percentage?: number;
}

export interface AttachBillingInformationView {
  firstName: string;

  lastName: string;

  address: string;

  country: string;

  postalCode: string;

  city: string;

  state: string;

  taxType?: string;

  taxId?: string;
}

export interface TicketCreateRequestDto {
  ticketType?: "CAMPAIGN_REVIEW";

  accountId?: string;

  campaignId?: string;

  campaignName?: string;

  accountEmail?: string;
}

export interface LeadView {
  email?: string;

  plan?: string;

  subscriptionType?: string;

  emailVolume?: string;

  trial?: boolean;

  promoCode?: string;

  affiliateId?: string;

  utmSource?: string;

  utmMedium?: string;

  utmCampaign?: string;

  utmTerm?: string;

  utmContent?: string;

  skipC?: boolean;
}

export interface LicenseView {
  action?: string;

  plan_id?: string;

  uuid?: string;

  activation_email?: string;

  invoice_item_uuid?: string;
}

export interface LicenseCreatedView {
  message?: string;

  redirect_url?: string;
}

export interface SenderDetailsCreateView {
  name?: string;

  email?: string;

  address?: string;

  city?: string;

  region?: string;

  country?: string;

  postalCode?: string;

  defaults?: boolean;

  sendConfirmationEmail?: boolean;
}

export interface SenderDetailsEmailConfirmationView {
  token?: string;
}

export interface AttachPaymentMethodView {
  cardNumber: string;

  expirationMonth: number;

  expirationYear: number;

  cvcCode: string;

  country: string;

  postalCode: string;

  makeDefault?: boolean;
}

export interface AddressResponse {
  city?: string;

  country?: string;

  line1?: string;

  line2?: string;

  postalCode?: string;

  state?: string;
}

export interface BillingDetailsResponse {
  address?: AddressResponse;

  email?: string;

  name?: string;

  phone?: string;
}

export interface CardResponse {
  last4?: string;

  expMonth?: string;

  expYear?: string;

  fingerprint?: string;

  brand?: string;
}

export interface LastSetupError {
  code?: string;

  declineCode?: string;

  errorType?: string;

  message?: string;
}

export interface PaymentMethodResponse {
  id?: string;

  card?: CardResponse;

  billingDetails?: BillingDetailsResponse;

  shippingDetails?: BillingDetailsResponse;
}

export interface PaymentMethodView {
  id?: string;

  paymentMethodStatus?:
    | "VALIDATING"
    | "ACTIVE"
    | "REQUIRES_PAYMENT_METHOD"
    | "REQUIRES_ACTION";

  lastSetupError?: LastSetupError;

  paymentDetails?: PaymentMethodResponse;

  defaults?: boolean;
}

export interface CampaignCreateRequestView {
  name?: string;

  subject?: string;

  preview?: string;

  smartAssistant?: boolean;
}

export interface CampaignCreateByTemplateRequestView {
  templateCampaignId?: string;
}

export interface CampaignStatisticsTotalRequestView {
  from?: number;

  to?: number;
}

export interface CampaignStatisticsTotalResponseView {
  completed?: number;

  queued?: number;

  launched?: number;

  scheduled?: number;

  paused?: number;

  requests?: number;

  sent?: number;

  open?: number;

  totalOpen?: number;

  clicked?: number;

  totalClicked?: number;

  unsubscribed?: number;

  delivered?: number;

  bounced?: number;

  spam?: number;

  sentProgress?: number;

  openProgress?: number;

  totalOpenProgress?: number;

  clickedProgress?: number;

  totalClickedProgress?: number;

  unsubscribedProgress?: number;

  deliveredProgress?: number;

  bouncedProgress?: number;

  spamProgress?: number;
}

export interface CampaignStatisticsGroupedRequestView {
  from?: number;

  to?: number;

  groupTimeUnit?: "HOUR" | "DAY" | "MONTH" | "YEAR";
}

export interface CampaignStatisticsGroupedResponseView {
  timeGroupStatisticsList?: CampaignTimeGroupStatisticsView[];
}

export interface CampaignTimeGroupStatisticsView {
  campaignStart?: number;

  sent?: number;

  open?: number;

  clicked?: number;

  unsubscribed?: number;

  delivered?: number;

  bounced?: number;

  spam?: number;
}

export interface CampaignCloneRequestView {
  cloneCampaignId?: string;
}

export interface WorkflowCloneRequestView {
  cloneWorkflowId?: string;
}

export interface AccountCreateView {
  id?: string;

  userEmail?: string;

  firstName?: string;

  lastName?: string;

  phoneNumber?: string;

  site?: string;

  company?: string;

  address?: string;

  country?: string;

  postalCode?: string;

  city?: string;

  state?: string;

  accountStatus?:
    | "ACTIVE"
    | "FROZEN"
    | "PAUSED"
    | "LATE"
    | "LATE_TRIAL"
    | "CANCELED";

  dkimAdded?: boolean;
}

export interface AccountIntegrationView {
  integrationSystemName?: string;

  workflowName?: string;

  workflowTriggerPath?: string;

  workflowDeletePath?: string;

  properties?: Record<string, string>;
}

export interface AssignSubscriptionView {
  tierId: string;

  trial?: boolean;

  yearly?: boolean;

  promotionCode?: string;
}

export interface AssignSubscriptionWithPaymentMethodView {
  subscription: AssignSubscriptionView;

  paymentMethod: AttachPaymentMethodView;

  billingInformation: AttachBillingInformationView;
}

export interface AssignSubscriptionWithBillingInfoView {
  subscription?: AssignSubscriptionView;

  billingInformation?: AttachBillingInformationView;
}

export interface TemplateTagsView {
  tags?: TemplateTagView[];
}

export interface SubscriptionFeatureView {
  name?: string;

  featureSystemName?:
    | "STRIPO_EDITOR"
    | "STRIPO_EDITOR_CUSTOM"
    | "BASIC_TEMPLATES"
    | "CUSTOM_TEMPLATES"
    | "LIBRARY_TEMPLATES"
    | "SENDER_DETAILS"
    | "CUSTOM_SEGMENTS"
    | "PRE_BUILD_CUSTOM_SEGMENTS"
    | "REMOVE_POWERED_BY_FOOTER"
    | "SUBSCRIBER_ANALYTICS"
    | "UNIQUE_CUSTOMERS"
    | "SMART_SUBJECT_ASSISTANT";

  enabled?: boolean;

  visible?: boolean;

  featureCount?: number;

  featureCountDescription?: string;

  featureAvailabilityStatus?:
    | "AVAILABLE"
    | "NOT_AVAILABLE"
    | "PARTIAL_AVAILABLE"
    | "COMING_SOON";

  iconUrl?: string;

  parameters?: Record<string, string>;

  subscriptionName?: string;
}

export interface SubscriptionView {
  id?: string;

  name?: string;

  planCode?: string;

  logo?: string;

  bestChoice?: boolean;

  tiers?: TierView[];

  subscriptionFeatures?: SubscriptionFeatureView[];

  affiliateType?: string;
}

export interface SubscriptionsView {
  subscriptions?: SubscriptionView[];
}

export interface TierView {
  id?: string;

  name?: string;

  subscriptionName?: string;

  planCode?: string;

  tierPlanCode?: string;

  logo?: string;

  dailyCost?: number;

  monthlyCost?: number;

  yearlyCost?: number;

  overageCost?: number;

  emailsNumber?: number;

  smsNumber?: number;

  duration?: number;

  defaults?: boolean;

  subscriptionId?: string;

  customPrice?: boolean;

  overageForbidden?: boolean;
}

export interface SubscriptionFeatureAvailabilityView {
  enabled?: boolean;

  featureCount?: number;

  featureCountDescription?: string;

  featureAvailabilityStatus?:
    | "AVAILABLE"
    | "NOT_AVAILABLE"
    | "PARTIAL_AVAILABLE"
    | "COMING_SOON";

  iconUrl?: string;
}

export interface SubscriptionFeaturesAvailabilityView {
  featureName?: string;

  subscriptionAvailabilities?: Record<string, any>;
}

export interface SubscriptionFeaturesCategoriesAvailabilityView {
  categoryName?: string;

  subscriptionFeaturesAvailabilities?: SubscriptionFeaturesAvailabilityView[];
}

export interface SenderDetailsesView {
  senderDetailses?: SenderDetailsView[];
}

export interface PaymentMethodsView {
  paymentMethods?: PaymentMethodView[];
}

export interface InvoiceView {
  id?: string;

  number?: string;

  description?: string;

  invoicePdf?: string;

  amountPaid?: number;

  amountDue?: number;

  localStatus?:
    | "PAID"
    | "VOID"
    | "UNCOLLECTIBLE"
    | "OVERDUE"
    | "UPCOMING"
    | "UNKNOWN";

  status?: string;

  created?: string;

  dueDate?: string;

  periodEnd?: string;
}

export interface InvoicesView {
  invoices?: InvoiceView[];

  hasMore?: boolean;
}

export interface SocialLinksView {
  links?: string[];
}

export interface ColorsView {
  filteredVibrant?: string;

  filteredDark?: string;

  filteredLight?: string;

  suggestedColors?: string[];
}

export interface FontView {
  titleFont?: string;

  titleFallback?: string[];

  paragraphFont?: string;

  paragraphFallback?: string[];
}

export interface IdentityProfiledView {
  logo?: LogoView;

  colors?: ColorsView;

  socialLinks?: SocialLinksView;

  font?: FontView;
}

export interface LogoView {
  logoSafe?: boolean;

  logoImage?: string;

  logoSvg?: string;

  iconImage?: string;

  iconSvg?: string;
}

export interface EditorTokenView {
  token?: string;
}

export interface ActivityDetailsListView {
  activities?: ActivityDetailsView[];
}

export interface ActivityEmailFooterView {
  html?: string;
}

export interface ActivityTemplateAttributeView {
  name?: string;

  value?: string;
}

export interface ActivityTemplateAttributesView {
  attributes?: ActivityTemplateAttributeView[];
}

export interface CampaignIdentitiesView {
  campaigns?: CampaignIdentityView[];
}

export interface CampaignIdentityView {
  id?: string;

  name?: string;
}

export interface EmailActivityTemplateDetailsView {
  id?: string;

  name?: string;

  subject?: string;

  templateCss?: string;

  templateHtml?: string;

  tags?: TemplateTagView[];
}

export interface AccountIntegrationItemView {
  id?: string;

  integrationId?: string;

  accountId?: string;

  integrationName?: string;

  integrationSystemName?: string;

  workflowName?: string;

  lastExecution?: string;

  lastSuccessExecution?: string;

  lastExecutionError?: string;

  totalImported?: number;

  lastExecutionImported?: number;

  integration?: IntegrationItemView;
}

export interface AccountPlatformUsageView {
  numberOfCampaigns?: number;

  numberOfSentCampaigns?: number;

  numberOfSavedTemplates?: number;

  numberOfIntegrations?: number;

  numberOfAudiences?: number;

  numberOfForms?: number;
}

export interface AccountTemplatesPlatformUsageView {
  numberOfSavedTemplates?: number;
}

export interface AccountIntegrationsPlatformUsageView {
  numberOfIntegrations?: number;
}

export interface AccountCampaignsPlatformUsageView {
  numberOfCampaigns?: number;

  numberOfSentCampaigns?: number;
}

export interface AccountAudiencesPlatformUsageView {
  numberOfAudiences?: number;
}

export interface ConfigurableNotificationCategoryView {
  name?: string;

  description?: string;

  disabled?: boolean;

  notificationSettings?: ConfigurableNotificationSettingView[];
}

export interface DomainDKIMOption {
  domain: string;
  enabled: boolean;
}
export interface ConfigurableNotificationSettingView {
  name?: string;

  description?: string;

  disabled?: boolean;
}

export interface ConfigurableNotificationSettingsView {
  notificationCategories?: ConfigurableNotificationCategoryView[];
}

export interface DomainDKIMOptions {
  domains: DomainDKIMOption[];
}
export interface DomainDKIMStatus {
  enabled: boolean;
}
export interface AccountIssueView {
  message?: string;

  code?: string;
}

export interface AccountIssuesView {
  issues?: AccountIssueView[];
}

export interface AccountSubscriptionRecommendationView {
  alert?: "ORANGE" | "RED" | "BLACK";

  daysExceeded?: boolean;

  emailUsagePercent?: number;

  trialEndsInDays?: number;
}

export interface AccountSubscriptionView {
  logo?: string;

  bestChoice?: boolean;

  subscriptionType?: "MONTH" | "YEAR" | "DAY";

  tier?: TierView;

  scheduledTier?: TierView;

  usage?: UsageView;

  recommendation?: AccountSubscriptionRecommendationView;

  promotionCode?: string;

  isTrial?: boolean;

  createdAt?: string;

  tierId?: string;

  scheduledTierId?: string;
}

export interface UsageView {
  emailsNumber?: number;
}

export interface AccountLeadSubscriptionView {
  subscriptionType?: "MONTH" | "YEAR";

  tier?: TierView;

  promotionCode?: string;

  isTrial?: boolean;

  duration?: number;

  affiliateId?: string;

  plan?: string;

  emailVolume?: string;

  utmSource?: string;

  utmMedium?: string;

  utmCampaign?: string;

  utmTerm?: string;

  utmContent?: string;

  skipC?: boolean;
}

export interface SubscriptionFeaturesView {
  subscriptionFeatures?: SubscriptionFeatureView[];
}

export interface SubscriptionFeaturesCategoriesView {
  subscriptionFeatureCategories?: SubscriptionFeaturesCategoryView[];
}

export interface SubscriptionFeaturesCategoryView {
  name?: string;

  categoryAvailabilityStatus?:
    | "AVAILABLE"
    | "NOT_AVAILABLE"
    | "PARTIAL_AVAILABLE"
    | "COMING_SOON";

  subscriptionFeatures?: SubscriptionFeatureView[];
}

export interface AccountSubscriptionBillingInfoView {
  contactInfo?: ContactInfoView;

  billingInfo?: BillingInfoView;
}

export interface BillingInfoView {
  userEmail?: string;

  firstName?: string;

  lastName?: string;

  phoneNumber?: string;

  site?: string;

  company?: string;

  address?: string;

  country?: string;

  postalCode?: string;

  city?: string;

  state?: string;

  taxId?: string;

  taxType?: string;
}

export interface ContactInfoView {
  userEmail?: string;

  firstName?: string;

  lastName?: string;

  phoneNumber?: string;

  site?: string;

  company?: string;

  address?: string;

  country?: string;

  postalCode?: string;

  city?: string;

  state?: string;
}

export interface AccountIntegrationsView {
  integrations?: AccountIntegrationItemView[];
}

export interface AccountIntegrationsSummaryView {
  accountIntegrations?: AccountIntegrationItemView[];

  foundIntegrations?: IntegrationItemView[];

  availableIntegrations?: Record<string, IntegrationItemView[]>;
}

export interface SenderDetailsDeleteView {
  ids?: string[];
}

export interface CampaignMultipleDeleteView {
  ids?: string[];
}

export interface ActivityMultipleDeleteView {
  ids?: string[];
}

export interface BaseCondition extends Condition {
  attributeType?: "AUDIENCE" | "PROFILE" | "ACTIVITY" | "CAMPAIGN" | "SEGMENT";

  attributeName?: string;

  criteria?: string;

  value?: ConditionValueObject;
}

export interface CombinedCondition extends Condition {
  conditionType?: "AND" | "OR";

  conditions?: Condition[];
}

export interface Condition {
  type: string;
}

export interface ConditionValueObject {
  value?: any;

  type: string;
}

export interface SegmentUpdateView {
  name?: string;

  filter?: BaseCondition | CombinedCondition;
}

export interface SegmentIdentityView1 {
  id?: string;

  name?: string;

  numberOfCustomers?: number;

  numberOfCreatedCustomers?: number;

  numberOfUpdatedCustomers?: number;

  segmentType?: string;
}

export interface SuppressionUpdateView {
  name?: string;
}

export interface CustomersUpdateView {
  toAssignCustomerIds?: string[];

  toUnassignCustomerIds?: string[];
}

export interface SegmentTypeStatisticRequestView {
  types?: (
    | "UPLOAD"
    | "INTEGRATION"
    | "CUSTOM"
    | "PREDEFINED_RECOMMENDED"
    | "PREDEFINED_LIBRARY"
  )[];
}

export interface SegmentTypeStatisticResponseView {
  countByType?: Record<string, number>;
}

export interface CustomFont {
  fontName: string;
  fontFamily: string;
  fontUrl: string;
}

export interface SegmentIdentitiesView1 {
  segments?: SegmentIdentityView1[];
}

export interface SegmentFilterView {
  from?: number;

  to?: number;

  searchTextName?: string;

  segmentTypes?: (
    | "UPLOAD"
    | "INTEGRATION"
    | "CUSTOM"
    | "PREDEFINED_RECOMMENDED"
    | "PREDEFINED_LIBRARY"
    | "PREDEFINED_DELIVERY"
  )[];
}

export interface SuppressionFilterView {
  searchTextName?: string;
  suppressionTypes?: ("EMAIL" | "MD5" | "SHA256")[];
  suppressionScopes?: ("LOCAL" | "GLOBAL")[];
}

export interface SuppressionDetailsFilterView {
  searchText?: string;
  suppressionId?: string;
}

export interface SegmentPageSegmentView {
  content?: SegmentView[];

  pageable?: Pageable;

  totalNumberOfCustomers?: number;

  last?: boolean;

  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  first?: boolean;

  numberOfElements?: number;

  size?: number;

  number?: number;

  empty?: boolean;
}
export interface SuppressionView {
  id?: string;
  name?: string;
  type?: "EMAIL" | "MD5" | "SHA256";
  scope?: "GLOBAL" | "LOCAL";
  suppressedCount?: number;
  updatedAt?: number;
  createdAt?: number;
  fileProcessStatus?: "UPLOADED" | "IN_PROGRESS" | "COMPLETED" | "FAILURE";
}

export interface SuppressionPageSuppressionView {
  content?: SuppressionView[];

  pageable?: Pageable;

  totalNumberOfCustomers?: number;

  last?: boolean;

  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  first?: boolean;

  numberOfElements?: number;

  size?: number;

  number?: number;

  empty?: boolean;
}

export interface SegmentView {
  id?: string;

  name?: string;

  segmentType?:
    | "UPLOAD"
    | "INTEGRATION"
    | "CUSTOM"
    | "PREDEFINED_RECOMMENDED"
    | "PREDEFINED_LIBRARY"
    | "PREDEFINED_DELIVERY";

  numberOfCustomers?: number;

  createdAt?: number;

  updatedAt?: number;

  assignedToCampaigns?: boolean;

  assignedTo?: ("CAMPAIGN" | "FORM")[];

  description?: string;

  status?: "IN_PROGRESS" | "COMPLETED" | "FAILED";
}

export interface CustomSegmentSummaryRequestView {
  customSegmentId?: string;

  condition?: BaseCondition | CombinedCondition;

  audiences?: string[];
}

export interface AudienceCriteriaView {
  criteria?: string;

  audiences?: AudienceView[];
}

export interface AudienceView {
  name?: string;

  totalSubscribers?: number;
}

export interface CustomSegmentSummaryResponseView {
  countCustomers?: number;

  countNetCustomers?: number;

  countDelivered?: number;

  countCustomersHavingOpened?: number;

  countCustomersHavingClicked?: number;

  countCustomersHavingUnsubscribed?: number;

  audiences?: AudienceCriteriaView[];

  openedPercentage: number;

  clickedPercentage: number;

  unsubscribedPercentage: number;
}

export interface SegmentCheckExistsFilterView {
  name?: string;

  type?:
    | "UPLOAD"
    | "INTEGRATION"
    | "CUSTOM"
    | "PREDEFINED_RECOMMENDED"
    | "PREDEFINED_LIBRARY";
}

export interface SuppressionCheckExistsFilterView {
  name?: string;

  type?: undefined | string;

  scope: "GLOBAL" | "LOCAL";
}

export interface AudienceMergeView {
  sourceAudienceIds: string[];
  targetAudienceId?: string;
  name?: string;
}

export interface CustomerView {
  id?: string;

  email?: string;

  firstName?: string;

  lastName?: string;

  source?: string;

  birthday?: number;

  tags?: string[];

  currency?: string;

  country?: string;

  province?: string;

  city?: string;

  address1?: string;

  address2?: string;

  phone?: string;

  postalCode?: string;

  company?: string;

  timezone?: string;

  acceptsMarketing?: string;

  ordersCount?: number;

  totalSpent?: number;

  lastOrderId?: string;

  lastOrderName?: string;

  sourceId?: string;

  status?: "Subscribed" | "Unsubscribed" | "Bounced" | "Inactive" | "Spam";

  lastOrderDate?: number;

  lastOrderPrice?: number;

  actionStatus?: string;
}

export interface CustomerFilterView {
  segmentId?: string;

  segmentIds?: string[];

  statuses?: (
    | "Subscribed"
    | "Unsubscribed"
    | "Bounced"
    | "Inactive"
    | "Spam"
  )[];

  searchText?: string;

  assigned?: boolean;

  ids?: string[];

  tags?: string[];

  columns?: string[];
}

export interface SuppressionItemInfoView {
  id: string;
  value: string;
  description: string;
  createdAt: number;
  updatedAt: number;
}

export interface CustomerInfoView {
  id?: string;

  email?: string;

  firstName?: string;

  lastName?: string;

  status?: string;

  statusReason?: string;

  source?: string;

  birthday?: number;

  tags?: string[];

  currency?: string;

  country?: string;

  province?: string;

  city?: string;

  address1?: string;

  address2?: string;

  phone?: string;

  postalCode?: string;

  company?: string;

  timezone?: string;

  acceptsMarketing?: string;

  ordersCount?: number;

  totalSpent?: number;

  lastOrderId?: string;

  lastOrderName?: string;

  lastOrderDate?: number;

  lastOrderPrice?: number;

  open?: number;

  clicked?: number;

  unsubscribed?: number;

  createdAt?: number;

  updatedAt?: number;

  actionStatus?: string;
}

export interface PageCustomerInfoView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  first?: boolean;

  last?: boolean;

  numberOfElements?: number;

  pageable?: Pageable;

  size?: number;

  content?: CustomerInfoView[];

  number?: number;

  empty?: boolean;
}

export interface SuppressionInfoView {
  id: string;
  value: string;
  description?: string;
  createdAt: number;
  updatedAt: number;
}

export interface PageSuppressionInfoView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  first?: boolean;

  last?: boolean;

  numberOfElements?: number;

  pageable?: Pageable;

  size?: number;

  content?: SuppressionInfoView[];

  number?: number;

  empty?: boolean;
}

export interface CustomerCompositeFilterView {
  eventFilters?: FilterViewWrapperCustomerEventFilterView[];

  segmentFilters?: FilterViewWrapperCustomerSegmentFilterView[];

  propertyFilters?: FilterViewWrapperCustomerPropertyFilterView[];

  excludeFilter?: CustomerExcludeFilterView;

  searchText?: string;
}

export interface CustomerEventFilterView {
  negate?: boolean;

  eventType?:
    | "CLICK"
    | "UNIQUE_CLICK"
    | "OPEN"
    | "UNIQUE_OPEN"
    | "SEND"
    | "UNSUBSCRIBE"
    | "DELIVERED"
    | "HARD_BOUNCED"
    | "SOFT_BOUNCED"
    | "SPAM"
    | "NOT_SPAM";

  from?: number;

  to?: number;
}

export interface CustomerExcludeFilterView {
  negate?: boolean;

  customerIds?: string[];
}

export interface CustomerPropertyFilterView {
  negate?: boolean;

  propertyName?: string;
}

export interface CustomerSegmentFilterView {
  negate?: boolean;

  segmentIds?: string[];

  notAssigned?: boolean;
}

export interface FilterViewWrapperCustomerEventFilterView {
  operation?: "OR" | "AND";

  filter?: CustomerEventFilterView;
}

export interface FilterViewWrapperCustomerPropertyFilterView {
  operation?: "OR" | "AND";

  filter?: CustomerPropertyFilterView;
}

export interface FilterViewWrapperCustomerSegmentFilterView {
  operation?: "OR" | "AND";

  filter?: CustomerSegmentFilterView;
}

export interface CustomerActionPlatformFindFilterView {
  campaignId?: string;

  activityType?: "CAMPAIGN" | "AUTOMATION";

  fromDate?: number;

  toDate?: number;

  eventType?:
    | "CLICK"
    | "UNIQUE_CLICK"
    | "OPEN"
    | "UNIQUE_OPEN"
    | "SEND"
    | "UNSUBSCRIBE"
    | "DELIVERED"
    | "HARD_BOUNCED"
    | "SOFT_BOUNCED"
    | "SPAM"
    | "NOT_SPAM";
}

export interface CustomerActionPlatformInfoView {
  campaignId?: string;

  desktop?: number;

  desktopPercent?: number;

  mobile?: number;

  mobilePercent?: number;

  other?: number;

  otherPercent?: number;
}

export interface CustomerActionGeolocationFindFilterView {
  campaignId?: string;

  activityType?: "CAMPAIGN" | "AUTOMATION";

  fromDate?: number;

  toDate?: number;

  eventType?:
    | "CLICK"
    | "UNIQUE_CLICK"
    | "OPEN"
    | "UNIQUE_OPEN"
    | "SEND"
    | "UNSUBSCRIBE"
    | "DELIVERED"
    | "HARD_BOUNCED"
    | "SOFT_BOUNCED"
    | "SPAM"
    | "NOT_SPAM";

  topNth?: number;
}

export interface CustomerActionGeolocationInfoView {
  campaignId?: string;

  countryName?: string;

  countryCode?: string;

  count?: number;
}

export interface CustomerActionDomainFindFilterView {
  campaignId?: string;

  activityType?: "CAMPAIGN" | "AUTOMATION";

  fromDate?: number;

  toDate?: number;

  eventType:
    | "CLICK"
    | "UNIQUE_CLICK"
    | "OPEN"
    | "UNIQUE_OPEN"
    | "SEND"
    | "UNSUBSCRIBE"
    | "DELIVERED"
    | "HARD_BOUNCED"
    | "SOFT_BOUNCED"
    | "SPAM"
    | "NOT_SPAM";

  minPercentRate?: number;

  limit?: number;
}

export interface CustomerActionDomainInfoView {
  campaignId?: string;

  domain?: string;

  count?: number;

  percent?: number;
}

export interface CustomerSegmentInfoView {
  id?: string;

  segmentIds?: string[];

  email?: string;

  status?: string;
}

export interface PageCustomerSegmentInfoView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  first?: boolean;

  last?: boolean;

  numberOfElements?: number;

  pageable?: Pageable;

  size?: number;

  content?: CustomerSegmentInfoView[];

  number?: number;

  empty?: boolean;
}

export interface CustomerBatchView {
  accountId?: string;

  customers?: CustomerView[];
}

export interface UploadSegmentCreateView {
  name?: string;
}

export interface SegmentTotalStatisticsResponseView {
  numberOfCustomers?: number;

  open?: number;

  clicked?: number;

  unsubscribed?: number;

  openProgress?: number;

  clickedProgress?: number;

  unsubscribedProgress?: number;
}

export interface SegmentTotalCustomersStatisticsRequestView {
  integrationLimit?: number;
}

export interface SegmentCustomersStatisticView {
  name?: string;

  numberOfCustomers?: number;
}

export interface SegmentTotalCustomersStatisticsResponseView {
  segments?: SegmentCustomersStatisticView[];
}

export interface IntegrationSegmentStatisticsGrowthRequestView {
  from?: number;

  to?: number;
}

export interface IntegrationSegmentStatisticGrowthView {
  name?: string;

  logoDefault?: string;

  total?: number;

  growth?: number;
}

export interface IntegrationSegmentStatisticsGrowthResponseView {
  integrationSegments?: IntegrationSegmentStatisticGrowthView[];
}

export interface SegmentImportView {
  id?: string;

  name?: string;

  segmentType?:
    | "UPLOAD"
    | "INTEGRATION"
    | "CUSTOM"
    | "PREDEFINED_RECOMMENDED"
    | "PREDEFINED_LIBRARY";

  customers?: CustomerView[];
}

export interface CustomSegmentCreateView {
  name?: string;

  filter?: BaseCondition | CombinedCondition;
}

export interface SegmentCloneRequestView {
  cloneSegmentId?: string;

  name?: string;
}

export interface CustomerStatisticsTotalRequestView {
  from?: number;

  to?: number;
}

export interface SuppressionStatisticsTotalRequestView {
  from?: number;

  to?: number;
}

export interface CustomerStatisticsTotalResponseView {
  fromManualUploads?: number;

  fromIntegrations?: number;

  fromManualUploadsProgress?: number;

  fromIntegrationsProgress?: number;

  numberOfUniqueCustomers?: number;
}

export interface SuppressionStatisticsTotalResponseView {
  totalGlobalSuppressed: number;
  totalLocalSuppressed: number;
}

export interface CustomerStatisticsGroupedRequestView {
  from?: number;

  to?: number;

  groupTimeUnit?: "HOUR" | "DAY" | "MONTH" | "YEAR";
}

export interface CustomerStatisticsGroupedResponseView {
  timeGroupStatisticsList?: CustomerTimeGroupStatisticsView[];
}

export interface CustomerTimeGroupStatisticsView {
  segmentType?:
    | "UPLOAD"
    | "INTEGRATION"
    | "CUSTOM"
    | "PREDEFINED_RECOMMENDED"
    | "PREDEFINED_LIBRARY";

  customerAssignedToSegmentAt?: number;

  count?: number;
}

export interface CustomerEventStatisticsGroupedRequestView {
  from?: number;

  to?: number;

  eventTypes?: (
    | "CLICK"
    | "UNIQUE_CLICK"
    | "OPEN"
    | "UNIQUE_OPEN"
    | "SEND"
    | "UNSUBSCRIBE"
    | "DELIVERED"
    | "HARD_BOUNCED"
    | "SOFT_BOUNCED"
    | "SPAM"
    | "NOT_SPAM"
  )[];

  groupTimeUnit?: "HOUR" | "DAY" | "MONTH" | "YEAR";

  parentCampaignId?: string;
}

export interface CustomerEventStatisticsGroupedResponseView {
  eventTimeGroupStatistics?: CustomerEventTimeGroupStatisticsView[];
}

export interface CustomerEventTimeGroupStatisticsView {
  eventType?:
    | "CLICK"
    | "UNIQUE_CLICK"
    | "OPEN"
    | "UNIQUE_OPEN"
    | "SEND"
    | "UNSUBSCRIBE"
    | "DELIVERED"
    | "HARD_BOUNCED"
    | "SOFT_BOUNCED"
    | "SPAM"
    | "NOT_SPAM";

  eventTime?: number;

  count?: number;

  hoursPassed?: number;
}

export interface CustomerEventStatisticsTotalRequestView {
  from?: number;

  to?: number;

  parentCampaignId?: string;
}

export interface CustomerEventStatisticsTotalResponseView {
  sent?: number;

  open?: number;

  requests?: number;

  uniqueOpen?: number;

  clicked?: number;

  uniqueClicked?: number;

  unsubscribed?: number;

  delivered?: number;

  bounced?: number;

  spam?: number;

  openProgress?: number;

  uniqueOpenProgress?: number;

  uniqueClickedProgress: number;

  clickedProgress?: number;

  unsubscribedProgress?: number;

  deliveredProgress?: number;

  bouncedProgress?: number;

  spamProgress?: number;
}

export interface CustomerEventMostOpenStatisticsFilterView {
  searchText?: string;
  parentCampaignId?: string;
}

export interface CustomerEventMostOpenStatisticsResponseView {
  openEvents?: CustomerGroupedOpenEventView[];
}

export interface CustomerGroupedOpenEventView {
  email?: string;

  numberOfOpens?: number;
}

export interface CustomerEventMostClickStatisticsFilterView {
  searchText?: string;
  parentCampaignId?: string;
}

export interface CustomerEventMostClickStatisticsResponseView {
  clickEvents?: CustomerGroupedClickEventView[];
}

export interface CustomerGroupedClickEventView {
  link?: string;

  numberOfClicks?: number;
}

export interface SegmentDetailsView {
  id?: string;

  name?: string;

  segmentType?:
    | "UPLOAD"
    | "INTEGRATION"
    | "CUSTOM"
    | "PREDEFINED_RECOMMENDED"
    | "PREDEFINED_LIBRARY";

  numberOfCustomers?: number;

  createdAt?: number;

  updatedAt?: number;

  sent?: number;

  open?: number;

  clicked?: number;

  unsubscribed?: number;

  delivered?: number;

  bounced?: number;

  spam?: number;

  openProgress?: number;

  clickedProgress?: number;

  unsubscribedProgress?: number;

  deliveredProgress?: number;

  bouncedProgress?: number;

  spamProgress?: number;

  filter?: BaseCondition | CombinedCondition;
}

export interface Attribute {
  criteria?: Record<string, any>;

  name?: string;

  type?: string;

  hidden?: boolean;
}

export interface AttributeType {
  hidden?: boolean;

  attributes?: Attribute[];
}

export interface SegmentAttributesView {
  attributesTypes?: Record<string, any>;
}

export interface SegmentsView {
  segments?: SegmentView[];
}

export interface TagsView {
  tags?: string[];
}

export interface CustomerTotalView {
  totalDistinct?: number;

  totalActive?: number;
}

export interface SegmentMultipleDeleteView {
  ids?: string[];
}
export interface SuppressionMultipleDeleteView {
  ids?: string[];
}
export interface SegmentUnassignCustomersView {
  ids?: string[];
}

export interface SubjectLineFilterView {
  searchText?: string;

  isFavorite?: boolean;
}

export interface PageSubjectLineView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  numberOfElements?: number;

  pageable?: Pageable;

  first?: boolean;

  last?: boolean;

  size?: number;

  content?: SubjectLineView[];

  number?: number;

  empty?: boolean;
}

export interface SubjectLineView {
  id?: string;

  text?: string;

  isFavorite?: boolean;
}

export interface SubjectGenerateRequestView {
  productName?: string;

  description?: string;

  toneId?: string;

  mergeTagIds?: string[];
}

export interface SubjectGenerateResponseView {
  totalAvailable?: number;

  totalUsed?: number;

  subjects?: SubjectLineView[];
}

export interface ToneView {
  id?: string;

  name?: string;

  description?: string;
}

export interface TonesView {
  tones?: ToneView[];
}

export interface MergeTagView {
  id?: string;

  label?: string;

  value?: string;
}

export interface MergeTagsView {
  mergeTags?: MergeTagView[];
}

export interface SubjectLineFeatureInfoView {
  generalInfo?: string;

  submitInfo?: string;

  totalAvailable?: number;

  totalUsed?: number;
}

export interface FormUpdateRequestView {
  name?: string;

  audienceId?: string;

  formType?: "EMBEDDED" | "POPUP" | "FLYOUT";

  formStatus?: "DRAFT" | "ACTIVE" | "INACTIVE";
}

export interface FormUpdateResponseView {
  id?: string;

  name?: string;

  audienceName?: string;

  formType?: "EMBEDDED" | "POPUP" | "FLYOUT";

  formStatus?: "DRAFT" | "ACTIVE" | "INACTIVE";
}

export interface FormFilterView {
  searchText?: string;

  formStatuses?: ("DRAFT" | "ACTIVE" | "INACTIVE")[];

  formTypes?: ("EMBEDDED" | "POPUP" | "FLYOUT")[];
}

export interface FormInfoView {
  id?: string;

  name?: string;

  formType?: "EMBEDDED" | "POPUP" | "FLYOUT";

  formStatus?: "DRAFT" | "ACTIVE" | "INACTIVE";

  lastSubmittedAt?: number;

  numberOfSubmissions?: number;

  updatedAt?: number;
}

export interface PageFormInfoView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  numberOfElements?: number;

  pageable?: Pageable;

  first?: boolean;

  last?: boolean;

  size?: number;

  content?: FormInfoView[];

  number?: number;

  empty?: boolean;
}

export interface CategoryElementView extends UiSchemaElementView {
  label?: string;
}

export interface ControlElementView extends UiSchemaElementView {
  label?: any;

  scope?: string;
}

export interface FormAppearanceView {
  width?: string;

  height?: string;

  backgroundColor?: string;

  font?: string;

  borderWidth?: string;

  matchWebsite?: boolean;

  successMessage?: string;

  formType?: "EMBEDDED" | "POPUP" | "FLYOUT";

  triggerDetails?: FormTriggerDetailsView;
}

export interface FormConfigView {
  formId?: string;

  audienceId?: string;

  formType?: "EMBEDDED" | "POPUP" | "FLYOUT";

  allowedDomains?: string[];

  generatedCode?: string;

  payload?: FormPayloadView;

  isDirty?: boolean;
}

export interface FormContentView {
  schema?: JsonSchemaView;

  uiSchema?:
    | CategoryElementView
    | ControlElementView
    | GroupElementView
    | LabelElementView
    | LayoutElementView;

  data?: Record<string, any>;
}

export interface FormPayloadView {
  formAppearance?: FormAppearanceView;

  formContent?: FormContentView;
}

export interface FormTriggerDetailsView {
  triggerType?: "PAGE_LOAD" | "RULE" | "CUSTOM";

  triggerEvents?: FormTriggerEventView[];
}

export interface FormTriggerEventView {
  triggerEventType?: "PAGE_LEAVE" | "TIMEOUT" | "SCROLL";

  options?: Record<string, any>;
}

export interface GroupElementView extends UiSchemaElementView {
  label?: string;
}

export interface JsonSchemaView {
  id?: string;

  title?: string;

  description?: string;

  multipleOf?: number;

  maximum?: number;

  exclusiveMaximum?: boolean;

  minimum?: number;

  exclusiveMinimum?: boolean;

  maxLength?: number;

  minLength?: number;

  pattern?: string;

  additionalItems?: any;

  items?: any;

  maxItems?: number;

  minItems?: number;

  uniqueItems?: boolean;

  maxProperties?: number;

  minProperties?: number;

  required?: string[];

  additionalProperties?: any;

  definitions?: Record<string, any>;

  properties?: Record<string, any>;

  patternProperties?: Record<string, any>;

  dependencies?: Record<string, any>;

  type?: string;

  allOf?: JsonSchemaView[];

  anyOf?: JsonSchemaView[];

  oneOf?: JsonSchemaView[];

  not?: JsonSchemaView;

  format?: string;

  readOnly?: boolean;

  writeOnly?: boolean;

  examples?: any[];

  contains?: JsonSchemaView;

  propertyNames?: JsonSchemaView;

  errorMessage?: any;

  $ref?: string;

  $schema?: string;

  default?: any;

  enum?: string[];

  const?: any;

  if?: JsonSchemaView;

  then?: JsonSchemaView;

  else?: JsonSchemaView;
}
export interface LabelElementView extends UiSchemaElementView {
  text?: string;
}
export interface LayoutElementView extends UiSchemaElementView {
  elements?: UiSchemaElementView[];
}
export interface UiSchemaElementView {
  type?: string;

  rule?: any;

  options?: Record<string, any>;
}
export interface FormAllowedDomainsView {
  allowedDomains?: string[];
}
export interface FormCheckAssignedAudiencesRequestView {
  audienceIds?: string[];
}
export interface FormCheckAssignedAudiencesResponseView {
  audienceIds?: string[];
}
export interface FormTemplateFilterView {
  searchNameText?: string;

  types?: ("EMBEDDED" | "POPUP" | "FLYOUT")[];

  tagIds?: string[];

  isFavorite?: boolean;
}
export interface FormTemplateInfoView {
  id?: string;

  name?: string;

  formType?: "EMBEDDED" | "POPUP" | "FLYOUT";

  payLoad?: string;

  isFavorite?: boolean;
}
export interface PageFormTemplateInfoView {
  totalPages?: number;

  totalElements?: number;

  sort?: Sort;

  numberOfElements?: number;

  pageable?: Pageable;

  first?: boolean;

  last?: boolean;

  size?: number;

  content?: FormTemplateInfoView[];

  number?: number;

  empty?: boolean;
}
export interface FormView {
  name?: string;

  audienceId?: string;

  formType?: "EMBEDDED" | "POPUP" | "FLYOUT";
}
export interface FormIdentityView {
  id?: string;
}
export interface FormCloneRequestView {
  cloneFormId?: string;
}
export interface FormCloneResponseView {
  id?: string;

  name?: string;
}
export interface FormWithTemplateView {
  name?: string;

  audienceId?: string;

  formTemplateId?: string;
}
export interface FormPlatformUsageView {
  numberOfForms?: number;
}
export interface FormDetailsView {
  formId?: string;

  name?: string;

  formType?: "EMBEDDED" | "POPUP" | "FLYOUT";

  formStatus?: "DRAFT" | "ACTIVE" | "INACTIVE";

  allowedDomains?: string[];

  generatedCode?: string;

  audienceId?: string;

  payload?: FormPayloadView;

  isDirty?: boolean;
}
export interface FormDeleteRequestView {
  formIds?: string[];
}
export interface AccountAutomationView {
  id?: string;

  name?: string;

  description?: string;

  infoDetails?: string;

  enabled?: boolean;

  comingSoon?: boolean;

  emailTemplate?: AutomationTemplateView;
}

export interface WorkflowTemplateView {
  id?: string;

  name?: string;

  triggerType?: string;

  status?: string;

  category?: string;

  description?: string;

  infoDescription?: string;

  imagePath?: string;

  structure?: GraphStructure;
}

export interface AutomationTemplateView {
  templateCss?: string;

  templateHtml?: string;
}
export interface AutomationsView {
  automations?: AccountAutomationView[];
}
export default abstract class GeneratedApi {
  abstract request<T>({
    secure,
    path,
    type,
    query,
    format,
    body,
    appName,
    ...params
  }: FullRequestParams & { appName?: string }): Promise<AxiosResponse<T>>;

  putSubscriptionLeadContextEmail(
    data: LeadIdentityView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/subscription/lead/context-email",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getSenderDetailsById(id: string, params: RequestParams = {}) {
    return this.request<SenderDetailsView>({
      path: `/sender-details/${id}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getWorkflowTemplateById(id: string, params: RequestParams = {}) {
    return this.request<WorkflowTemplateView>({
      path: `/workflow/template/${id}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putSenderDetailsById(
    id: string,
    data: SenderDetailsUpdateView,
    params: RequestParams = {}
  ) {
    return this.request<SenderDetailsView>({
      path: `/sender-details/${id}`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteSenderDetailsById(id: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/sender-details/${id}`,
      method: "DELETE",

      type: ContentType.Json,
      ...params,
    });
  }

  putSenderDetailsFindFilter(
    data: SenderDetailsFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageSenderDetailsView>({
      path: "/sender-details/find/filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putPaymentAccountPaymentMethodDefaultById(
    id: string,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/payment-account/payment-method/${id}/default`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putPaymentAccountInvoicePayByInvoiceId(
    invoiceId: string,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/payment-account/invoice/${invoiceId}/pay`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putPaymentAccountInvoiceExport(
    data: ExportInvoicesView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/payment-account/invoice/export",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putIntegrationFindFilter(
    data: IntegrationFilterView,
    params: RequestParams = {}
  ) {
    return this.request<IntegrationsView>({
      path: "/integration/find/filter",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getIdentityLogoById(id: string, params: RequestParams = {}) {
    return this.request<IdentityLogoView>({
      path: `/identity/${id}/logo`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putIdentityLogoById(
    id: string,
    data: {
      logoFile?: File;
    },
    params: RequestParams = {}
  ) {
    return this.request<IdentityLogoView>({
      path: `/identity/${id}/logo`,
      method: "PUT",

      body: data,
      type: ContentType.FormData,
      ...params,
    });
  }

  deleteIdentityLogoById(id: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/identity/${id}/logo`,
      method: "DELETE",

      type: ContentType.Json,
      ...params,
    });
  }

  getIdentity(params: RequestParams = {}) {
    return this.request<IdentityView>({
      path: "/identity",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putIdentity(
    data: {
      logoFile?: File;
      identity: IdentityView;
    },
    params: RequestParams = {}
  ) {
    return this.request<IdentityView>({
      path: "/identity",
      method: "PUT",

      body: data,
      type: ContentType.FormData,
      ...params,
    });
  }

  postSuppression(
    data: {
      file?: File;
      suppression: CreateSuppressionRequestView;
    },
    params: RequestParams = {}
  ) {
    return this.request<CreateSuppressionResponseView>({
      path: "/customer/suppression/import",
      method: "POST",

      body: data,
      type: ContentType.FormData,
      ...params,
    });
  }

  postSuppressionDetails(
    suppressionId: string,
    data: {
      suppressionValues: suppressionDetailsItem[];
    },
    params: RequestParams = {}
  ) {
    return this.request<CreateSuppressionResponseView>({
      path: `/customer/suppression/${suppressionId}/suppression_values`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postIdentity(
    data: {
      logoFile?: File;
      identity: IdentityView;
    },
    params: RequestParams = {}
  ) {
    return this.request<IdentityView>({
      path: "/identity",
      method: "POST",

      body: data,
      type: ContentType.FormData,
      ...params,
    });
  }

  getCampaignById(id: string, params: RequestParams = {}) {
    return this.request<CampaignResponseView>({
      path: `/campaign/${id}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getPredefinedSegmentCounts(id: string, params: RequestParams = {}) {
    return this.request<PredefinedSegmentsCountsResponseView>({
      path: `/pre-defined-segment/${id}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignById(
    id: string,
    data: CampaignUpdateRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CampaignResponseView>({
      path: `/campaign/${id}/details`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putWorkflowById(
    id: string,
    data: CampaignUpdateRequestView,
    params: RequestParams = {}
  ) {
    return this.request<WorkflowDetailsView>({
      path: `/workflow/${id}`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteCampaignById(id: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/campaign/${id}`,
      method: "DELETE",

      type: ContentType.Json,
      ...params,
    });
  }

  deleteWorkflowById(id: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/workflow/${id}`,
      method: "DELETE",

      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignUnscheduleById(id: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/campaign/${id}/unschedule`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignStopRecurringById(id: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/campaign/${id}/stop-recurring`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignResumeRecurringById(id: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/campaign/${id}/resume-recurring`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putWorkflowActivateById(id: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/workflow/${id}/start`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putWorkflowPauseById(id: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/workflow/${id}/pause`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putWorkflowsTestEmailsById(
    id: string,
    data: CampaignTestEmailsView,
    params: RequestParams = {}
  ) {
    return this.request<CampaignRemainedTestEmailsView>({
      path: `/workflow-email-template/${id}/test-emails`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignTemplateById(id: string, params: RequestParams = {}) {
    return this.request<CampaignResponseView>({
      path: `/campaign/${id}/template`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignStartById(
    id: string,
    data: CampaignStartView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/campaign/${id}/start`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignSenderDetailsById(
    id: string,
    data: SenderDetailsIdentityView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/campaign/${id}/sender-details`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignContentSourceById(
    id: string,
    data: CampaignContentSourceRequestView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/campaign/${id}/content-source`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getCampaignSegmentsById(id: string, params: RequestParams = {}) {
    return this.request<AudienceIdentitiesView>({
      path: `/campaign/${id}/audiences`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getCampaignSuppressionsById(id: string, params: RequestParams = {}) {
    return this.request<SuppressionIdentitiesView>({
      path: `/campaign/${id}/suppressions`,
      method: "GET",
      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignSegmentsById(
    id: string,
    data: SegmentIdentitiesView,
    params: RequestParams = {}
  ) {
    const payload = {
      audiences: data?.segments?.map((segment) => {
        return {
          id: segment?.id,
          audienceType: segment?.segmentType,
          ...segment,
        };
      }),
    };
    return this.request<void>({
      path: `/campaign/${id}/audiences`,
      method: "PUT",

      body: payload,
      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignSuppressionsById(
    id: string,
    data: string[],
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/campaign/${id}/suppressions`,
      method: "PUT",

      body: {
        suppressionIds: data,
      },
      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignCheckById(
    id: string,
    data: CampaignStartView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/campaign/${id}/check`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getCampaignAutoTrackingById(id: string, params: RequestParams = {}) {
    return this.request<EnabledView>({
      path: `/campaign/${id}/auto-tracking`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignAutoTrackingById(
    id: string,
    data: EnabledView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/campaign/${id}/auto-tracking`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getCampaignActivitiesById(id: string, params: RequestParams = {}) {
    return this.request<ActivityDetailsView>({
      path: "/campaign-layout",
      method: "PUT",
      body: {
        campaignId: id,
      },
      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignActivitiesById(
    id: string,
    data: ActivityIdentitiesView,
    params: RequestParams = {}
  ) {
    const payload = data?.activities?.map((activity) => {
      const result = {
        ...activity,
        campaignId: id,
        html: activity?.templateHtml,
        css: activity?.templateCss,
      };
      delete result?.templateHtml;
      delete result?.templateCss;
      return result;
    })[0];
    return this.request<void>({
      path: "/campaign-layout",
      method: "POST",
      body: payload,
      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignActivitiesEmailMessageByCampaignId(
    campaignId: string,
    data: ActivityDetailsView,
    params: RequestParams = {}
  ) {
    const payload = {
      ...data,
      campaignId: campaignId,
      html: data?.templateHtml,
      css: data?.templateCss,
    };
    delete payload?.templateHtml;
    delete payload?.templateCss;
    return this.request<any>({
      path: "/campaign-layout",
      method: "POST",
      body: payload,
      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignSegmentsCheckAssigned(data: IdsView, params: RequestParams = {}) {
    return this.request<IdsView>({
      path: "/campaign/segments/check-assigned",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignFindFilter(
    data: CampaignFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageCampaignInfoView>({
      path: "/campaign/find/filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putWorkflowTemplateFindFilter(
    data: WorkflowTemplateFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageWorkflowTemplateInfoView>({
      path: "/workflow/template",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postDomainEngagementFindFilter(
    data: DomainEngagementFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageDomainEngagementInfoView>({
      path: "/campaign/domain-stats/",
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postDomainDeliverabilityFindFilter(
    data: DomainDeliverabilityFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageDomainDeliverabilityInfoView>({
      path: "/campaign/domain-stats/",
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putWorkflowFindFilter(
    data: WorkflowFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageWorkflowInfoView>({
      path: "/workflow",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getWorkflowEmailTemplate(id: string, params: RequestParams = {}) {
    return this.request<WorkflowEmailTemplateView>({
      path: `/workflow-email-template/${id}`,
      method: "GET",
      type: ContentType.Json,
      ...params,
    });
  }

  postWorkflowEmailTemplate(
    data: WorkflowEmailTemplateCreateRequestView,
    params: RequestParams = {}
  ) {
    return this.request<WorkflowEmailTemplateCreateResponseView>({
      path: "/workflow-email-template",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putWorkflowEmailTemplate(
    id: string,
    data: WorkflowEmailTemplateUpdateRequestView,
    params: RequestParams = {}
  ) {
    return this.request<WorkflowEmailTemplateUpdateResponseView>({
      path: `/workflow-email-template/${id}`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putCampaignFindFilterExport(
    data: CampaignFilterView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/campaign/find/filter/export",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getActivityById(id: string, params: RequestParams = {}) {
    return this.request<ActivityDetailsView>({
      path: `/account-html-template/${id}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putActivityById(
    id: string,
    data: ActivityDetailsView,
    params: RequestParams = {}
  ) {
    return this.request<ActivityIdentityView>({
      path: `/account-html-template/${id}`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putActivityFavoriteByIdAndIsFavorite(
    id: string,
    isFavorite: boolean,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/account-html-template/${id}/favorite/${isFavorite}`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putActivityFindFilter(
    data: ActivityFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageActivityIdentityView>({
      path: "/account-html-template/find/filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putActivityTemplateEmailFavoriteByIdAndIsFavorite(
    id: string,
    isFavorite: boolean,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/html-template/email/${id}/favorite/${isFavorite}`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putActivityTemplateEmailFindFilter(
    data: EmailActivityTemplateFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageEmailActivityTemplateIdentityView>({
      path: "/html-template/email/find/filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getAccount(params: RequestParams = {}) {
    return this.request<AccountView>({
      path: "/account",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putAccount(data: AccountView, params: RequestParams = {}) {
    return this.request<AccountView>({
      path: "/account",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postAccount(data: AccountCreateView, params: RequestParams = {}) {
    return this.request<AccountView>({
      path: "/account",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteAccount(
    query: {
      email: string;
    },
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/account",
      method: "DELETE",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  putAccountCanceledByAccountId(
    accountId: string,
    query: {
      reason: string;
      issueType:
        | "SUBSCRIPTION_PAYMENT"
        | "ACCOUNT_SETUP"
        | "SUBSCRIPTION_LIMIT"
        | "HIGH_BOUNCE_RATE"
        | "HIGH_SPAM_RATE"
        | "HIGH_UNSUBSCRIBE_RATE"
        | "ACCOUNT_ISSUE";
    },
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/account/${accountId}/canceled`,
      method: "PUT",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  putAccountAccountIntegrationExecutionByAccountId(
    accountId: string,
    data: AccountIntegrationExecutionView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/account/${accountId}/account-integration/execution`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putAccountNotificationSettingNotification(
    data: ConfigurableNotificationUpdateView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/account/notification-setting/notification",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putAccountNotificationSettingCategory(
    data: ConfigurableNotificationCategoryUpdateView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/account/notification-setting/category",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putAccountSubscriptionCalculateCost(
    data: SubscriptionCostCalculateParametersView,
    params: RequestParams = {}
  ) {
    return this.request<SubscriptionCostView>({
      path: "/account-subscription/calculate-cost",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getAccountSubscriptionBillingInfo(params: RequestParams = {}) {
    return this.request<AccountSubscriptionBillingInfoView>({
      path: "/account-subscription/billing-info",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putAccountSubscriptionBillingInfo(
    data: AttachBillingInformationView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/account-subscription/billing-info",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putAccountIntegrationTriggerByIntegrationSystemName(
    integrationSystemName: string,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/account-integration/${integrationSystemName}/trigger`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putAccountIntegrationSave(params: RequestParams = {}) {
    return this.request<void>({
      path: "/account-integration/save",
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  postTicket(data: TicketCreateRequestDto, params: RequestParams = {}) {
    return this.request<void>({
      path: "/ticket",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getSubscriptionLead(params: RequestParams = {}) {
    return this.request<LeadView>({
      path: "/subscription/lead",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  postSubscriptionLead(data: LeadView, params: RequestParams = {}) {
    return this.request<LeadIdentityView>({
      path: "/subscription/lead",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSubscriptionAppsumoLicense(params: RequestParams = {}) {
    return this.request<LicenseCreatedView>({
      path: "/subscription/appsumo/license",
      method: "POST",

      type: ContentType.Json,
      ...params,
    });
  }

  getSenderDetails(params: RequestParams = {}, appName?: string) {
    return this.request<SenderDetailsesView>({
      path: "/sender-details",
      method: "GET",
      appName: appName,
      type: ContentType.Json,
      ...params,
    });
  }

  getSenderDetailsDefault(params: RequestParams = {}, appName?: string) {
    return this.request<SenderDetailsView>({
      path: "/sender-details/default",
      method: "GET",
      appName: appName,
      type: ContentType.Json,
      ...params,
    });
  }
  getCustomFonts(params: RequestParams = {}) {
    return this.request<CustomFont[]>({
      path: "/custom-font",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getSenderDetailsByAccountId(accountId: string, params: RequestParams = {}) {
    return this.request<SenderDetailsView[]>({
      path: `/account/${accountId}/sender-details`,
      method: "GET",
      type: ContentType.Json,
      ...params,
    });
  }

  postSenderDetails(data: SenderDetailsCreateView, params: RequestParams = {}) {
    return this.request<SenderDetailsView>({
      path: "/sender-details",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteSenderDetails(
    data: SenderDetailsDeleteView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/sender-details",
      method: "DELETE",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSenderDetailsEmailConfirmTokenBySenderDetailsId(
    senderDetailsId: string,
    params: RequestParams = {}
  ) {
    return this.request<string>({
      path: `/sender-details/${senderDetailsId}/email-confirm-token`,
      method: "POST",

      type: ContentType.Json,
      ...params,
    });
  }

  postSenderDetailsConfirmEmail(
    data: SenderDetailsEmailConfirmationView,
    params: RequestParams = {}
  ) {
    return this.request<SenderDetailsView>({
      path: "/sender-details/confirm-email",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postPaymentAccountWebhook(params: RequestParams = {}) {
    return this.request<void>({
      path: "/payment-account/webhook",
      method: "POST",

      type: ContentType.Json,
      ...params,
    });
  }

  getPaymentAccountPaymentMethod(params: RequestParams = {}) {
    return this.request<PaymentMethodsView>({
      path: "/payment-account/payment-method",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  postPaymentAccountPaymentMethod(
    data: AttachPaymentMethodView,
    params: RequestParams = {}
  ) {
    return this.request<PaymentMethodView>({
      path: "/payment-account/payment-method",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCampaign(data: CampaignCreateRequestView, params: RequestParams = {}) {
    return this.request<CampaignResponseView>({
      path: "/campaign/details",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomFonts(data: customFontsRequestView, params: RequestParams = {}) {
    return this.request<customFontsResponseView>({
      path: "/custom-font",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postWorkflow(data: WorkflowCreateRequestView, params: RequestParams = {}) {
    return this.request<CreateWorkflowResponseView>({
      path: "/workflow",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getWorkflowById(id: string, params: RequestParams = {}) {
    return this.request<WorkflowDetailsView>({
      path: `/workflow/${id}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  deleteCampaign(data: CampaignMultipleDeleteView, params: RequestParams = {}) {
    return this.request<void>({
      path: "/campaign",
      method: "DELETE",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCampaignSenderDetailsEmailConfirmTokenByCampaignIdAndSenderDetailsId(
    campaignId: string,
    senderDetailsId: string,
    params: RequestParams = {}
  ) {
    return this.request<string>({
      path: `/campaign/${campaignId}/sender-details/${senderDetailsId}/email-confirm-token`,
      method: "POST",

      type: ContentType.Json,
      ...params,
    });
  }

  postCampaignTemplate(
    data: CampaignCreateByTemplateRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CampaignResponseView>({
      path: "/campaign/template",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCampaignStatisticsTotal(
    data: CampaignStatisticsTotalRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CampaignStatisticsTotalResponseView>({
      path: "/campaign/statistics/total",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCampaignStatisticsGrouped(
    data: CampaignStatisticsGroupedRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CampaignStatisticsGroupedResponseView>({
      path: "/campaign/statistics/grouped",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCampaignClone(
    data: CampaignCloneRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CampaignResponseView>({
      path: "/campaign/clone",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postWorkflowClone(
    data: WorkflowCloneRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CreateWorkflowResponseView>({
      path: `/workflow/${data?.cloneWorkflowId}/clone`,
      method: "POST",

      type: ContentType.Json,
      ...params,
    });
  }

  postActivity(data: ActivityDetailsView, params: RequestParams = {}) {
    return this.request<ActivityIdentityView>({
      path: "/account-html-template",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteActivity(data: ActivityMultipleDeleteView, params: RequestParams = {}) {
    return this.request<void>({
      path: "/account-html-template",
      method: "DELETE",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postAccountAccountIntegrationByAccountId(
    accountId: string,
    data: AccountIntegrationView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/account/${accountId}/account-integration`,
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getAccountSubscription(params: RequestParams = {}) {
    return this.request<AccountSubscriptionView>({
      path: "/account-subscription",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  postAccountSubscription(
    data: AssignSubscriptionView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/account-subscription",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postAccountSubscriptionPaymentMethod(
    data: AssignSubscriptionWithPaymentMethodView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/account-subscription/payment-method",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postAccountSubscriptionPaymentMethodByPaymentMethodId(
    paymentMethodId: string,
    data: AssignSubscriptionWithBillingInfoView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/account-subscription/payment-method/${paymentMethodId}`,
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postAccountSubscriptionDefault(params: RequestParams = {}) {
    return this.request<void>({
      path: "/account-subscription/default",
      method: "POST",

      type: ContentType.Json,
      ...params,
    });
  }

  getTemplateTag(params: RequestParams = {}) {
    return this.request<TemplateTagsView>({
      path: "/template-tag",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getSubscription(params: RequestParams = {}) {
    return this.request<SubscriptionsView>({
      path: "/subscription",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getSubscriptionByTierByTierId(tierId: string, params: RequestParams = {}) {
    return this.request<SubscriptionView>({
      path: `/subscription/by-tier/${tierId}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getSubscriptionFeatureAvailable(params: RequestParams = {}) {
    return this.request<SubscriptionFeaturesAvailabilityView[]>({
      path: "/subscription-feature/available",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getSubscriptionFeatureAvailableByFeatureSystemName(
    featureSystemName: string,
    params: RequestParams = {}
  ) {
    return this.request<SubscriptionFeaturesAvailabilityView>({
      path: `/subscription-feature/available/${featureSystemName}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getSubscriptionFeatureAvailableByCategories(params: RequestParams = {}) {
    return this.request<SubscriptionFeaturesCategoriesAvailabilityView[]>({
      path: "/subscription-feature/available/by-categories",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getSenderDetailsCheckExistsByName(name: string, params: RequestParams = {}) {
    return this.request<boolean>({
      path: `/sender-details/check-exists/${name}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getPaymentAccountInvoice(
    query: {
      limit?: number;
      startAfter?: string;
    },
    params: RequestParams = {}
  ) {
    return this.request<InvoicesView>({
      path: "/payment-account/invoice",
      method: "GET",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  getIntegration(params: RequestParams = {}) {
    return this.request<IntegrationsView>({
      path: "/integration",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getIntegrationProfiled(params: RequestParams = {}) {
    return this.request<IntegrationsView>({
      path: "/integration/profiled",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getIdentitySocialLinksProfiled(params: RequestParams = {}) {
    return this.request<SocialLinksView>({
      path: "/identity/social-links/profiled",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getIdentityProfiled(params: RequestParams = {}) {
    return this.request<IdentityProfiledView>({
      path: "/identity/profiled",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getEditorToken(params: RequestParams = {}) {
    return this.request<EditorTokenView>({
      path: "/editor/token",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getCampaignEmailFooterByCampaignId(
    campaignId: string,
    params: RequestParams = {}
  ) {
    return this.request<ActivityEmailFooterView>({
      path: `/campaign/${campaignId}/email/footer`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getCampaignActivitiesByCampaignIdAndActivityId(
    campaignId: string,
    activityId: string,
    params: RequestParams = {}
  ) {
    return this.request<ActivityDetailsView>({
      path: "/campaign-layout",
      method: "PUT",
      body: {
        campaignId,
      },
      type: ContentType.Json,
      ...params,
    });
  }

  getCampaignActivitiesTemplateAttributesByCampaignId(
    campaignId: string,
    params: RequestParams = {}
  ) {
    return this.request<ActivityTemplateAttributesView>({
      path: `/campaign/${campaignId}/template-attributes`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getCampaignTemplates(
    query: {
      searchTextName: string;
    },
    params: RequestParams = {}
  ) {
    return this.request<CampaignIdentitiesView>({
      path: "/campaign/templates",
      method: "GET",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  getActivityTemplateAttributes(params: RequestParams = {}) {
    return this.request<ActivityTemplateAttributesView>({
      path: "/account-html-template/template-attributes",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getActivityEmailFooter(params: RequestParams = {}) {
    return this.request<ActivityEmailFooterView>({
      path: "/account-html-template/email/footer",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getActivityTemplateEmailById(id: string, params: RequestParams = {}) {
    return this.request<EmailActivityTemplateDetailsView>({
      path: `/html-template/email/${id}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountAccountIntegrationByAccountIdAndIntegrationSystemName(
    accountId: string,
    integrationSystemName: string,
    params: RequestParams = {}
  ) {
    return this.request<AccountIntegrationItemView>({
      path: `/account/${accountId}/account-integration/${integrationSystemName}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountProfiled(params: RequestParams = {}) {
    return this.request<AccountView>({
      path: "/account/profiled",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountPoweredByCheckExists(params: RequestParams = {}) {
    return this.request<boolean>({
      path: "/account/powered-by/check-exists",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountNotificationSetting(params: RequestParams = {}) {
    return this.request<ConfigurableNotificationSettingsView>({
      path: "/account/notification-setting",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getDKIMDomains(params: RequestParams = {}) {
    return this.request<DomainDKIMOptions>({
      path: "/dkim/domain",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  checkDKIMDomains(domain: string, params: RequestParams = {}) {
    return this.request<DomainDKIMStatus>({
      path: `/dkim/check?domain=${domain}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  addDKIMDomain(data: DomainDKIMOption, params: RequestParams = {}) {
    return this.request({
      path: "/dkim",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getAccountIssues(params: RequestParams = {}) {
    return this.request<AccountIssuesView>({
      path: "/account/issues",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountSubscriptionLead(params: RequestParams = {}) {
    return this.request<AccountLeadSubscriptionView>({
      path: "/account-subscription/lead",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountSubscriptionFeatures(params: RequestParams = {}) {
    return this.request<SubscriptionFeaturesView>({
      path: "/account-subscription/features",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountSubscriptionFeaturesCategories(params: RequestParams = {}) {
    return this.request<SubscriptionFeaturesCategoriesView>({
      path: "/account-subscription/features-categories",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountIntegration(params: RequestParams = {}) {
    return this.request<AccountIntegrationsView>({
      path: "/account-integration",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountIntegrationByIntegrationSystemName(
    integrationSystemName: string,
    params: RequestParams = {}
  ) {
    return this.request<AccountIntegrationItemView>({
      path: `/account-integration/${integrationSystemName}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountIntegrationSummary(params: RequestParams = {}) {
    return this.request<AccountIntegrationsSummaryView>({
      path: "/account-integration/summary",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  deletePaymentAccountPaymentMethodById(
    id: string,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/payment-account/payment-method/${id}`,
      method: "DELETE",

      type: ContentType.Json,
      ...params,
    });
  }

  deleteCampaignActivitiesEmailMessageByCampaignIdAndEmailMessageId(
    campaignId: string,
    emailMessageId: string,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/campaign/${campaignId}`,
      method: "DELETE",

      type: ContentType.Json,
      ...params,
    });
  }

  deleteCampaignSegment(data: SegmentIdentityView, params: RequestParams = {}) {
    return this.request<void>({
      path: "/campaign/segment",
      method: "DELETE",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteCampaignSegments(data: IdsView, params: RequestParams = {}) {
    return this.request<void>({
      path: "/campaign/segments",
      method: "DELETE",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteAccountIntegrationById(id: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/account-integration/${id}`,
      method: "DELETE",

      type: ContentType.Json,
      ...params,
    });
  }

  getSegmentBySegmentId(segmentId: string, params: RequestParams = {}) {
    return this.request<SegmentDetailsView>({
      path: `/segment/${segmentId}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getSuppressionBySuppressionId(
    suppressionId: string,
    params: RequestParams = {}
  ) {
    return this.request<SuppressionView>({
      path: `/customer/suppression/suppression/${suppressionId}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putSegmentBySegmentId(
    segmentId: string,
    data: SegmentUpdateView,
    params: RequestParams = {}
  ) {
    return this.request<SegmentIdentityView1>({
      path: `/segment/${segmentId}`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putSuppressionBySuppressionId(
    suppressionId: string,
    data: SegmentUpdateView,
    params: RequestParams = {}
  ) {
    return this.request<SuppressionView>({
      path: `/customer/suppression/suppression/${suppressionId}`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteSegmentBySegmentId(segmentId: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/segment/${segmentId}`,
      method: "DELETE",

      type: ContentType.Json,
      ...params,
    });
  }

  putSegmentCustomersBySegmentId(
    segmentId: string,
    data: CustomersUpdateView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/segment/${segmentId}/customers`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSegmentCustomersBySegmentId(
    segmentId: string,
    data: CustomerBatchView,
    params: RequestParams = {}
  ) {
    return this.request<SegmentIdentityView1>({
      path: `/segment/${segmentId}/customers`,
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putSegmentStatisticByType(
    data: SegmentTypeStatisticRequestView,
    params: RequestParams = {}
  ) {
    return this.request<SegmentTypeStatisticResponseView>({
      path: "/segment/statistic/by-type",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putSegmentFindIds(data: IdsView, params: RequestParams = {}) {
    return this.request<SegmentIdentitiesView1>({
      path: "/segment/find/ids",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putSegmentFindFilter(
    data: SegmentFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
      countAllCustomers?: boolean;
    },
    params: RequestParams = {}
  ) {
    return this.request<SegmentPageSegmentView>({
      path: "/segment/find/filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putSuppressionFindFilter(
    data: SuppressionFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<SuppressionPageSuppressionView>({
      path: "/customer/suppression/find/filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putSegmentCustomSummary(
    data: CustomSegmentSummaryRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CustomSegmentSummaryResponseView>({
      path: "/segment/custom/summary",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getSegmentCheckExists(
    query: {
      name: string;
    },
    params: RequestParams = {}
  ) {
    return this.request<boolean>({
      path: "/segment/check-exists",
      method: "GET",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  useGetAlbertAudience(query: { sort: string }, params: RequestParams = {}) {
    return this.request<AlbertAudienceResponseView>({
      path: "/audience",
      method: "GET",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  putSegmentCheckExists(
    data: SegmentCheckExistsFilterView,
    params: RequestParams = {}
  ) {
    return this.request<boolean>({
      path: "/segment/check-exists",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getSuppressionCheckExistsBySuppressionName(
    suppressionName: string,
    params: RequestParams = {}
  ) {
    return this.request<{ exist: boolean }>({
      path: `/customer/suppression/check-exists?name=${suppressionName}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putCustomerByCustomerId(
    customerId: string,
    data: CustomerView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/customer/${customerId}`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putCustomerStatusByCustomerIdAndCustomerStatus(
    customerId: string,
    customerStatus: string,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/customer/${customerId}/status/${customerStatus}`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putCustomerFilter(
    data: CustomerFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageCustomerInfoView>({
      path: "/customer/filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putSuppressionFilter(
    data: SuppressionDetailsFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageSuppressionInfoView>({
      path: "/suppression/filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putCustomerFilterExport(
    data: CustomerFilterView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/customer/filter/export",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putSuppressionFilterExport(
    data: SuppressionDetailsFilterView,
    params: RequestParams = {}
  ) {
    return this.request<{ url: string }>({
      path: `/customer/suppression/${data?.suppressionId}/download`,
      method: "GET",
      type: ContentType.Json,
      ...params,
    });
  }

  putCustomerCompositeFilter(
    data: CustomerCompositeFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageCustomerInfoView>({
      path: "/customer/composite-filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putCustomerActionPlatformInfoFindFilter(
    data: CustomerActionPlatformFindFilterView,
    params: RequestParams = {}
  ) {
    return this.request<CustomerActionPlatformInfoView[]>({
      path: "/customer-action/platform-info/find/filter",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putCustomerActionGeolocationInfoFindFilter(
    data: CustomerActionGeolocationFindFilterView,
    params: RequestParams = {}
  ) {
    return this.request<CustomerActionGeolocationInfoView[]>({
      path: "/customer-action/geolocation-info/find/filter",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putCustomerActionDomainInfoFindFilter(
    data: CustomerActionDomainFindFilterView,
    params: RequestParams = {}
  ) {
    return this.request<CustomerActionDomainInfoView[]>({
      path: "/customer-action/domain-info/find/filter",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putAccountSegmentCustomerFilterByAccountId(
    accountId: string,
    data: CustomerSegmentFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageCustomerSegmentInfoView>({
      path: `/account/${accountId}/segment-customer/filter`,
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putAccountCustomerFilterByAccountId(
    accountId: string,
    data: CustomerFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageCustomerInfoView>({
      path: `/account/${accountId}/customer/filter`,
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSegmentCustomerBySegmentId(
    segmentId: string,
    data: CustomerView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/segment/${segmentId}/customer`,
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putMergeAudiences(data: AudienceMergeView, params: RequestParams = {}) {
    return this.request<SegmentView>({
      path: "/segment/merge",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteSegmentCustomerBySegmentId(
    segmentId: string,
    data: SegmentUnassignCustomersView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/segment/${segmentId}/customer`,
      method: "DELETE",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSegmentUpload(data: UploadSegmentCreateView, params: RequestParams = {}) {
    return this.request<SegmentIdentityView1>({
      path: "/segment/upload",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSegmentUploadCustomer(
    data: CustomerView,
    query: {
      segmentName: string;
    },
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/segment/upload/customer",
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSegmentStatisticsTotal(params: RequestParams = {}) {
    return this.request<SegmentTotalStatisticsResponseView>({
      path: "/segment/statistics/total",
      method: "POST",

      type: ContentType.Json,
      ...params,
    });
  }

  postSegmentStatisticsTotalCustomers(
    data: SegmentTotalCustomersStatisticsRequestView,
    params: RequestParams = {}
  ) {
    return this.request<SegmentTotalCustomersStatisticsResponseView>({
      path: "/segment/statistics/total-customers",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSegmentIntegrationStatisticsGrowth(
    data: IntegrationSegmentStatisticsGrowthRequestView,
    params: RequestParams = {}
  ) {
    return this.request<IntegrationSegmentStatisticsGrowthResponseView>({
      path: "/segment/integration/statistics/growth",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSegmentImport(data: SegmentImportView, params: RequestParams = {}) {
    return this.request<SegmentIdentityView1>({
      path: "/segment/import",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSegmentCustom(data: CustomSegmentCreateView, params: RequestParams = {}) {
    return this.request<SegmentIdentityView1>({
      path: "/segment/custom",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSegmentClone(data: SegmentCloneRequestView, params: RequestParams = {}) {
    return this.request<SegmentIdentityView1>({
      path: "/segment/clone",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomerStatisticsTotal(
    data: CustomerStatisticsTotalRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CustomerStatisticsTotalResponseView>({
      path: "/customer/statistics/total",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postSuppressionStatisticsTotal(
    data: SuppressionStatisticsTotalRequestView,
    params: RequestParams = {}
  ) {
    return this.request<SuppressionStatisticsTotalResponseView>({
      path: "/customer/suppression/statistics/total",
      method: "GET",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomerStatisticsGrouped(
    data: CustomerStatisticsGroupedRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CustomerStatisticsGroupedResponseView>({
      path: "/customer/statistics/grouped",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomerImport(data: CustomerBatchView, params: RequestParams = {}) {
    return this.request<void>({
      path: "/customer/import",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomerEventStatisticsGrouped(
    data: CustomerEventStatisticsGroupedRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CustomerEventStatisticsGroupedResponseView>({
      path: "/customer-event/statistics/grouped",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomerEventCampaignStatisticsTotalByCampaignId(
    campaignId: string,
    data: CustomerEventStatisticsTotalRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CustomerEventStatisticsTotalResponseView>({
      path: `/customer-event/campaign/${campaignId}/statistics/total`,
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomerEventCampaignStatisticsMostOpenByCampaignId(
    campaignId: string,
    data: CustomerEventMostOpenStatisticsFilterView,
    query: {
      offset?: number;
      limit?: number;
    },
    params: RequestParams = {}
  ) {
    return this.request<CustomerEventMostOpenStatisticsResponseView>({
      path: `/customer-event/campaign/${campaignId}/statistics/most-open`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomerEventCampaignStatisticsMostOpenExportByCampaignId(
    campaignId: string,
    data: CustomerEventMostOpenStatisticsFilterView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/customer-event/campaign/${campaignId}/statistics/most-open/export`,
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomerEventCampaignStatisticsMostClickByCampaignId(
    campaignId: string,
    data: CustomerEventMostClickStatisticsFilterView,
    query: {
      offset?: number;
      limit?: number;
    },
    params: RequestParams = {}
  ) {
    return this.request<CustomerEventMostClickStatisticsResponseView>({
      path: `/customer-event/campaign/${campaignId}/statistics/most-click`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomerEventCampaignStatisticsMostClickExportByCampaignId(
    campaignId: string,
    data: CustomerEventMostClickStatisticsFilterView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/customer-event/campaign/${campaignId}/statistics/most-click/export`,
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomerEventCampaignStatisticsGroupedByCampaignId(
    campaignId: string,
    data: CustomerEventStatisticsGroupedRequestView,
    params: RequestParams = {}
  ) {
    return this.request<CustomerEventStatisticsGroupedResponseView>({
      path: `/customer-event/campaign/${campaignId}/statistics/grouped`,
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postCustomerEventCampaignStatisticsExportByCampaignId(
    campaignId: string,
    params: RequestParams = {},
    body?: any
  ) {
    return this.request<void>({
      path: `/customer-event/campaign/${campaignId}/statistics/export`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    });
  }

  postWorkflowStatisticsExportByWorkflowId(
    workflowId: string,
    params: RequestParams = {},
    body?: any
  ) {
    return this.request<void>({
      path: `/workflow/${workflowId}/statistics/export`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    });
  }

  postAccountSegmentCustomersByAccountIdAndSegmentId(
    accountId: string,
    segmentId: string,
    data: CustomerBatchView,
    params: RequestParams = {}
  ) {
    return this.request<SegmentIdentityView1>({
      path: `/account/${accountId}/segment/${segmentId}/customers`,
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postAccountSegmentImportByAccountId(
    accountId: string,
    data: SegmentImportView,
    params: RequestParams = {}
  ) {
    return this.request<SegmentIdentityView1>({
      path: `/account/${accountId}/segment/import`,
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getSegmentCustomCustomers(
    query: {
      segmentId: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageCustomerInfoView>({
      path: "/segment/custom/customers",
      method: "GET",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  getSegmentConditionsInfo(params: RequestParams = {}) {
    return this.request<SegmentAttributesView>({
      path: "/segment/conditions/info",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getSegmentCheckExistsBySegmentName(
    segmentName: string,
    params: RequestParams = {}
  ) {
    return this.request<boolean>({
      path: `/segment/check-exists/${segmentName}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getSegmentBySegmentType(
    query: {
      searchSegmentType:
        | "UPLOAD"
        | "INTEGRATION"
        | "CUSTOM"
        | "PREDEFINED_RECOMMENDED"
        | "PREDEFINED_LIBRARY";
    },
    params: RequestParams = {}
  ) {
    return this.request<SegmentsView>({
      path: "/segment/by-segment-type",
      method: "GET",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  getSegmentByName(
    query: {
      searchTextName: string;
    },
    params: RequestParams = {}
  ) {
    return this.request<SegmentIdentitiesView1>({
      path: "/segment/by-name",
      method: "GET",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  getCustomerTags(params: RequestParams = {}) {
    return this.request<TagsView>({
      path: "/customer/tags",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountSegmentByAccountId(
    accountId: string,
    query: {
      name: string;
    },
    params: RequestParams = {}
  ) {
    return this.request<SegmentView>({
      path: `/account/${accountId}/segment`,
      method: "GET",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountCustomerByAccountId(
    accountId: string,
    query: {
      email: string;
    },
    params: RequestParams = {}
  ) {
    return this.request<CustomerInfoView>({
      path: `/account/${accountId}/customer`,
      method: "GET",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  getAccountCustomerTotalByAccountId(
    accountId: string,
    query: {
      segmentIds: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<CustomerTotalView>({
      path: `/account/${accountId}/customer/total`,
      method: "GET",
      query: query,

      type: ContentType.Json,
      ...params,
    });
  }

  deleteSegment(data: SegmentMultipleDeleteView, params: RequestParams = {}) {
    return this.request<void>({
      path: "/segment",
      method: "DELETE",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteSuppression(
    data: SuppressionMultipleDeleteView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: "/customer/suppression",
      method: "DELETE",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteSegmentCustomerBySegmentIdAndCustomerId(
    segmentId: string,
    customerId: string,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/segment/${segmentId}/customer/${customerId}`,
      method: "DELETE",

      type: ContentType.Json,
      ...params,
    });
  }

  deleteCustomer(data: IdsView, params: RequestParams = {}) {
    return this.request<void>({
      path: "/customer",
      method: "DELETE",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteSuppressionDetailsItem(
    suppressionId: string,
    data: IdsView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/customer/suppression/${suppressionId}/suppression_value`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteCustomerById(id: string, params: RequestParams = {}) {
    return this.request<void>({
      path: `/customer/${id}`,
      method: "DELETE",

      type: ContentType.Json,
      ...params,
    });
  }

  putContentSubjectFilter(
    data: SubjectLineFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageSubjectLineView>({
      path: "/content/subject/filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putContentSubjectFavoriteByIdAndIsFavorite(
    id: string,
    isFavorite: boolean,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/content/subject/${id}/favorite/${isFavorite}`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putToggleSuppressionType(
    suppressionId: string,
    scope: "GLOBAL" | "LOCAL",
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/customer/suppression/${suppressionId}/change-scope/${scope}`,
      method: "PUT",
      type: ContentType.Json,
      ...params,
    });
  }

  postContentSubjectGenerate(
    data: SubjectGenerateRequestView,
    params: RequestParams = {}
  ) {
    return this.request<SubjectGenerateResponseView>({
      path: "/content/subject/generate",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getTone(params: RequestParams = {}) {
    return this.request<TonesView>({
      path: "/tone",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getMergeTag(params: RequestParams = {}) {
    return this.request<MergeTagsView>({
      path: "/merge-tag",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getContentSubjectFeature(params: RequestParams = {}) {
    return this.request<SubjectLineFeatureInfoView>({
      path: "/content/subject/feature",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putFormById(
    id: string,
    data: FormUpdateRequestView,
    params: RequestParams = {}
  ) {
    return this.request<FormUpdateResponseView>({
      path: `/form/${id}`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putFormStatusByIdAndFormStatus(
    id: string,
    formStatus: string,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/form/${id}/status/${formStatus}`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putFormFilter(
    data: FormFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageFormInfoView>({
      path: "/form/filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putFormConfig(data: FormConfigView, params: RequestParams = {}) {
    return this.request<void>({
      path: "/form/config/",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putFormConfigContentById(
    id: string,
    data: FormContentView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/form/config/${id}/content`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putFormConfigAppearanceById(
    id: string,
    data: FormAppearanceView,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/form/config/${id}/appearance`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putFormConfigAllowedDomainsById(
    id: string,
    data: FormAllowedDomainsView,
    params: RequestParams = {}
  ) {
    return this.request<FormAllowedDomainsView>({
      path: `/form/config/${id}/allowed-domains`,
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putFormCheckAssignedAudiences(
    data: FormCheckAssignedAudiencesRequestView,
    params: RequestParams = {}
  ) {
    return this.request<FormCheckAssignedAudiencesResponseView>({
      path: "/form/check/assigned-audiences",
      method: "PUT",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  putFormTemplateFavoriteByIdAndIsFavorite(
    id: string,
    isFavorite: boolean,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/form-template/${id}/favorite/${isFavorite}`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  putFormTemplateFilter(
    data: FormTemplateFilterView,
    query: {
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {}
  ) {
    return this.request<PageFormTemplateInfoView>({
      path: "/form-template/filter",
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postForm(data: FormView, params: RequestParams = {}) {
    return this.request<FormIdentityView>({
      path: "/form",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  deleteForm(data: FormDeleteRequestView, params: RequestParams = {}) {
    return this.request<void>({
      path: "/form",
      method: "DELETE",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postFormClone(data: FormCloneRequestView, params: RequestParams = {}) {
    return this.request<FormCloneResponseView>({
      path: "/form/clone",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  postFormByTemplate(data: FormWithTemplateView, params: RequestParams = {}) {
    return this.request<FormIdentityView>({
      path: "/form/by-template",
      method: "POST",

      body: data,
      type: ContentType.Json,
      ...params,
    });
  }

  getFormProviderByAccountIdAndFormId(
    accountId: string,
    formId: string,
    params: RequestParams = {}
  ) {
    return this.request<FormPayloadView>({
      path: `/form-provider/${accountId}/${formId}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  postFormProviderByAccountIdAndFormId(
    accountId: string,
    formId: string,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/form-provider/${accountId}/${formId}`,
      method: "POST",

      type: ContentType.Json,
      ...params,
    });
  }

  getFormConfigById(id: string, params: RequestParams = {}) {
    return this.request<FormDetailsView>({
      path: `/form/config/${id}`,
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  getFormTemplateTags(params: RequestParams = {}) {
    return this.request<TemplateTagsView>({
      path: "/form-template/tags",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }

  putAutomationEnabledByAutomationIdAndIsEnabled(
    automationId: string,
    isEnabled: boolean,
    params: RequestParams = {}
  ) {
    return this.request<void>({
      path: `/automation/${automationId}/enabled/${isEnabled}`,
      method: "PUT",

      type: ContentType.Json,
      ...params,
    });
  }

  getAutomation(params: RequestParams = {}) {
    return this.request<AutomationsView>({
      path: "/automation",
      method: "GET",

      type: ContentType.Json,
      ...params,
    });
  }
}
