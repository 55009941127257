import React from "react";
import useTriggerForm from "./TriggerForm.hooks";

type ContextValue = ReturnType<typeof useTriggerForm>;

const TriggerFormContext = React.createContext<ContextValue | null>(null);

export function useTriggerFormView() {
  const contextValue = React.useContext(TriggerFormContext);
  if (!contextValue) {
    throw Error("Should be used inside the TriggerFormProvider");
  }
  return contextValue;
}

export const TriggerFormProvider = TriggerFormContext.Provider;
