import React from "react";
import {
  Button,
  Typography,
  Tooltip,
  TextField,
  Box,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Copy, HelpCircleOutline } from "@tiny/icons";
import { useDialogInvokers } from "@tiny/lib";

const FeedbackDialogContent = ({ onClose }: { onClose: () => void }) => {
  const { enqueueSnackbar } = useSnackbar();

  const feedbackSubject = `Workflow Feature Request`;
  const feedbackEmail = "workflows@tinyemail.com";

  const handleSendEmail = () => {
    const mailtoLink = `mailto:${feedbackEmail}?subject=${encodeURIComponent(
      feedbackSubject
    )}`;
    window.location.href = mailtoLink;
    onClose();
  };

  const handleCopyEmailDetails = () => {
    const textToCopy = `${feedbackEmail}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      enqueueSnackbar("Email address copied to clipboard", {
        variant: "default",
        autoHideDuration: 3000,
      });
    });
  };

  return (
    <>
      <DialogContent sx={{ mt: 2 }}>
        <Typography variant="label2R" color="grey.90" paragraph>
          We value your input! Your feedback helps us improve our workflows and
          make them work better for you.
        </Typography>
        <Typography variant="label2R" color="grey.90" paragraph>
          Please share any suggestions, issues, or improvements you'd like to
          see:
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1, mt: 2 }}>
          <TextField
            fullWidth
            value={feedbackEmail}
            label="Email Address"
            variant="outlined"
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
          />
          <IconButton
            color="primary"
            onClick={handleCopyEmailDetails}
            sx={{ ml: 1 }}
          >
            <Copy />
          </IconButton>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSendEmail}>
          Open Email Client
        </Button>
      </DialogActions>
    </>
  );
};

const FeedbackButton = () => {
  const { requestSmallDialog, exitDialog } = useDialogInvokers();

  const handleOpenFeedbackDialog = () => {
    requestSmallDialog({
      titleProps: { children: "Send Feedback", variant: "label1R" },
      contentProps: {
        dividers: false,
        sx: {
          border: "none",
        },
        children: <FeedbackDialogContent onClose={exitDialog} />,
      },
    });
  };

  return (
    <Tooltip
      title={<Typography sx={{ p: 2 }}>Send Feedback</Typography>}
      sx={{ px: 2 }}
    >
      <Button
        variant="text"
        endIcon={<HelpCircleOutline />}
        onClick={handleOpenFeedbackDialog}
      >
        Submit a Feature Request
      </Button>
    </Tooltip>
  );
};

export default FeedbackButton;
