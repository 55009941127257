import { Box, Typography } from "@mui/material";
import React from "react";
import useTriggerForm from "./TriggerForm.hooks";
import SelectTrigger from "./SelectTrigger";
import SelectRules from "./SelectRules/SelectRules";
import { TriggerFormProvider } from "./TiggerForm.context";
import { useWorkflowsBuilderPageView } from "../../pages/WorkflowsBuilderPage/WorkflowsBuilderPage.context";

const TriggerForm = ({ triggerId: id }: { triggerId: string }) => {
  const triggerFormView = useTriggerForm();
  const { workflowData } = useWorkflowsBuilderPageView();
  const { triggerType } = workflowData;

  const renderTriggerForm = () => {
    if (!triggerType) {
      return <SelectTrigger />;
    }

    switch (triggerType) {
      case "RULE":
        return <SelectRules id={id} />;
      default:
        return null;
    }
  };

  return (
    <TriggerFormProvider value={triggerFormView}>
      {renderTriggerForm()}
    </TriggerFormProvider>
  );
};

export default TriggerForm;
