import { WorkflowFilterView, WorkflowsNav } from "./WorkflowsPage.types";

export interface PageTabItem<T extends string> {
  name: T;
  props?: { [key: string]: any };
}

export const workflowsNav: PageTabItem<WorkflowsNav>[] = [
  { name: "All" },
  { name: "Live" },
  { name: "Paused" },
  { name: "Draft" },
];

export function mapToWorkflowStatuses(
  tab: string
): WorkflowFilterView["workflowStatuses"] {
  switch (tab) {
    case "Live":
      return ["ACTIVE"];
    case "Paused":
      return ["PAUSED"];
    case "Draft":
      return ["DRAFT"];
    case "Launched":
    default:
      return ["DRAFT", "ACTIVE", "PAUSED"];
  }
}
