import React from "react";
import { useLoadingSnackbar } from "./LoadingSnackbar.hooks";

type ContextValue = ReturnType<typeof useLoadingSnackbar>;

const SnackbarContext = React.createContext<ContextValue | null>(null);

export function useLoadingSnackbarView(): ContextValue {
  const contextValue = React.useContext(SnackbarContext);
  if (!contextValue) {
    throw Error("Should be used inside the SnackbarProvider");
  }
  return contextValue;
}

const Provider = SnackbarContext.Provider;

export function LoadingSnackbarProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const loadingSnackbarView = useLoadingSnackbar();
  return <Provider value={loadingSnackbarView}>{children}</Provider>;
}
