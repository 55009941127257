import React from "react";
import { useForm } from "react-hook-form";
import { useCampaignPageView } from "../../CampaignPage.context";
import { CampaignCreateRequestView } from "../../CampaignPage.types";
export function useDetailsPanel() {
  const { saveDetails, details, notifyRequireSave, requireSave } =
    useCampaignPageView();

  const [editing, setEditing] = React.useState(false);

  const form = useForm<CampaignCreateRequestView>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const requiredFields = form.watch(["name", "subject"]);

  const saveDisabled = React.useMemo(() => {
    return (
      editing &&
      requiredFields.filter((v) => !!v).length != requiredFields.length
    );
  }, [editing, requiredFields]);

  const submitForm = async (values: CampaignCreateRequestView) => {
    await saveDetails(values);
    setEditing(false);
  };

  const save = React.useCallback(() => {
    form.handleSubmit(submitForm)();
  }, [form, submitForm]);

  const mustSave = React.useCallback(() => {
    notifyRequireSave({ name: "saveDetails", fn: save });
  }, [notifyRequireSave, save]);

  const edit = React.useCallback(() => {
    form.reset(details);
    setEditing(true);
  }, [details, form]);

  React.useLayoutEffect(() => {
    if (!details?.subject) {
      form.reset(details);
      setEditing(true);
    } else {
      setEditing(false);
    }
  }, [details, form]);

  React.useLayoutEffect(() => {
    if (
      editing &&
      (form.formState.isDirty ||
        !form.formState.isValid ||
        form.formState.touchedFields) &&
      !requireSave["saveDetails"]
    ) {
      mustSave();
    }
  }, [
    editing,
    form.formState.isDirty,
    form.formState.isValid,
    form.formState.touchedFields,
    mustSave,
    requireSave,
    saveDetails,
  ]);

  return { details, editing, edit, save, form, submitForm, saveDisabled };
}
