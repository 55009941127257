import React from "react";
import { StripoContext } from "./Stripo.types";
import { createCtxWithDefaultValue } from "@tiny/utils";

type StripoContextValue = {
  accountId: string;
  emailId: string;
  userFullName: string;
  getAuthToken: () => Promise<string>;
  templateState: {
    html?: string;
    css?: string;
  };
  updateTemplateState: (html: string, css: string) => void;
  version?: string;
  switchVersion?: (newVersion: string) => void;
};

type StripoProviderProps = {
  children: React.ReactNode;
  value: StripoContextValue;
};

const defaultContext: StripoContextValue = {
  accountId: "",
  emailId: "",
  userFullName: "",
  version: "v1",
  switchVersion: () => {},
  getAuthToken: () => Promise.resolve(""),
  templateState: {},
  updateTemplateState: () => {},
};

const [useStripoContext, StripoContextProvider] =
  createCtxWithDefaultValue<StripoContextValue>(defaultContext);

function StripoProvider({ children, value }: StripoProviderProps) {
  const [version, setVersion] = React.useState<string>(() => {
    return localStorage.getItem("version") || "v1";
  });

  const [templateState, setTemplateState] = React.useState<{
    html?: string;
    css?: string;
  }>({});

  const switchVersion = React.useCallback((newVersion: string) => {
    setVersion(newVersion);
    localStorage.setItem("version", newVersion);
  }, []);

  const updateTemplateState = React.useCallback((html: string, css: string) => {
    setTemplateState({ html, css });
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      ...value,
      version,
      switchVersion,
      templateState,
      updateTemplateState,
    };
  }, [value, version, switchVersion, updateTemplateState, templateState]);

  return (
    <StripoContextProvider value={contextValue}>
      {children}
    </StripoContextProvider>
  );
}

export { useStripoContext, StripoProvider };
