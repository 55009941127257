import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { Form, TextControl } from "../../components";

import { UseFormReturn } from "react-hook-form";
import { WorkflowValues } from "./CreateWorkflow.types";

interface CreateWorkflowDialogProps {
  workflow: UseFormReturn<WorkflowValues>;
  executing: boolean;
  open: boolean;
  onClose: () => void;
  handleSubmit: (values: WorkflowValues) => Promise<void>;
}

export function CreateWorkflowDialog({
  workflow,
  executing,
  open,
  onClose,
  handleSubmit,
}: CreateWorkflowDialogProps) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        <Typography component="span" variant="h3" color="grey.90">
          Create new workflow
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Form form={workflow} onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={4}>
            <Grid item mt={4}>
              <TextControl
                name="name"
                label="Workflow Name"
                defaultValue=""
                placeholder="Type your workflow name"
                required
                variant="filled"
                maxLength={100}
              />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={workflow.handleSubmit(handleSubmit)}
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
}
