// SidebarItem.js
import React from "react";
import { Box, Typography } from "@mui/material";
import { Drag } from "@tiny/icons";

const SidebarItem = ({
  item,
  onDragStart,
  enabled,
}: {
  item: {
    id: number;
    label: string;
    type: string;
    data: any;
    icon: null;
    active: boolean;
  };
  onDragStart: (
    event: React.DragEvent,
    nodeType: string,
    label: string
  ) => void;
  enabled: boolean;
}) => {
  return (
    <Box
      onDragStart={(event) => onDragStart(event, item.type, item.label)}
      draggable={item.active && enabled}
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 3,
        mb: 4,
        border: `1px dashed ${theme.palette.grey[60]}`,
        borderRadius: 2,
        cursor: item.active && enabled ? "grab" : "not-allowed",
      })}
    >
      <Typography
        variant="label1R"
        sx={(theme) => ({
          color: theme.palette.grey[80],
        })}
      >
        {item.label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        {!item.active && (
          <Typography
            variant="label2R"
            sx={(theme) => ({
              color: theme.palette.primary[100],
            })}
          >
            coming soon
          </Typography>
        )}
        <Drag
          sx={(theme) => ({
            fontSize: "18px",
            color: theme.palette.grey[60],
          })}
        />
      </Box>
    </Box>
  );
};

export default SidebarItem;
