import React from "react";
import clsx from "clsx";
import useBreakpoints from "../../hooks/useBreakpoints";
import SimpleList from "../SimpleList";
import SimpleListItem from "../SimpleListItem";
import { MoreButtonProps } from "./MoreButton.types";
import {
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { MoreHorizontal } from "@tiny/icons";

function MoreButton<T>(props: MoreButtonProps<T>) {
  const { actions, clickParam, disabled, iconMap, className, variant } = props;

  const theme = useTheme();
  const { mdUp } = useBreakpoints();

  const moreButtonRef = React.useRef<HTMLButtonElement | null>(null);

  const [moreOpen, setMoreOpen] = React.useState(false);

  const showMore = () => {
    setMoreOpen(true);
  };

  const hideMore = () => {
    setMoreOpen(false);
  };

  const buttonClasses = clsx(className, {
    root: {
      background: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 4,
      "& svg": {
        width: 16,
        height: 16,
        color: theme.palette.grey[500],
      },
      "&:hover": {
        border: `1px solid ${theme.palette.grey[900]}`,
      },
      "&:focus": {
        border: `1px solid ${theme.palette.grey[900]}`,
      },
    },
    rootIcon: variant === "icon" && {
      background: "transparent",
      "& svg": {
        width: 24,
        height: 24,
        color: theme.palette.grey[700],
      },
    },
  });

  return (
    <>
      <IconButton
        ref={moreButtonRef}
        className={buttonClasses}
        onClick={showMore}
        size="small"
        aria-label="More"
        sx={{
          width: 24,
          height: 24,
          padding: 0,
          marginLeft: 8,
          "& svg": {
            width: 16,
            height: 16,
            color: (theme) => theme.palette.primary.main,
          },
          background: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[500]}`,
          borderRadius: 1,
          "&:hover": {
            border: `1px solid ${theme.palette.grey[900]}`,
          },
          "&:focus": {
            border: `1px solid ${theme.palette.grey[900]}`,
          },
        }}
      >
        <MoreHorizontal />
      </IconButton>
      {mdUp && moreOpen && (
        <Menu
          anchorEl={moreButtonRef.current}
          keepMounted
          open={moreOpen}
          onClose={hideMore}
          disableScrollLock
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {actions.map((action) => {
            const visible = action.visible ? action.visible(clickParam) : true;
            if (!visible) {
              return null;
            }
            return (
              <MenuItem
                key={action.name}
                onClick={() => {
                  action.onClickAction(clickParam);
                  hideMore();
                }}
                disabled={disabled}
              >
                {action.name}
              </MenuItem>
            );
          })}
        </Menu>
      )}
      {!mdUp && (
        <Dialog open={moreOpen} onClose={hideMore} disableScrollLock>
          <DialogContent sx={{ pt: 1, pb: 3 }}>
            <SimpleList>
              {actions.map((action) => (
                <SimpleListItem
                  key={action.name}
                  onClick={() => {
                    action.onClickAction(clickParam);
                    hideMore();
                  }}
                  size="large"
                  icon={iconMap?.[action.name]}
                >
                  {action.name}
                </SimpleListItem>
              ))}
            </SimpleList>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default React.memo(MoreButton) as typeof MoreButton;
