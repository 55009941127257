import React from "react";
import useBreakpoints from "../../hooks/useBreakpoints";
import { PaginatorProps } from "./Paginator.types";
import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";

function Paginator(props: PaginatorProps) {
  const {
    itemsPerPageOptions,
    page,
    itemsPerPage,
    totalCount,
    itemsPerPageLabel,
    onPageChange,
    onItemsPerPageChange,
    labelDisplayedRows,
    hasMore,
    ...other
  } = props;

  const { mdUp } = useBreakpoints();

  const handlePageChange = (event: any, newPage: number) => {
    onPageChange(newPage - 1);
  };

  const text = React.useMemo(() => {
    if (
      totalCount === undefined ||
      itemsPerPage === undefined ||
      page === undefined
    ) {
      return null;
    }
    const from = page * itemsPerPage + 1;
    let to = (page + 1) * itemsPerPage;
    if (to > totalCount) {
      to = totalCount;
    }
    return `${from}-${to} of ${totalCount}`;
  }, [itemsPerPage, page, totalCount]);

  const labelDisplayedRowsResult = React.useMemo(() => {
    if (!mdUp) {
      return "results";
    }
    if (
      !labelDisplayedRows ||
      totalCount === undefined ||
      !itemsPerPage ||
      page === undefined
    ) {
      return "results";
    }
    const from = page * itemsPerPage + 1;
    let to = (page + 1) * itemsPerPage;
    if (to > totalCount) {
      to = totalCount;
    }
    return labelDisplayedRows({
      from,
      to,
      page,
      count: totalCount,
    });
  }, [itemsPerPage, labelDisplayedRows, mdUp, page, totalCount]);

  if (!totalCount) {
    return null;
  }

  const count = Math.ceil(totalCount / itemsPerPage);

  const options = itemsPerPageOptions.map((nu) => ({
    label: String(nu),
    value: nu,
  }));

  return (
    <Box {...other} sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: 76 }}>
        <FormControl size="small">
          <Select
            fullWidth
            //@ts-ignore
            onChange={(e) => onItemsPerPageChange(e.target.value)}
            value={itemsPerPage}
            aria-label="Sort"
          >
            {options.map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="label2R" color="grey.80">
          {labelDisplayedRowsResult}
        </Typography>
      </Box>
      <Box sx={{ flex: 1, paddingLeft: 1, textAlign: "right" }}>
        <Typography variant="label2R" color="grey.80">
          {text}
        </Typography>
      </Box>
      <Box sx={{ paddingLeft: 12 }}>
        <Pagination
          count={count}
          page={page + 1}
          siblingCount={0}
          boundaryCount={1}
          onChange={handlePageChange}
        />
      </Box>
    </Box>
  );
}

export default Paginator;
