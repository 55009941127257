import React from "react";
import {
  Button,
  IconButton,
  MenuItem,
  Typography,
  Tooltip,
  Box,
  List,
  SxProps,
  Theme,
  Grid,
  Chip,
} from "@mui/material";
import {
  Form,
  MenuButton,
  TextControl,
  TruncateTooltip,
} from "../../../components";
import {
  CircleCheckOutline,
  CloseBig,
  DownloadOutline,
  Edit,
  ErrorOutline,
  WarningOutline,
} from "@tiny/icons";
import useWorkflowsHeader from "./WorkflowsHeader.hooks";
import QuitWorkflows from "./QuitWorkflows";
import WorkflowRestrictions from "../../../customComponents/WorkflowRestrictions";
import FeedbackButton from "../../../customComponents/FeedbackButton";

const WorkflowsHeader = (): JSX.Element => {
  const {
    downloadEnabled,
    isDownloading,
    editTitle,
    form,
    formTitle,
    headerControlAction,
    onClickDownload,
    quitWorkflows,
    saveTitle,
    setFormTitle,
    titleMaxLength,
    toggleEditTitle,
    workflowTitle,
    restrictions,
    viewMode,
  } = useWorkflowsHeader();
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          height: 72,
          backgroundColor: "white",
          border: `1px solid ${theme.palette.grey[60]}`,
        })}
      >
        <Box
          sx={(theme) => ({
            boxSizing: "border-box",
            flex: "1",
            display: "flex",
            alignItems: "center",
            paddingRight: 40,
            [theme.breakpoints.up("lg")]: {
              paddingRight: 80,
            },
            gap: 1.5,
          })}
        >
          <Box
            sx={() => ({
              flex: "0 0 54px",
              boxSizing: "border-box",
              padding: "0 12px 0 20px",
            })}
          >
            <IconButton size="small" onClick={quitWorkflows}>
              <CloseBig />
            </IconButton>
          </Box>
          <Box
            sx={{
              minWidth: "10px",
            }}
          >
            {editTitle ? (
              <Form
                form={form}
                onSubmit={saveTitle}
                style={{
                  width: "100%",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <TextControl
                      name="name"
                      onKeyDown={() => {}}
                      autoFocus
                      label="Edit Title"
                      defaultValue={workflowTitle}
                      maxLength={titleMaxLength}
                      value={formTitle}
                      onChange={(e) => setFormTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!formTitle}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={toggleEditTitle}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            ) : (
              <TruncateTooltip arrow>
                <Typography
                  variant="h4"
                  component="h1"
                  fontWeight="bold"
                  color="grey.100"
                  noWrap
                  m={0}
                >
                  {workflowTitle}
                </Typography>
              </TruncateTooltip>
            )}
          </Box>
          {!editTitle && !viewMode && (
            <IconButton size="small" onClick={toggleEditTitle}>
              <Edit />
            </IconButton>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            boxSizing: "border-box",
            flex: "1",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 5,
            padding: "0 20px",
            [theme.breakpoints.up("lg")]: {
              padding: "0 20px 0 60px",
            },
          })}
        >
          <FeedbackButton />
          {downloadEnabled && (
            <Tooltip
              title={<Typography sx={{ p: 2 }}>Download Reports</Typography>}
              sx={{ px: 2 }}
            >
              <Button
                variant="text"
                endIcon={<DownloadOutline />}
                onClick={onClickDownload}
                disabled={isDownloading}
              >
                Download Stats
              </Button>
            </Tooltip>
          )}
          <WorkflowRestrictions restrictions={restrictions} />
          {headerControlAction.children ? (
            <MenuButton
              disabled={headerControlAction.disabled}
              color="primary"
              text={headerControlAction.text}
              variant="contained"
            >
              {headerControlAction.children
                ?.filter((a) => !a.hidden)
                ?.map((action) => (
                  <MenuItem
                    key={action.text}
                    onClick={action.onClick}
                    disabled={action.disabled}
                  >
                    {action.text}
                  </MenuItem>
                ))}
            </MenuButton>
          ) : (
            <Button
              variant="contained"
              disabled={headerControlAction.disabled}
              onClick={headerControlAction.onClick}
            >
              {headerControlAction.text}
            </Button>
          )}
        </Box>
      </Box>
      <QuitWorkflows />
    </Box>
  );
};

export default WorkflowsHeader;
