import React from "react";

import { WorkflowListProps } from "./WorkflowList.types";
import { useWorkflowList } from "./WorkflowList.hooks";
import { itemsPerPageOptions } from "./WorkflowList.helpers";
import WorkflowListHeader from "./WorkflowListHeader/WorkflowListHeader";
import WorkflowRow from "./WorkflowRow/WorkflowRow";
import useBreakpoints from "../../hooks/useBreakpoints";
import ComponentLoader from "../ComponentLoader/ComponentLoader";
import { Search } from "@tiny/icons";
import { Box, Grid } from "@mui/material";
import EmptyState from "../../components/EmptyState";
import Paginator from "../../components/Paginator";

function WorkflowList(props: WorkflowListProps) {
  const { showChip, workflowStatuses, ...other } = props;

  const { mdUp } = useBreakpoints();

  const {
    allWorkflowsSelected,
    allSelectedDisabled,
    bulkDeleteEnabled,
    cancelScheduledWorkflow,
    cloneWorkflow,
    currentPage,
    data,
    deleteWorkflow,
    deleteSelectedWorkflows,
    deselectAllWorkflows,
    handlePageChange,
    handleSizeChange,
    isFetching,
    isSearching,
    isSelected,
    mutationLoading,
    pageSize,
    searchWorkflows,
    selectWorkflow,
    selectedWorkflowsIds,
    sort,
    sortWorkflows,
    toggleWorkflows,
    activateWorkflow,
    downloadReport,
  } = useWorkflowList(props);

  const emptyStateTitle = "No workflows found";

  const emptyStateMessage = isSearching ? (
    <span>Try some other search keywords.</span>
  ) : mdUp ? (
    <span>Let&apos;s get your message out to your audience.</span>
  ) : null;

  return (
    <>
      <Box {...other}>
        <WorkflowListHeader
          allSelected={allWorkflowsSelected}
          allSelectedDisabled={allSelectedDisabled}
          bulkDeleteEnabled={bulkDeleteEnabled}
          onDeleteClick={deleteSelectedWorkflows}
          onDeselectAll={deselectAllWorkflows}
          onSearchChange={searchWorkflows}
          onSortChange={sortWorkflows}
          onToggleAll={toggleWorkflows}
          selected={selectedWorkflowsIds}
          sort={sort}
        />
        <>
          {isFetching ? (
            <ComponentLoader height="200px" />
          ) : data?.content?.length ? (
            <>
              <Box
                component="ul"
                sx={{
                  p: 0,
                }}
              >
                {data?.content?.map((row) => (
                  <WorkflowRow
                    isClickable={true}
                    showDetails={true}
                    isSelected={isSelected}
                    item={row}
                    key={row.id}
                    onPause={cancelScheduledWorkflow}
                    onDelete={deleteWorkflow}
                    onSelectRow={selectWorkflow}
                    onClone={cloneWorkflow}
                    showChip={showChip}
                    mutationLoading={mutationLoading}
                    onStart={activateWorkflow}
                    onExport={downloadReport}
                  />
                ))}
              </Box>
              <Grid item xs={12} mt={mdUp ? 4 : 3}>
                <Paginator
                  itemsPerPage={pageSize}
                  itemsPerPageLabel="Results"
                  itemsPerPageOptions={itemsPerPageOptions}
                  onItemsPerPageChange={handleSizeChange}
                  onPageChange={handlePageChange}
                  page={currentPage}
                  totalCount={data?.totalElements}
                />
              </Grid>
            </>
          ) : (
            <Box pt={10} pb={10}>
              <EmptyState
                title={emptyStateTitle}
                message={emptyStateMessage}
                icon={<Search />}
                iconColor="primary"
                circleColor="white"
                align="center"
              />
            </Box>
          )}
        </>
      </Box>
    </>
  );
}

export default WorkflowList;
