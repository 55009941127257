import React from "react";
import { WorkflowInfoView } from "../../../app/GeneratedApi";
import { getChipColor } from "./WorkflowStatus.helpers";
import { Chip, ChipProps, Theme } from "@mui/material";

interface WorkflowStatusProps extends Omit<ChipProps, "label" | "color"> {
  status: WorkflowInfoView["status"];
}

function WorkflowStatus(
  props: WorkflowStatusProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { status, ...other } = props;
  const chipColor = getChipColor(status);

  const getBgColor = (theme: Theme) => {
    switch (chipColor) {
      case "primary":
        return theme.palette.primary.main;
      case "yellow":
        return theme.palette.yellow[75];
      case "red":
        return theme.palette.red[75];
      default:
        return theme.palette.grey[500];
    }
  };

  const getTextColor = (theme: Theme) => {
    if (chipColor === "yellow" || chipColor === "red") {
      return theme.palette.common.black;
    }
    return theme.palette.common.white;
  };
  return (
    <Chip
      ref={ref}
      label={status}
      {...other}
      size="small"
      sx={(theme) => ({
        fontSize: 8,
        p: 0,
        height: 16,
        background: getBgColor(theme),
        color: getTextColor(theme),
      })}
    />
  );
}

export default React.forwardRef(WorkflowStatus);
