import React from "react";
import { TemplateFilter } from "../../TemplateList.types";
import { useCustomCodeTabView } from "../CustomCodeTab.context";
import { useConfigView } from "../../../../Config.context";
import { useGetTemplateTagQuery } from "../../../../app/useGeneratedApi";

export function useCustomCodeHeader() {
  const {
    allSelected,
    activeFilter,
    changeActiveFilter,
    deleteSelected,
    deselectAll,
    deselectTag,
    search,
    selectedTags,
    selectedIds,
    updateSelectedTags,
    toggleAll,
    allSelectedDisabled,
  } = useCustomCodeTabView();

  const [moreOpen, setMoreOpen] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);

  const [sectionsFilterOpen, setSectionsFilterOpen] = React.useState(false);

  const [tempActiveFilter, setTempActiveFilter] =
    React.useState<TemplateFilter>(activeFilter);

  const { data: { tags = [] } = {} } = useGetTemplateTagQuery({
    cacheTime: Infinity,
  });

  const openMore = React.useCallback(() => {
    setMoreOpen(true);
  }, []);

  const closeMore = React.useCallback(() => {
    setMoreOpen(false);
  }, []);

  const openFilters = React.useCallback(() => {
    setFiltersOpen(true);
    setMoreOpen(false);
  }, []);

  const closeFilters = React.useCallback(() => {
    setFiltersOpen(false);
  }, []);

  const openSectionsFilter = React.useCallback(() => {
    setMoreOpen(false);
    setSectionsFilterOpen(true);
    setTempActiveFilter(activeFilter);
  }, [activeFilter]);

  const closeSectionsFilter = React.useCallback(() => {
    setSectionsFilterOpen(false);
  }, []);

  const applySectionFilter = React.useCallback(() => {
    changeActiveFilter(tempActiveFilter);
    closeSectionsFilter();
  }, [changeActiveFilter, closeSectionsFilter, tempActiveFilter]);

  return {
    activeFilter,
    allSelected,
    allSelectedDisabled,
    changeActiveFilter,
    closeFilters,
    closeMore,
    closeSectionsFilter,
    deleteSelected,
    deselectAll,
    deselectTag,
    filtersOpen,
    moreOpen,
    openFilters,
    openMore,
    openSectionsFilter,
    search,
    sectionsFilterOpen,
    selectedTags,
    selectedIds,
    tags,
    toggleAll,
    updateSelectedTags,
    tempActiveFilter,
    setTempActiveFilter,
    applySectionFilter,
  };
}
