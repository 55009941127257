import React from "react";
import { Box, Chip, Divider, MenuItem, Typography } from "@mui/material";
import {
  AutomationsOutline,
  CircleCheckOutline,
  MoreHorizontal,
  OffOutlineClose,
} from "@tiny/icons";
import { Handle, NodeProps, Position } from "reactflow";
import { NodeData } from "../types";
import { MenuIconButton } from "../../../../components";
import useDeleteNode from "../Hooks/useDeleteNode";
import ConditionalSplitForm from "../../../../customComponents/ConditionalSplitForm";
import { useDrawerInvokers } from "@tiny/lib";
import { transformNodeToFormValues } from "../../../../customComponents/ConditionalSplitForm/ConditionalSplitForm.hooks";
import { useWorkflowsBuilderPageView } from "../../WorkflowsBuilderPage.context";

const LogicNode = ({ id, data }: NodeProps<NodeData>) => {
  const { deleteNode } = useDeleteNode();
  const { configured } = data.config;
  const { showDrawer } = useDrawerInvokers();
  const { viewMode } = useWorkflowsBuilderPageView();

  const handleClick = (e: React.SyntheticEvent) => {
    showDrawer({
      children: <ConditionalSplitForm id={id} />,
    });
  };

  transformNodeToFormValues(data);

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ backgroundColor: "transparent", border: "none" }}
      />
      <Box>
        <Box
          sx={(theme) => ({
            background: theme.palette.common.white,
            border: `1px solid ${theme.palette.grey[80]}`,
            padding: 3,
            paddingBottom: 4,
            borderRadius: 3,
            display: "flex",
            flexDirection: "column",
            width: "400px",
            cursor: "pointer",
          })}
          onClick={handleClick}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mr: -1 }}
          >
            <Box display="flex" alignItems="center">
              <AutomationsOutline
                sx={(theme) => ({
                  fontSize: 22,
                  mr: 1,
                  color: theme.palette.fuchsia[150],
                })}
              />
              <Typography
                variant="label1"
                color="grey.80"
                sx={(theme) => ({
                  textTransform: "uppercase",
                  color: theme.palette.fuchsia[150],
                })}
              >
                LOGIC {"›"} {data.label}
              </Typography>
            </Box>
            <MenuIconButton
              icon={<MoreHorizontal />}
              onClick={(e) => e.stopPropagation()}
            >
              <MenuItem onClick={handleClick}>Edit</MenuItem>
              {!viewMode && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteNode(id);
                  }}
                >
                  Delete
                </MenuItem>
              )}
            </MenuIconButton>
          </Box>
          <Box>
            <Typography
              variant="p2"
              color="grey.100"
              fontWeight="600"
              textTransform="uppercase"
            >
              {`${transformNodeToFormValues(data)?.ruleType || ""} › ${
                transformNodeToFormValues(data)?.event || ""
              }`}
            </Typography>
          </Box>
          <Box mt={2}>
            {configured ? (
              <Chip
                sx={(theme) => ({
                  height: theme.spacing(5),
                  backgroundColor: theme.palette.primary[100],
                  color: theme.palette.common.white,
                  mr: 1,
                  fontSize: theme.spacing(3),
                  lineHeight: theme.spacing(3),
                  "& .MuiChip-label": { fontWeight: 700 },
                })}
                label="Configured"
              />
            ) : (
              <Chip
                sx={(theme) => ({
                  height: theme.spacing(5),
                  backgroundColor: theme.palette.yellow[150],
                  color: theme.palette.common.white,
                  mr: 1,
                  fontSize: theme.spacing(3),
                  lineHeight: theme.spacing(3),
                  "& .MuiChip-label": { fontWeight: 700 },
                })}
                label="Not-Configured"
              />
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Divider
              orientation="vertical"
              sx={{
                height: "20px",
              }}
              color="grey.60"
            />
            <Box
              display="flex"
              alignItems="center"
              sx={(theme) => ({
                background: theme.palette.common.white,
                border: `1px solid ${theme.palette.grey[80]}`,
                padding: 3,
                borderRadius: 3,
                width: "90px",
              })}
            >
              <Typography
                variant="label1"
                color="grey.80"
                sx={{ textTransform: "capitalize", width: "100%" }}
                textAlign={"center"}
              >
                Yes
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
            <Divider
              orientation="vertical"
              sx={{
                height: "20px",
              }}
              color="grey.60"
            />
            <Box
              display="flex"
              alignItems="center"
              sx={(theme) => ({
                background: theme.palette.common.white,
                border: `1px solid ${theme.palette.grey[80]}`,
                padding: 3,
                borderRadius: 3,
                width: "90px",
              })}
            >
              <Typography
                variant="label1"
                color="grey.80"
                sx={{ textTransform: "capitalize", width: "100%" }}
                textAlign={"center"}
              >
                No
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Handle
        type="source"
        position={Position.Bottom}
        id="left"
        style={{
          left: "10%",
          background: "#555",
          backgroundColor: "transparent",
          border: "none",
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="right"
        style={{
          left: "90%",
          background: "#555",
          backgroundColor: "transparent",
          border: "none",
        }}
      />
    </>
  );
};
export default LogicNode;
