import React from "react";
import TinyWorkflows from "@tiny/workflows";
import { WorkflowPageProps } from "./Workflow.types";
import useWorkflow from "./Workflow.hooks";

const Workflow = React.memo((props: WorkflowPageProps) => {
  const { config } = useWorkflow(props);
  return <TinyWorkflows config={config} />;
});

Workflow.displayName = "Workflow";

export default Workflow;
