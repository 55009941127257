import { Box, Chip, List, ListItem, Typography } from "@mui/material";
import { CircleCheckOutline, ErrorOutline } from "@tiny/icons";
import React from "react";

const WorkflowRestrictions = ({
  restrictions,
}: {
  restrictions: {
    label: string;
    isValid: boolean;
  }[];
}) => {
  const invalidRestrictionsCount = restrictions.reduce(
    (prev, curr) => prev + (curr.isValid ? 0 : 1),
    0
  );
  const isValid = invalidRestrictionsCount === 0;
  const [restrictionsVisible, setRestrictionsVisible] = React.useState(false);
  return (
    <Box position="relative">
      <Chip
        onClick={() => setRestrictionsVisible((prev) => !prev)}
        sx={(theme) => ({
          backgroundColor: isValid
            ? theme.palette.green[50]
            : theme.palette.red[50],
          color: theme.palette.common.black,
          lineHeight: theme.spacing(3),
          border: `1px solid ${
            isValid ? theme.palette.green[100] : theme.palette.red[75]
          }`,
          ":hover": {
            backgroundColor: isValid
              ? theme.palette.green[50]
              : theme.palette.red[50],
          },
          cursor: "pointer",
        })}
        deleteIcon={
          isValid ? (
            <CircleCheckOutline
              sx={(theme) => ({
                "&.MuiChip-deleteIcon ": {
                  color: theme.palette.green[125],
                  ":hover": {
                    color: theme.palette.green[125],
                  },
                },
              })}
            />
          ) : (
            <ErrorOutline
              sx={(theme) => ({
                "&.MuiChip-deleteIcon ": {
                  color: theme.palette.red[125],
                  ":hover": {
                    color: theme.palette.red[125],
                  },
                },
              })}
            />
          )
        }
        onDelete={() => setRestrictionsVisible((prev) => !prev)}
        label={
          isValid ? (
            <Typography
              sx={(theme) => ({
                fontWeight: 500,
                fontSize: theme.spacing(3),
                lineHeight: theme.spacing(4),
              })}
            >
              Valid workflow
            </Typography>
          ) : (
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                sx={(theme) => ({
                  fontWeight: 500,
                  fontSize: theme.spacing(3),
                  lineHeight: theme.spacing(4),
                  pr: 2,
                  borderRight: `0.5px solid ${theme.palette.red[75]}`,
                })}
              >
                {invalidRestrictionsCount}
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontWeight: 500,
                  fontSize: theme.spacing(3),
                  lineHeight: theme.spacing(4),
                })}
              >
                Invalid workflow
              </Typography>
            </Box>
          )
        }
      />
      {restrictionsVisible && (
        <Box
          sx={(theme) => ({
            position: "absolute",
            top: "calc(100% + 15px)",
            right: -10,
            display: "flex",
            gap: 2,
            backgroundColor: isValid
              ? theme.palette.green[50]
              : theme.palette.red[50],
            zIndex: 1,
            p: 4,
            borderRadius: 2,
            border: `1px solid ${
              isValid ? theme.palette.green[100] : theme.palette.red[75]
            }`,
            minWidth: isValid ? "300px" : "325px",
            "::before": {
              content: '""',
              position: "absolute",
              top: "-18px",
              right: "17px",
              borderWidth: 9,
              borderStyle: "solid",
              borderColor: `transparent transparent ${
                isValid ? theme.palette.green[100] : theme.palette.red[75]
              } transparent`,
            },
            "::after": {
              content: '""',
              position: "absolute",
              top: "-16.5px",
              right: "17px",
              borderWidth: 9,
              borderStyle: "solid",
              borderColor: `transparent transparent ${
                isValid ? theme.palette.green[50] : theme.palette.red[50]
              } transparent`,
            },
          })}
          onClick={() => setRestrictionsVisible(false)}
        >
          {isValid ? (
            <CircleCheckOutline
              sx={(theme) => ({
                color: theme.palette.green[125],
              })}
            />
          ) : (
            <ErrorOutline
              sx={(theme) => ({
                color: theme.palette.red[125],
              })}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: isValid ? 1 : 2.5,
            }}
          >
            <Typography
              sx={(theme) => ({
                textTransform: "uppercase",
                fontSize: 12,
                fontWeight: "bold",
                color: isValid
                  ? theme.palette.green[125]
                  : theme.palette.red[125],
                mt: 1,
              })}
            >
              {isValid ? "Valid" : "Invalid"} Workflow
            </Typography>
            {isValid ? (
              <Typography variant="p3">
                This is a valid workflow for activation!
              </Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 12,
                    ml: -6,
                  }}
                >
                  The workflow is invalid due to below reasons
                </Typography>
                <List sx={{ listStyleType: "disc", padding: 0 }}>
                  {restrictions
                    .filter((restriction) => !restriction.isValid)
                    .map((restriction) => (
                      <ListItem
                        key={restriction.label}
                        sx={{
                          display: "list-item",
                          fontSize: 12,
                          fontWeight: 400,
                          padding: "3px 0",
                        }}
                      >
                        {restriction.label}
                      </ListItem>
                    ))}
                </List>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WorkflowRestrictions;
