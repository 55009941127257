import react from "react";
import { Box, Typography } from "@mui/material";
import { PlusCircleOutline } from "@tiny/icons";
export function CreateCard({
  openWorkflowDialog,
}: {
  openWorkflowDialog: () => void;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
        borderRadius: 2,
        bgcolor: "white",
        minHeight: "200px",
        cursor: "pointer",
      }}
      onClick={() => openWorkflowDialog()}
    >
      <PlusCircleOutline />
      <Typography variant="h4" color="grey.900" fontWeight={400} mt={2}>
        Create from scratch
      </Typography>
    </Box>
  );
}
