import {
  format,
  addMinutes,
  set,
  getHours,
  getMinutes,
  getSeconds,
} from "date-fns";

export function formatFromUtc(date: Date, pattern: string) {
  return format(addMinutes(date, date.getTimezoneOffset()), pattern);
}

export function combineDateTimeToUtcMilliseconds(
  dateObj: Date,
  timeObj: Date
): number {
  const combinedDateTime = set(dateObj, {
    hours: getHours(timeObj),
    minutes: getMinutes(timeObj),
    seconds: getSeconds(timeObj),
  });
  const utcMilliseconds = combinedDateTime.getTime();
  return utcMilliseconds;
}

export function createUtcTodayTimeZero(): Date {
  const today = new Date();
  const isoString = today.toISOString();
  return new Date(`${isoString.split("T")[0]}T00:00:00.000Z`);
}

export function createUtcTodayLastTime(): Date {
  const today = new Date();
  const isoString = today.toISOString();
  return new Date(`${isoString.split("T")[0]}T23:59:59.000Z`);
}

export const hourOptions: {
  value: number;
  label: string;
}[] = [
  { label: "01", value: 1 },
  { label: "02", value: 2 },
  { label: "03", value: 3 },
  { label: "04", value: 4 },
  { label: "05", value: 5 },
  { label: "06", value: 6 },
  { label: "07", value: 7 },
  { label: "08", value: 8 },
  { label: "09", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
];

export const minuteOptions: {
  value: number;
  label: string;
}[] = Array(60)
  .fill(null)
  .map((_, index: number) => ({
    label: String(`0${index}`).slice(-2),
    value: index,
  }));

export const ampmOptions: {
  value: string;
  label: string;
}[] = [
  { label: "AM", value: "am" },
  { label: "PM", value: "pm" },
];

export function getTimezone(timezoneOffset: number): string {
  const signal = timezoneOffset > 0 ? "-" : "+";
  const hours = `0${Math.floor(Math.abs(timezoneOffset) / 60)}`.slice(-2);
  const minutes = `0${Math.abs(timezoneOffset) % 60}`.slice(-2);
  return `${signal}${hours}:${minutes}`;
}

// return format: Mon, Dec 31, 2018 4:38 pm
export function formatDate(
  unixTimestamp: number,
  formatPattern = "E, MMM d, yyyy h:mm aaa"
): string {
  return format(new Date(unixTimestamp), formatPattern);
}

export function getUtcTimestamp(offset = 0) {
  const now = new Date();
  return (
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    ) + offset
  );
}

export function getUtcFirstTime(date: Date): Date {
  const isoString = date.toISOString();
  return new Date(`${isoString.split("T")[0]}T00:00:00.000Z`);
}

export function getUtcLastTime(date: Date): Date {
  const isoString = date.toISOString();
  return new Date(`${isoString.split("T")[0]}T23:59:59.000Z`);
}

export function getUTCTimeString(date: Date): string {
  const hours = ("0" + date.getUTCHours()).slice(-2);
  const minutes = ("0" + date.getUTCMinutes()).slice(-2);
  const seconds = ("0" + date.getUTCSeconds()).slice(-2);

  const utcTimeString = `${hours}:${minutes}:${seconds}`;
  return utcTimeString;
}

export function getUTCTimeStringToDate(utcTimeString: string): Date {
  const [hours, minutes, seconds] = utcTimeString.split(":").map(Number);

  const date = new Date();
  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);
  date.setUTCSeconds(seconds);

  return date;
}
