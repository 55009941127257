import { QueryClient } from "react-query";
import { Template } from "../../TemplateList.types";

export function changeTemplateFavoriteCache(
  queryClient: QueryClient,
  templateId: string,
  isFavorite: boolean
): void {
  const savedQueries = queryClient.getQueriesData<{
    content: Template[];
  }>(["PUT", "/activity/find/filter"]);

  const otherQueries = queryClient.getQueriesData<{
    content: Template[];
  }>(["PUT", "/activity-template/email/find/filter"]);

  const queries = [...savedQueries, ...otherQueries];

  queries.forEach(([queryKey, result]) => {
    if (result) {
      const existTemplate = result.content.some(
        (template: any) => template.id === templateId
      );
      if (existTemplate) {
        const content = result.content.map((template: any) => {
          if (template.id === templateId) {
            return {
              ...template,
              isFavorite,
            };
          } else {
            return template;
          }
        });
        const nextData = {
          ...result,
          content,
        };
        queryClient.setQueryData(queryKey, nextData);
      }
    }
  });

  const infiniteQueries = queryClient.getQueriesData<{
    pages: { content: Template[] }[];
  }>(["templates"]);
  infiniteQueries.forEach(([queryKey, result]) => {
    if (result) {
      const existTemplate = result?.pages?.some((page) => {
        return page.content.some((template) => {
          return template.id === templateId;
        });
      });
      if (existTemplate) {
        const pages = result.pages.map((page) => {
          const content = page.content.map((template) => {
            if (template.id === templateId) {
              return {
                ...template,
                isFavorite,
              };
            } else {
              return template;
            }
          });
          return {
            ...page,
            content,
          };
        });
        const nextData = {
          ...result,
          pages,
        };
        queryClient.setQueryData(queryKey, nextData);
      }
    }
  });
}
