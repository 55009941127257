import React from "react";
import {
  usePostWorkflowCloneMutation,
  usePutWorkflowActivateByIdMutation,
  usePutWorkflowPauseByIdMutation,
  usePutWorkflowsFindFilterQuery,
  useDeleteWorkflowByIdMutation,
} from "../../app/useGeneratedApi";
import useSearchable from "../../hooks/useSearchable";
import useSelect from "../../hooks/useSelect";
import { pageableDefault } from "./WorkflowList.helpers";
import { WorkflowListProps } from "./WorkflowList.types";
import { useDialogInvokers } from "@tiny/lib";
import { convertDateRangeToFilter } from "@tiny/utils/dateUtils";
import { api } from "../../app/apiInstance";
import { enqueueSnackbar } from "notistack";
import { DEFAULT_ERROR_MESSAGE } from "../../utils/errorUtils";
import { downloadFileData, ResponseHeaders } from "../../utils/fileUtils";
import { WorkflowInfoView } from "../../app/GeneratedApi";

export function useWorkflowList(props: WorkflowListProps) {
  const {
    workflowStatuses,
    defaultPageable = pageableDefault,
    pageName = "",
  } = props;

  const [search, setSearch] = React.useState("");

  const { exitDialog } = useDialogInvokers();

  const { pageable, handlePageChange, handleSizeChange, handleSortChange } =
    useSearchable(defaultPageable, pageName);

  const {
    selected,
    handleToggleSelect,
    isSelected,
    handleDeselectAll,
    handleAddSelected,
    handleRemoveSelected,
  } = useSelect();

  const {
    data,
    refetch: reloadWorkflows,
    isLoading,
    isFetching,
  } = usePutWorkflowsFindFilterQuery(
    {
      workflowStatuses: workflowStatuses,
      searchTextName: search,
    },
    pageable,
    {
      keepPreviousData: true,
    }
  );

  const postDeleteOps = React.useCallback(async () => {
    await reloadWorkflows();
  }, [reloadWorkflows]);

  const refetchWorkflows = React.useCallback(() => {
    reloadWorkflows();
  }, [reloadWorkflows]);

  const { mutateAsync: cloneWorkflowMutate, isLoading: cloneWorkflowLoading } =
    usePostWorkflowCloneMutation();

  const {
    mutateAsync: deleteWorkflowsMutate,
    isLoading: deleteWorkflowsLoading,
  } = useDeleteWorkflowMutation();

  const {
    mutateAsync: pauseWorkflowsMutate,
    isLoading: pauseWorkflowsLoading,
  } = usePutWorkflowPauseByIdMutation();

  const {
    mutateAsync: activateWorkflowsMutate,
    isLoading: activateWorkflowsLoading,
  } = usePutWorkflowActivateByIdMutation();

  const {
    mutateAsync: deleteWorkflowByIdMutation,
    isLoading: deleteWorkflowByIdLoading,
  } = useDeleteWorkflowByIdMutation();

  const selectWorkflow = React.useCallback(
    (id?: string) => {
      handleToggleSelect(id);
    },
    [handleToggleSelect]
  );

  const deselectAllWorkflows = React.useCallback(() => {
    handleDeselectAll();
  }, [handleDeselectAll]);

  const deleteSelectedWorkflows = React.useCallback(async () => {
    await deleteWorkflowsMutate({
      data: {
        ids: selected,
      },
    });
    await reloadWorkflows();
    handleDeselectAll();
  }, [deleteWorkflowsMutate, handleDeselectAll, reloadWorkflows, selected]);

  const toggleWorkflows = React.useCallback(
    (checked: boolean) => {
      const ids = data?.content?.map((item) => item.id!) || [];
      if (checked) {
        handleAddSelected(ids);
      } else {
        handleRemoveSelected(ids);
      }
    },
    [data?.content, handleAddSelected, handleRemoveSelected]
  );

  const sortWorkflows = React.useCallback(
    (sort: string[]) => {
      handleSortChange(sort);
    },
    [handleSortChange]
  );

  const deleteWorkflow = React.useCallback(
    async (id: string) => {
      await deleteWorkflowByIdMutation({
        id,
      });
      await reloadWorkflows();
    },
    [deleteWorkflowsMutate, reloadWorkflows]
  );

  const cloneWorkflow = React.useCallback(
    async (id: string) => {
      await cloneWorkflowMutate({ data: { cloneWorkflowId: id } });
      handlePageChange(0);
      handleSortChange(["updatedAt,DESC"]);
      await reloadWorkflows();
    },
    [cloneWorkflowMutate, handlePageChange, handleSortChange, reloadWorkflows]
  );

  const cancelScheduledWorkflow = React.useCallback(
    async (id: string) => {
      await pauseWorkflowsMutate({ id });
      await reloadWorkflows();
      exitDialog();
    },
    [reloadWorkflows, pauseWorkflowsMutate]
  );

  const activateWorkflow = React.useCallback(
    async (id: string) => {
      await activateWorkflowsMutate({ id });
      await reloadWorkflows();
    },
    [reloadWorkflows, activateWorkflowsMutate]
  );

  const downloadReport = React.useCallback(
    async (workflow: WorkflowInfoView) => {
      enqueueSnackbar("Downloading!", {
        variant: "info",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        autoHideDuration: 2000,
      });
      try {
        const { from, to } = convertDateRangeToFilter("LAST_30_DAYS");
        const { data, headers } =
          await api.postWorkflowStatisticsExportByWorkflowId(
            workflow?.id,
            {
              format: "blob",
              headers: {
                "Access-Control-Expose-Headers": "Content-Disposition",
              },
            },
            {
              from: new Date(workflow?.createdAt || "").getTime(),
              to: new Date().getTime(),
            }
          );
        downloadFileData(data, headers as ResponseHeaders);
      } catch (error) {
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 10000,
        });
      }
    },
    []
  );

  const searchWorkflows = React.useCallback(
    async (value: string) => {
      handlePageChange(0);
      setSearch(value);
    },
    [handlePageChange]
  );

  const allWorkflowsSelected = React.useMemo(() => {
    const dataLength = data?.content?.length || 0;
    const allSelected =
      dataLength &&
      data?.content?.every((item) => item.id && selected.includes(item.id));
    return allSelected || false;
  }, [data, selected]);

  const bulkDeleteEnabled = React.useMemo(() => {
    return (
      workflowStatuses?.every((status) =>
        ["DRAFT", "PAUSED"].includes(status)
      ) || false
    );
  }, [workflowStatuses]);

  React.useEffect(() => {
    if (workflowStatuses) {
      handlePageChange(0);
      handleDeselectAll();
    }
  }, [workflowStatuses, handleDeselectAll, handlePageChange]);

  const sort = pageable.sort;
  const selectedWorkflowsIds = selected;
  const currentPage = pageable.page;
  const pageSize = pageable.size;
  const isSearching = !!search;
  const allSelectedDisabled = data?.content?.length === 0;

  const mutationLoading =
    cloneWorkflowLoading ||
    deleteWorkflowsLoading ||
    activateWorkflowsLoading ||
    pauseWorkflowsLoading ||
    deleteWorkflowByIdLoading;

  return {
    allWorkflowsSelected,
    allSelectedDisabled,
    bulkDeleteEnabled,
    cancelScheduledWorkflow,
    cloneWorkflow,
    currentPage,
    data,
    deleteWorkflow,
    deleteSelectedWorkflows,
    deselectAllWorkflows,
    handlePageChange,
    handleSizeChange,
    isFetching,
    isLoading,
    isSearching,
    isSelected,
    mutationLoading,
    pageSize,
    reloadWorkflows,
    selectWorkflow,
    selectedWorkflowsIds,
    searchWorkflows,
    sort,
    sortWorkflows,
    toggleWorkflows,
    activateWorkflow,
    downloadReport,
  };
}

//delete these after api integration and import these useGeneratedApis
function useDeleteWorkflowMutation() {
  return {
    mutateAsync: (data: any) => {
      console.log("test");
    },
    isLoading: false,
  };
}
