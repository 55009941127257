import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <g>
    <g xmlns="http://www.w3.org/2000/svg">
      <title>Layer 1</title>
      <path
        transform="rotate(9.10965 11.9998 11.999)"
        stroke="null"
        d="m5.71921,13.90408l5.61036,0l-2.9591,8.04317c-0.38728,1.02275 0.67521,1.5689 1.35046,0.73478l9.01626,-11.28024c0.16883,-0.20853 0.25819,-0.40715 0.25819,-0.63551c0,-0.37737 -0.28797,-0.67525 -0.70499,-0.67525l-5.62032,0l2.96906,-8.04317c0.37728,-1.02275 -0.67525,-1.5689 -1.3505,-0.72486l-9.01626,11.27037c-0.16883,0.21845 -0.2681,0.41702 -0.2681,0.63551c0,0.38724 0.29788,0.67521 0.71495,0.67521z"
        id="svg_1"
      />
    </g>
    <title>Layer 1</title>
    <path
      transform="rotate(9.10965 11.9998 11.999)"
      stroke="null"
      d="m5.71921,13.90408l5.61036,0l-2.9591,8.04317c-0.38728,1.02275 0.67521,1.5689 1.35046,0.73478l9.01626,-11.28024c0.16883,-0.20853 0.25819,-0.40715 0.25819,-0.63551c0,-0.37737 -0.28797,-0.67525 -0.70499,-0.67525l-5.62032,0l2.96906,-8.04317c0.37728,-1.02275 -0.67525,-1.5689 -1.3505,-0.72486l-9.01626,11.27037c-0.16883,0.21845 -0.2681,0.41702 -0.2681,0.63551c0,0.38724 0.29788,0.67521 0.71495,0.67521z"
      id="svg_1"
      xmlns="http://www.w3.org/2000/svg"
    />
  </g>,
  "Trigger"
);
