import React from "react";
import { useWorkflowsBuilderPage } from "./WorkflowsBuilderPage.hooks";

type ContextValue = ReturnType<typeof useWorkflowsBuilderPage>;

const WorkflowsContext = React.createContext<ContextValue | null>(null);

export function useWorkflowsBuilderPageView(): ContextValue {
  const contextValue = React.useContext(WorkflowsContext);
  if (!contextValue) {
    throw Error("Should be used inside the WorkflowsBuilderPageProvider");
  }
  return contextValue;
}

export const WorkflowsBuilderPageProvider = WorkflowsContext.Provider;
