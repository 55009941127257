import React from "react";
import { Box, Chip, MenuItem, Typography } from "@mui/material";
import { MoreHorizontal, Trigger } from "@tiny/icons";
import { Handle, NodeProps, Position } from "reactflow";
import MenuIconButton from "../../../../components/MenuIconButton/MenuIconButton";
import { NodeData } from "../types";
import useDeleteNode from "../Hooks/useDeleteNode";
import { useWorkflowsBuilderPageView } from "../../WorkflowsBuilderPage.context";
import { TruncateTooltip } from "../../../../components";
import { usePutWorkflowsEmailTemplateMutation } from "../../../../app/useGeneratedApi";

const ActionNode = ({ id, data }: NodeProps<NodeData>) => {
  const { deleteNode } = useDeleteNode();
  const { configured } = data.config;
  const { openCampaignBuilder, viewMode } = useWorkflowsBuilderPageView();

  const {
    mutateAsync: putWorkflowMutateAsync,
    isLoading: isPutWorkflowLoading,
  } = usePutWorkflowsEmailTemplateMutation();

  const handleDelete = async (e: React.SyntheticEvent) => {
    e?.stopPropagation();
    try {
      if (configured && data?.template_id) {
        await putWorkflowMutateAsync({
          id: data?.template_id,
          data: {
            status: "DELETED",
          },
        });
      }
      deleteNode(id);
    } catch (error) {
      console.error("Failed to delete workflow:", error);
    }
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: "transparent", border: "none" }}
      />
      <Box
        sx={(theme) => ({
          background: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[80]}`,
          padding: 3,
          paddingBottom: 4,
          borderRadius: 3,
          display: "flex",
          flexDirection: "column",
          width: "400px",
          cursor: "pointer",
        })}
        onClick={() => openCampaignBuilder(id)}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mr: -1 }}
        >
          <Box display="flex" alignItems="center">
            <Trigger
              sx={(theme) => ({
                fontSize: 20,
                mr: 1,
                color: theme.palette.purple[150],
              })}
            />
            <Typography
              variant="label1"
              color="grey.80"
              sx={(theme) => ({
                textTransform: "uppercase",
                color: theme.palette.purple[150],
              })}
            >
              ACTION {"›"} {data.label}
            </Typography>
          </Box>
          <MenuIconButton
            icon={<MoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          >
            <MenuItem
              onClick={(e) => {
                openCampaignBuilder(id);
              }}
            >
              Edit
            </MenuItem>
            {!viewMode && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
          </MenuIconButton>
        </Box>
        <Box>
          <TruncateTooltip arrow>
            <Typography
              variant="p1"
              color="grey.100"
              fontWeight="600"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflowWrap: "break-word",
              }}
            >
              {data.name}
            </Typography>
          </TruncateTooltip>
        </Box>
        <Box mt={2}>
          {configured ? (
            <Chip
              sx={(theme) => ({
                height: theme.spacing(5),
                backgroundColor: theme.palette.primary[100],
                color: theme.palette.common.white,
                mr: 1,
                fontSize: theme.spacing(3),
                lineHeight: theme.spacing(3),
                "& .MuiChip-label": { fontWeight: 700 },
              })}
              label="Configured"
            />
          ) : (
            <Chip
              sx={(theme) => ({
                height: theme.spacing(5),
                backgroundColor: theme.palette.yellow[150],
                color: theme.palette.common.white,
                mr: 1,
                fontSize: theme.spacing(3),
                lineHeight: theme.spacing(3),
                "& .MuiChip-label": { fontWeight: 700 },
              })}
              label="Not-Configured"
            />
          )}
        </Box>
      </Box>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: "transparent", border: "none" }}
      />
    </>
  );
};

export default ActionNode;
