import React from "react";

import StepContainer from "../StepContainer";
import DetailsPanel from "./DetailsPanel";
import SenderPanel from "./SenderPanel";

function DetailsStep(): JSX.Element | null {
  return (
    <StepContainer>
      <DetailsPanel />
      <SenderPanel />
    </StepContainer>
  );
}

export default DetailsStep;
