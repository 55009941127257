import React from "react";
import { Box, Container } from "@mui/material";
import WorkflowsHeader from "./WorkflowsHeader/WorkflowsHeader";
import { WorkflowsBuilderPageProvider } from "./WorkflowsBuilderPage.context";
import { useWorkflowsBuilderPage } from "./WorkflowsBuilderPage.hooks";
import WorkflowBuilder from "./WorkflowsBuilder";
import CampaignBuilder from "./CampaignBuilder";
import { TinyDrawerProvider } from "@tiny/lib";

const WorkflowsBuilder = () => {
  const workflowsBuilderView = useWorkflowsBuilderPage();

  return (
    <WorkflowsBuilderPageProvider value={workflowsBuilderView}>
      <TinyDrawerProvider>
        <Container
          sx={(theme) => ({
            position: "fixed",
            m: 0,
            p: 0,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: theme.palette.grey[20],
            minWidth: "100vw",
            minHeight: "100vh",
            zIndex: 0,
          })}
          disableGutters
        >
          <Box display="flex" flexDirection="column">
            <WorkflowsHeader />
            <WorkflowBuilder />
            <CampaignBuilder />
          </Box>
        </Container>
      </TinyDrawerProvider>
    </WorkflowsBuilderPageProvider>
  );
};

export default WorkflowsBuilder;
