import React from "react";
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const WorkflowEditConfirmationDialogContent = ({
  onContinue,
  onCancel,
}: {
  onContinue: () => void;
  onCancel?: () => void;
}) => {
  return (
    <>
      <DialogContent sx={{ mt: 2 }}>
        <Typography variant="label1" color="grey.90">
          Are you sure you want to pause this workflow? If you pause, all
          customers currently in progress will be stopped, and they won’t
          continue through the workflow. Any scheduled actions for them will be
          canceled.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onContinue}>
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkflowEditConfirmationDialogContent;
