import { authStorage } from "@tiny/utils/api/authStorage";
import { useState, useEffect } from "react";

interface JwtTokenPayload {
  accountId: string;
  sub: string;
  relayId: string;
  user_name: string;
  campaignWorkflowType: string;
  scope: string[];
  exp: number;
  authorities: string[];
  jti: string;
  client_id: string;
  [key: string]: unknown;
  showWorkflows: string;
}

export const useJwtToken = (): JwtTokenPayload | null => {
  const [tokenPayload, setTokenPayload] = useState<JwtTokenPayload | null>(
    null
  );

  useEffect(() => {
    try {
      const accessToken = authStorage?.getAccessToken();
      if (accessToken) {
        const payloadBase64 = accessToken.split(".")[1];
        const decodedPayload = JSON.parse(atob(payloadBase64));
        setTokenPayload(decodedPayload);
      }
    } catch (error) {
      console.error("Failed to decode access token:", error);
      setTokenPayload(null);
    }
  }, []);

  return tokenPayload;
};

export default useJwtToken;
