import React from "react";
import { useRefCallBack } from "./useRefCallBack";

interface UseSelectOptions<T> {
  isEqual: (a: T, b: T) => boolean;
}

function isEqualDefault<T>(a: T, b: T) {
  return a === b;
}

function useSelect<T = string>(
  options: UseSelectOptions<T> = {
    isEqual: isEqualDefault,
  }
) {
  const { isEqual } = options;

  const [selected, setSelected] = React.useState<T[]>([]);

  const isEqualRefCallback = useRefCallBack(isEqual);

  const handleToggleSelect = React.useCallback(
    (item?: T) => {
      if (!item) {
        return;
      }
      setSelected((prev) => {
        const index = prev.findIndex((i) => isEqualRefCallback(i, item));
        if (index === -1) {
          return [...prev, item];
        } else {
          const next = [...prev];
          next.splice(index, 1);
          return next;
        }
      });
    },
    [isEqualRefCallback]
  );

  const handleDeselectAll = React.useCallback(() => {
    setSelected([]);
  }, []);

  const handleSetSelected = React.useCallback((items: T[]) => {
    setSelected(items);
  }, []);

  const handleAddSelected = React.useCallback(
    (items: T[]) => {
      setSelected((prev) => {
        const next = [...prev];
        items?.forEach((item) => {
          const exist = prev.some((i) => isEqualRefCallback(i, item));
          if (!exist) {
            next.push(item);
          }
        });
        return next;
      });
    },
    [isEqualRefCallback]
  );

  const handleRemoveSelected = React.useCallback(
    (items: T[]) => {
      setSelected((prev) => {
        let next = [...prev];
        items?.forEach((item) => {
          const exist = prev.some((i) => isEqualRefCallback(i, item));
          if (exist) {
            next = next.filter((i) => !isEqualRefCallback(i, item));
          }
        });
        return next;
      });
    },
    [isEqualRefCallback]
  );

  const isSelected = React.useCallback(
    (item?: T) => {
      if (!item) {
        return false;
      }
      return selected.some((i) => isEqualRefCallback(i, item));
    },
    [selected, isEqualRefCallback]
  );

  const isAllSelected = React.useCallback(
    (items?: T[]) => {
      if (!items) {
        return false;
      }
      return items.every((item) =>
        selected.some((i) => isEqualRefCallback(i, item))
      );
    },
    [selected, isEqualRefCallback]
  );

  const haveSelections = React.useMemo(() => {
    return selected.length > 0;
  }, [selected]);

  const toggleSelect = handleToggleSelect;
  const select = handleSetSelected;
  const deselectAll = handleDeselectAll;
  const addToSelected = handleAddSelected;
  const removeToSelected = handleRemoveSelected;

  return {
    selected,
    handleToggleSelect,
    handleSetSelected,
    handleDeselectAll,
    isSelected,
    isAllSelected,
    haveSelections,
    handleAddSelected,
    handleRemoveSelected,
    toggleSelect,
    select,
    deselectAll,
    addToSelected,
    removeToSelected,
  };
}

export default useSelect;
