import React from "react";
import { TitleForm } from "./WorkflowsHeader.types";
import { convertDateRangeToFilter } from "@tiny/utils/dateUtils";
import { useForm } from "react-hook-form";
import { useWorkflowsBuilderPageView } from "../WorkflowsBuilderPage.context";
import { api } from "../../../app/apiInstance";
import { downloadFileData, ResponseHeaders } from "../../../utils/fileUtils";
import { enqueueSnackbar } from "notistack";
import { DEFAULT_ERROR_MESSAGE } from "../../../utils/errorUtils";

const useWorkflowsHeader = () => {
  const {
    getHeaderControlAction,
    quitWithoutSaving,
    downloadEnabled,
    showQuitWorkflows,
    workflowData,
    setWorkflowData,
    getRestrictionOptions,
    viewMode,
  } = useWorkflowsBuilderPageView();

  React.useEffect(() => {
    setWorkflowTitle(workflowData.name);
  }, [workflowData]);

  const [editTitle, setEditTitle] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const [formTitle, setFormTitle] = React.useState<string>(
    workflowData.name || "My First Workflow"
  );
  const [workflowTitle, setWorkflowTitle] =
    React.useState<string>("My First Workflow");
  const toggleEditTitle = () => setEditTitle((prev) => !prev);
  const form = useForm<TitleForm>();
  const saveTitle = async (values: TitleForm) => {
    try {
      setWorkflowData((prev) => ({
        ...prev,
        name: values?.name,
      }));
    } catch (e) {
      return;
      // TODO: handle error
    }
    setWorkflowTitle(values.name);
    toggleEditTitle();
  };

  const quitWorkflows = React.useCallback(() => {
    quitWithoutSaving?.();
  }, []);

  const headerControlAction = React.useMemo(() => {
    return getHeaderControlAction();
  }, [workflowData]);

  const onClickDownload = React.useCallback(async () => {
    setIsDownloading(true);
    enqueueSnackbar("Downloading!", {
      variant: "info",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      autoHideDuration: 2000,
    });
    try {
      const { data, headers } =
        await api.postWorkflowStatisticsExportByWorkflowId(
          workflowData.id,
          {
            format: "blob",
            headers: { "Access-Control-Expose-Headers": "Content-Disposition" },
          },
          {
            from: new Date(workflowData?.createdAt || "").getTime(),
            to: new Date().getTime(),
          }
        );
      downloadFileData(data, headers as ResponseHeaders);
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      enqueueSnackbar(DEFAULT_ERROR_MESSAGE, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        autoHideDuration: 10000,
      });
    }
  }, [workflowData.id, setIsDownloading]);

  const restrictions = getRestrictionOptions();

  return {
    onClickDownload,
    downloadEnabled,
    isDownloading,
    editTitle,
    toggleEditTitle,
    headerControlAction,
    quitWorkflows,
    form,
    formTitle,
    saveTitle,
    setFormTitle,
    titleMaxLength: 64,
    workflowTitle,
    restrictions,
    viewMode,
  };
};

export default useWorkflowsHeader;
