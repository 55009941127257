import React from "react";
import { Box, Theme, Typography } from "@mui/material";
import { EmptyStateProps } from "./EmptyState.types";

function EmptyState(props: EmptyStateProps) {
  const { children, title, message, icon, link, linkTo, linkId } = props;
  function getIconColor(props: EmptyStateProps, theme: Theme) {
    switch (props.iconColor) {
      case "primary":
        return theme.palette.primary.main;
      default:
        return theme.palette.grey[900];
    }
  }

  function getBackgroundColor(props: EmptyStateProps, theme: Theme) {
    switch (props.backgroundIcon) {
      case "grey":
        return theme.palette.grey[100];
      default:
        return theme.palette.grey[50];
    }
  }
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: 16,
        textAlign: props.align,
        [theme.breakpoints.up("md")]: {
          padding: 24,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: props.backgroundIcon ? "block" : "flex",
          margin: props.backgroundIcon ? "0 auto" : "",
          padding: props.backgroundIcon ? 20 : 0,
          borderRadius: props.backgroundIcon ? 8 : 0,
          justifyContent: props.align === "center" ? "center" : "",
          "& svg": {
            color: getIconColor(props, theme),
          },
          [theme.breakpoints.down("md")]: {
            "& svg": {
              width: 32,
              height: 32,
            },
          },
          [theme.breakpoints.up("md")]: {
            "& svg": {
              width: 48,
              height: 48,
            },
          },
        })}
      >
        {icon}
      </Box>
      <Box
        sx={{
          flex: "1",
        }}
      >
        <Typography
          sx={{
            position: "relative",
          }}
          variant="h3"
          fontWeight="semiBold"
          color="grey"
          mt={4}
          mb={1}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            position: "relative",
          }}
          variant="p2"
          component="p"
          color="grey"
          m={0}
        >
          {message}
          {"."}
        </Typography>
      </Box>
      {children && (
        <Box
          sx={{
            marginTop: 12,
            position: "relative",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

export default EmptyState;
