import React from "react";
import StepContainer from "../StepContainer";
import TemplatePanel from "./TemplatePanel";
import { useCampaignPageView } from "../CampaignPage.context";
import ComponentLoader from "../../../../customComponents/ComponentLoader/ComponentLoader";
import { Box, Container } from "@mui/material";
import { Stripo } from "@tiny/stripo-editor";
import { useConfigView } from "../../../../Config.context";

function DesignStep() {
  const {
    handleTemplateChange,
    handleTemplateLoaded,
    handleTemplateUnLoaded,
    hideTemplateHistory,
    selectTemplate,
    stripoRef,
    templateCss,
    templateEditing,
    templateHtml,
    templateEditorType,
    templateHistoryVisible,
    screenLoading,
    setScreenLoading,
  } = useCampaignPageView();

  const { config } = useConfigView();
  const { appName } = config || {};
  if (templateEditing) {
    const codeEditorVisible = templateEditorType === "HTML";
    const settingsVisible = templateEditorType === "DRAG_AND_DROP";
    React.useEffect(() => {
      setScreenLoading(true);
    }, []);

    return (
      <Container
        sx={(theme) => ({
          minWidth: "100vw",
          minHeight: "100%",
          position: "relative",
        })}
        disableGutters
      >
        {screenLoading && (
          <Box
            sx={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0,0,0,0.5)",
            }}
          >
            <ComponentLoader loading={screenLoading} />
          </Box>
        )}
        <Stripo
          codeEditorVisible={codeEditorVisible}
          css={templateCss ?? ""}
          emailId={"1231323"}
          historyVisible={templateHistoryVisible}
          html={templateHtml ?? ""}
          onTemplateChange={handleTemplateChange}
          onTemplateLoaded={() => {
            handleTemplateLoaded();
          }}
          onTemplateUnloaded={handleTemplateUnLoaded}
          onHistoryHide={hideTemplateHistory}
          ref={stripoRef}
          settingsVisible={settingsVisible}
        />
      </Container>
    );
  }

  return (
    <StepContainer>
      <TemplatePanel selectTemplate={selectTemplate} />
    </StepContainer>
  );
}

export default DesignStep;
