import React from "react";
import { WorkflowRowProps } from "./WorkflowRow";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { WorkflowInfoView } from "../../../app/GeneratedApi";
import { useConfigView } from "../../../Config.context";
import { useDialogInvokers } from "@tiny/lib";
import WorkflowEditConfirmationDialogContent from "../../../customComponents/WorkflowEditPausedDialog";

export function useWorkflowRow(props: WorkflowRowProps) {
  const {
    item,
    onPause,
    onDelete,
    onClone,
    onSelectRow,
    isSelected,
    onStart,
    onExport,
  } = props;

  const { requestSmallDialog, exitDialog } = useDialogInvokers();

  const { config } = useConfigView();
  const { navigateTo } = config || {};

  const { lgUp } = useBreakpoints();

  const [detailsOpen, setDetailsOpen] = React.useState(false);

  const onClickClone = React.useCallback(
    async (row: WorkflowInfoView) => {
      await onClone(row.id!);
    },
    [onClone]
  );

  const onClickEdit = React.useCallback(
    (row: WorkflowInfoView) => {
      navigateTo && navigateTo(`/workflow/${row.id}`);
    },
    [history]
  );

  const onClickDelete = React.useCallback(
    (row: WorkflowInfoView) => {
      onDelete(row.id!);
    },
    [onDelete]
  );

  const onClickPause = React.useCallback(
    (row: WorkflowInfoView) => {
      onPause(row.id!);
    },
    [onPause]
  );

  const onClickActivate = React.useCallback(
    (row: WorkflowInfoView) => {
      onStart(row.id!);
    },
    [onStart]
  );

  const onClickExport = React.useCallback(
    (row: WorkflowInfoView) => {
      onExport(row);
    },
    [onExport]
  );

  const toggleDetails = () => {
    setDetailsOpen((prev) => !prev);
  };

  const selectWorkflow = React.useCallback(() => {
    onSelectRow(item.id!);
  }, [item.id, onSelectRow]);

  const editEnabled = React.useMemo(() => {
    return ["DRAFT"].includes(item.status!);
  }, [item.status]);

  const deleteEnabled = React.useMemo(() => {
    return ["DRAFT", "PAUSED"].includes(item.status!);
  }, [item.status]);

  const isScheduled = React.useMemo(
    () => item.status === "ACTIVE",
    [item.status]
  );
  const isDraft = React.useMemo(() => item.status === "DRAFT", [item.status]);

  const workflowLink = React.useMemo(() => {
    const workflowId = item.id;
    return `/workflow/${workflowId}`;
  }, [item.id]);

  const showPauseConfirmation = (row: WorkflowInfoView) => {
    requestSmallDialog({
      titleProps: { children: "Please confirm", variant: "label1R" },
      contentProps: {
        dividers: false,
        sx: {
          border: "none",
        },
        children: (
          <WorkflowEditConfirmationDialogContent
            onContinue={() => onClickPause(row)}
            onCancel={exitDialog}
          />
        ),
      },
    });
  };

  const moreActions = React.useMemo(() => {
    const result: any[] = [{ name: "Clone", onClickAction: onClickClone }];
    if (editEnabled && lgUp) {
      result.push({ name: "Edit", onClickAction: onClickEdit });
    }
    if (deleteEnabled) {
      result.push({ name: "Delete", onClickAction: onClickDelete });
    }
    if (isScheduled) {
      result.push({
        name: "Pause",
        onClickAction: showPauseConfirmation,
      });
    }
    if (!isDraft) {
      result.push({
        name: "Download stats",
        onClickAction: onClickExport,
      });
    }
    return result;
  }, [
    deleteEnabled,
    editEnabled,
    isScheduled,
    lgUp,
    onClickActivate,
    onClickPause,
    onClickClone,
    onClickDelete,
    onClickEdit,
  ]);

  const checked = isSelected(item.id!);

  return {
    workflowLink,
    checked,
    detailsOpen,
    moreActions,
    selectWorkflow,
    toggleDetails,
    editEnabled,
  };
}
