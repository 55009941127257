import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { sidebarItems } from "./sidebarItems";
import SidebarItem from "./SidebarItem";
import { ChevronDown } from "@tiny/icons";
import { useWorkflowsBuilderPageView } from "../../WorkflowsBuilderPage.context";

const LeftSidebar = () => {
  const onDragStart = (
    event: React.DragEvent,
    nodeType: string,
    label: string
  ) => {
    event.dataTransfer.setData(
      "application/reactflow",
      JSON.stringify({ type: nodeType, label })
    );
    event.dataTransfer.effectAllowed = "move";
  };
  const { viewMode } = useWorkflowsBuilderPageView();

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.background.paper,
        minWidth: "350px",
        border: `1px solid ${theme.palette.grey[40]}`,
        ...(viewMode
          ? {
              opacity: 0.5,
              cursor: "notAllowed",
            }
          : {}),
      })}
    >
      {sidebarItems.map((item, index) => (
        <Accordion
          key={index}
          defaultExpanded
          disableGutters
          sx={(theme) => ({
            p: 4,
            boxShadow: 0,
            background: theme.palette.common.white,
            "&:before": { border: "none", background: "none" },
            borderBottom: `1px solid ${theme.palette.grey[60]}`,
          })}
        >
          <AccordionSummary
            expandIcon={
              <ChevronDown
                sx={(theme) => ({ color: theme.palette.grey[60] })}
              />
            }
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              p: 0,
              "& .MuiAccordionSummary-content": {
                margin: 0,
                padding: 0,
              },
            }}
          >
            {item.name}
          </AccordionSummary>
          <AccordionDetails>
            {item.items.map((subItem) => (
              <Box>
                <SidebarItem
                  enabled={!viewMode}
                  key={subItem.id}
                  item={subItem}
                  onDragStart={onDragStart}
                />
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default LeftSidebar;
