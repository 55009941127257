import { Node, Edge } from "../types";
import { createNewNode, createNewEdge } from "./commons";

const initialNodes: Node[] = [
  createNewNode(
    "1",
    "TRIGGER",
    { x: 0, y: 20 },
    {
      label: "trigger",
      config: {
        configured: false,
      },
    }
  ),
  createNewNode(
    "2",
    "EXIT",
    { x: 0, y: 280 },
    {
      label: "Exit",
      config: { configured: true },
    }
  ),
];

const initialEdges: Edge[] = [createNewEdge("1", "2")];

export { initialEdges, initialNodes };
