import React from "react";
import { Box, Typography } from "@mui/material";
import { CircleCheckOutline } from "@tiny/icons";
import { Handle, NodeProps, Position } from "reactflow";
import { Node, NodeData } from "../types";

const ExitNode = ({ data }: NodeProps<NodeData>) => (
  <>
    <Handle
      type="target"
      position={Position.Top}
      style={{ background: "transparent", border: "none" }}
    />
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "400px",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={(theme) => ({
          background: "transparent",
          border: `1px solid ${theme.palette.grey[80]}`,
          padding: 2,
          borderRadius: 3,
          width: "80px",
        })}
      >
        <CircleCheckOutline
          sx={(theme) => ({
            fontSize: 24,
            mr: 2.5,
          })}
        />
        <Typography
          variant="label1"
          color="grey.80"
          sx={{ textTransform: "capitalize" }}
        >
          {data.label}
        </Typography>
      </Box>
    </Box>
  </>
);
export default ExitNode;
