import React from "react";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactFlowProvider } from "reactflow";
import { FlowContainer } from "./FlowContainer";
import { LeftSidebar } from "./LeftSidebar";

const WorkflowBuilder = () => {
  const sm = useMediaQuery("(max-width:600px)");

  return !sm ? (
    <ReactFlowProvider>
      <Box display="flex">
        <LeftSidebar />
        <FlowContainer />
      </Box>
    </ReactFlowProvider>
  ) : (
    <div></div>
  );
};

export default WorkflowBuilder;
