import React from "react";
import { Typography } from "@mui/material";
import EditPanel from "../../EditPanel";
import DetailsForm from "./DetailsForm";
import { useDetailsPanel } from "./DetailsPanel.hooks";
import DetailsView from "./DetailsView";

function DetailsPanel() {
  const saveDetailsLoading = false;

  const { details, edit, editing, form, save, submitForm, saveDisabled } =
    useDetailsPanel();

  return (
    <EditPanel
      title="Details"
      open={editing}
      buttonLoading={saveDetailsLoading}
      buttonDisabled={saveDisabled}
      buttonVariant={editing ? "contained" : "outlined"}
      subtitle={
        <Typography
          variant="inherit"
          mt={-2.5}
          mb={6}
          color="grey"
          component="p"
        >
          Enter the campaign name, subject line and preview text (optional).
        </Typography>
      }
      onOpen={edit}
      onClose={save}
    >
      {editing ? (
        <DetailsForm form={form} onSubmit={submitForm} />
      ) : (
        <DetailsView details={details} />
      )}
    </EditPanel>
  );
}

export default DetailsPanel;
