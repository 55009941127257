import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { extractBackendError } from "../utils/errorUtils";
import GeneratedApi, { ContentType, FullRequestParams } from "./GeneratedApi";
import qs from "qs";
import { authStorage } from "@tiny/utils";

class Api extends GeneratedApi {
  instance;
  accountStatusChangedHandler?: () => void;

  NO_AUTHORIZATION_REQUIRED_PATHS = [
    { url: "/clientservice/subscription/lead", method: "post" },
    { url: "/clientservice/sender-details/confirm-email", method: "post" },
  ];

  constructor(baseURL: string) {
    super();
    this.instance = axios.create({
      baseURL,
    });
    this.instance.interceptors.request.use((config: any) => {
      const accessToken = authStorage.getAccessToken();
      const token = localStorage.getItem("accessToken") || accessToken;
      if (config.headers) {
        config.headers.Authorization = token ? `Bearer ${token}` : "";
      }
      return config;
    });
    this.instance.interceptors.response.use(undefined, (error: any) => {
      this.analyzeResponseError(error);
      return Promise.reject(error);
    });
  }

  private async analyzeResponseError(error: any) {
    const forceReloadCodes = ["4.1.002", "4.1.003"];
    const logOutCodes = ["4.3.002"];
    const accountStatusChangedCode = ["4.2.004", "4.1.001"];
    const { pathname } = window.location;
    const backendError = await extractBackendError(error?.response);
    if (
      backendError?.errorCodes?.find((e) => logOutCodes.includes(e.errorCode))
    ) {
      // window.location.href = "/login";
    } else if (
      backendError?.status === 401 &&
      !["/sign-up", "/login"].includes(pathname)
    ) {
      // window.location.href = "/login";
    } else if (
      backendError?.errorCodes?.find((e) =>
        accountStatusChangedCode.includes(e.errorCode)
      )
    ) {
      this.accountStatusChangedHandler && this.accountStatusChangedHandler();
    } else if (
      backendError?.errorCodes?.find((e) =>
        forceReloadCodes.includes(e.errorCode)
      )
    ) {
      window.location.reload();
    }
  }

  private static preparePath(path: string, appName?: string): string {
    if (path.startsWith("/")) {
      const controller = path.substring(1).split("/")[0];
      switch (controller) {
        case "segment":
        case "audience":
        case "customer":
        case "customer-event":
        case "customer-action":
        case "suppression":
        case "pre-defined-segment":
          return `/customerservice${path}`;
        case "tone":
        case "merge-tag":
        case "content":
          return `/einsteinservice${path}`;
        case "form":
        case "form-template":
          return `/formservice${path}`;
        case "automation":
          return `/automationservice${path}`;
        case "report":
        case "dkim":
          return `/notificationservice${path}`;
        case "html-template":
        case "campaign":
        case "account-html-template":
        case "campaign-layout":
        case "template-tag":
          return `/campaignservice${path}`;
        case "workflow":
        case "workflow-email-template":
          if (path.includes("statistics/export")) {
            return `/reportservice${path}`;
          }
          return `/workflowservice${path}`;
        case "sender-details":
          return appName === "tinyEmail"
            ? `/clientservice${path}`
            : `/accountservice${path}`;
        default:
          return `/clientservice${path}`;
      }
    } else {
      return path;
    }
  }

  async request<T = any>({
    path,
    method,
    body,
    type,
    query,
    format,
    headers,
    baseURL,
    appName,
  }: FullRequestParams & { appName?: string }): Promise<AxiosResponse<T>> {
    const options: AxiosRequestConfig = {
      url: Api.preparePath(path, appName),
      method,
      responseType: format,
      headers,
      paramsSerializer: function (params: any) {
        return qs.stringify(params, { indices: false, encode: false });
      },
    };

    if (type === ContentType.FormData && body && typeof body === "object") {
      const formData = new FormData();
      for (const key in body) {
        const item: any = body[key as keyof typeof body];
        if (item instanceof File) {
          formData.append(key, item);
        } else {
          const blob = new Blob([JSON.stringify(item)], {
            type: "application/json",
          });
          formData.append(key, blob);
        }
      }
      options.data = formData;
    } else {
      if (body) {
        options.data = body;
      }
      if (query) {
        options.params = query;
      }
    }

    if (baseURL) {
      options.baseURL = baseURL;
    }

    return this.instance.request(options);
  }
}

export { Api };
