import React from "react";

function useSenderDetailsFeatures() {
  const [totalSenders, setTotalSenders] = React.useState<number>();

  const [message, setMessage] = React.useState<string>();

  const FEATURE_SYSTEM_NAME = "SENDER_DETAILS";

  const [openUpgradeToFeature, setOpenUpgradeToFeature] =
    React.useState<string>();

  const onCloseUpgradeToFeature = React.useCallback(
    () => setOpenUpgradeToFeature(undefined),
    []
  );

  return {
    message,
    openUpgradeToFeature,
    onCloseUpgradeToFeature,
    setTotalSenders,
  };
}

export default useSenderDetailsFeatures;
