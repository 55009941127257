import React, { useContext, useEffect, useMemo, useRef } from "react";
import ReactFlow, { Controls, MiniMap, ReactFlowInstance } from "reactflow";
import { Box, duration } from "@mui/material";
import "reactflow/dist/style.css";
import {
  ActionNode,
  ExitNode,
  LogicNode,
  TriggerNode,
  TimingNode,
} from "../CustomNodes";
import { AddEdge } from "../CustomEdges";
import { useWorkflowsBuilderPageView } from "../../WorkflowsBuilderPage.context";

export function FlowContainer() {
  const { workflowData } = useWorkflowsBuilderPageView();
  const { nodes, edges } = workflowData?.structure;

  const reactFlowWrapper = useRef<HTMLDivElement>(null);

  const nodeTypes = useMemo(
    () => ({
      TRIGGER: TriggerNode,
      ACTION: ActionNode,
      LOGIC: LogicNode,
      EXIT: ExitNode,
      TIMING: TimingNode,
    }),
    []
  );

  const edgeTypes = useMemo(() => ({ ADD: AddEdge }), []);

  const onInit = (reactFlowInstance: ReactFlowInstance) => {
    if (reactFlowWrapper.current) {
      const LEFT_SIDEBAR_WIDTH = 350;
      const NODE_WIDTH = 400;
      const totalNodeWidth = NODE_WIDTH / 2;
      const middleX = Math.ceil(
        (reactFlowWrapper.current.offsetWidth -
          LEFT_SIDEBAR_WIDTH -
          totalNodeWidth) /
          2
      );
      reactFlowInstance.setViewport(
        { x: middleX, y: 0, zoom: 1 },
        { duration: 500 }
      );
    }
  };

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        height: "calc(100vh - 60px)",
        background: theme.palette.background.default,
      })}
      ref={reactFlowWrapper}
    >
      <ReactFlow
        onInit={onInit}
        nodes={nodes}
        edges={edges}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        panOnScroll
        maxZoom={1}
        panOnDrag
        selectionKeyCode={null}
        zoomOnDoubleClick={true}
        zoomOnPinch={true}
      >
        <Controls />
        <MiniMap />
      </ReactFlow>
    </Box>
  );
}
