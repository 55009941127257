import { WorkflowSortOption } from "./WorkflowList.types";

export const itemsPerPageOptions = [5, 10, 15, 20];

export const sortWorkflowsAnaliticsOptions: WorkflowSortOption[] = [
  { label: "Last Updated", value: ["updatedAt,DESC"], action: "Modified" },
  { label: "Created Date", value: ["createdAt,DESC"], action: "Created" },
];

export const pageableDefault = {
  page: 0,
  size: itemsPerPageOptions[0],
  sort: sortWorkflowsAnaliticsOptions[0].value,
};

export const workflowDateFormat = "dd MMM, yy - hh:mm a";
