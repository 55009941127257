import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Clock, CloseBig } from "@tiny/icons";
import React from "react";
import { Controller, FormProvider } from "react-hook-form";
import useTimerDelayForm from "./TimerDelayForm.hooks";
import { InputLabel } from "@mui/material";

const TimeDelayForm = (props: { id: string }) => {
  const {
    goBack,
    onSubmit,
    methods,
    formValues,
    control,
    saveTimerDisabled,
    viewMode,
  } = useTimerDelayForm(props);
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        style={{
          height: "100%",
        }}
      >
        <Box
          sx={{
            py: 4,
            px: 6,
            display: "flex",
            flexDirection: "column",
            width: { xs: "400px", xl: "25vw" },
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Clock
                sx={{
                  color: "yellow.150",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Typography variant="h2">Time Delay</Typography>
              </Box>
            </Box>
            <IconButton sx={{ px: 0 }} onClick={goBack}>
              <CloseBig />
            </IconButton>
          </Box>
          <Box sx={{ flex: 1, display: "flex", gap: 4, mt: 8 }}>
            <Box
              sx={{
                width: "50%",
              }}
            >
              <Controller
                name="days"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      id="repeat-on-select-label"
                      sx={(theme) => ({
                        backgroundColor: theme.palette.background.paper,
                        pr: 2,
                      })}
                    >
                      Days
                    </InputLabel>
                    <Select
                      size="small"
                      fullWidth
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e.target.value);
                      }}
                      aria-label="days"
                      label="Days"
                      placeholder="Days"
                      displayEmpty
                      renderValue={(item) => {
                        return item;
                      }}
                    >
                      {[...new Array(31)].map((_, index) => (
                        <MenuItem key={`days-${index}`} value={index}>
                          {index}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Box>
            <Box
              sx={{
                width: "50%",
              }}
            >
              <Controller
                name="hours"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      id="repeat-on-select-label"
                      sx={(theme) => ({
                        backgroundColor: theme.palette.background.paper,
                        pr: 2,
                      })}
                    >
                      Hours
                    </InputLabel>
                    <Select
                      size="small"
                      fullWidth
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e.target.value);
                      }}
                      aria-label="hours"
                      label="Hours"
                      placeholder="Hours"
                      displayEmpty
                      renderValue={(item) => {
                        return item;
                      }}
                    >
                      {[...new Array(24)].map((_, index) => (
                        <MenuItem key={`hour-${index}`} value={index}>
                          {index}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            <Button onClick={goBack}>Cancel</Button>
            <Button
              variant="contained"
              type="submit"
              disabled={
                saveTimerDisabled ||
                (!formValues.minutes &&
                  !formValues.days &&
                  !formValues.hours) ||
                viewMode
              }
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default TimeDelayForm;
