import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { RootLoader } from "@/types/RootLoader";
import { WorkflowPageProps } from "./Workflow.types";

const useWorkflow = (props: WorkflowPageProps) => {
  const { page } = props;
  const appName = "tinyAlbert" as const;
  const { accountInfo } = useRouteLoaderData("root") as RootLoader;

  const navigate = useNavigate();
  const quitApp = () => {
    navigate("/main/workflows");
  };

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const config = {
    appName,
    page,
    quitApp,
    baseUrl: import.meta.env.VITE_APP_API_URL || "",
    navigateTo,
    accountId: accountInfo?.id,
  };
  return { config };
};

export default useWorkflow;
