import React from "react";
import TemplateList from "../../../../../customComponents/TemplateList";
import { useCampaignPageView } from "../../CampaignPage.context";
import { Box } from "@mui/material";
import { Tab, Tabs } from "../../../../../components";
import Panel from "../../Panel";
import { TemplatePanelProps } from "./TemplatePanel.types";
import { useTemplatePanel } from "./TemplatePanel.hooks";

function TemplatePanel(props: TemplatePanelProps) {
  const { selectTemplate } = props;

  const {
    campaignTemplate,
    campaignId,
    details,
    refetchTemplateSections,
    selectCustomTemplate,
  } = useCampaignPageView();

  const { activeTab, changeTab } = useTemplatePanel();

  const getPreviewMainActionName = React.useCallback((sectionName: string) => {
    return sectionName === "Currently selected"
      ? "Edit template"
      : "Use template";
  }, []);

  return (
    <>
      <Panel
        title="Design your email"
        titleMarginBottom={2}
        contentPaddingX={0}
      >
        <Box pl={6} pr={6} pt={5} pb={0}>
          <Tabs value={activeTab} onChange={changeTab}>
            <Tab label="Drag & drop" value="dragAndDrop" />
            <Tab label="Custom code" value="customCode" />
          </Tabs>
        </Box>
        <TemplateList
          activeTab={activeTab}
          campaignId={campaignId}
          campaignName={details?.name}
          emptyStateMessage="No templates found matching your selected tags or search keyword. You can create a new template or get started with any of the branded templates or from template library."
          getPreviewMainActionName={getPreviewMainActionName}
          mainActionText="Use"
          onCreateCustomTemplate={selectCustomTemplate}
          onMainActionClick={selectTemplate}
          onPreviewMainActionClick={selectTemplate}
          refetchSections={refetchTemplateSections}
          selectable={false}
          selectedActionText="Edit"
          selectedTemplate={campaignTemplate ?? undefined}
        />
      </Panel>
    </>
  );
}

export default TemplatePanel;
