import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { WorkflowValues } from "./CreateWorkflow.types";
import useDialog from "../../hooks/useDialog";
import {
  usePostWorkflowMutation,
  useGetWorkflowTemplateDetailsByIdQuery,
} from "../../app/useGeneratedApi";
import { WorkflowCreateRequestView } from "../../app/GeneratedApi";
import { useConfigView } from "../../Config.context";
import {
  initialEdges,
  initialNodes,
} from "../../pages/WorkflowsBuilderPage/WorkflowsBuilder/Helpers/InitialData";

export default function useCreateWorkflow() {
  const { dialogVisible, hideDialog, showDialog } = useDialog();
  const workflow = useForm<WorkflowValues>();
  const { config } = useConfigView();
  const { navigateTo } = config || {};
  const [executing, setExecuting] = useState(false);
  const [workflowId, setWorkflowId] = useState<string | null>(null);

  const { data: workflowData, isLoading: isFetching } =
    useGetWorkflowTemplateDetailsByIdQuery(workflowId || "", {
      enabled: !!workflowId,
    });

  const {
    mutateAsync: postWorkflowMutateAsync,
    isLoading: isPostWorkflowLoading,
  } = usePostWorkflowMutation();

  const openWorkflowDialog = useCallback(
    (id?: string) => {
      if (id) setWorkflowId(id);
      showDialog();
    },
    [showDialog]
  );

  const saveDetails = useCallback(
    async (workflowPayload: WorkflowCreateRequestView) => {
      const data = await postWorkflowMutateAsync({
        data: { ...workflowPayload },
      });
      return data;
    },
    [postWorkflowMutateAsync]
  );

  const handleOnClose = useCallback(() => {
    workflow.reset();
    hideDialog();
    setWorkflowId(null);
  }, [workflow, hideDialog]);

  const handleSubmit = async (values: WorkflowValues) => {
    setExecuting(true);
    try {
      const resp = await saveDetails({
        name: values.name,
        description: values.name,
        ...(workflowData?.triggerType && {
          triggerType: workflowData.triggerType,
        }),
        structure: {
          nodes: workflowId
            ? workflowData?.structure?.nodes || []
            : initialNodes,
          edges: workflowId
            ? workflowData?.structure?.edges || []
            : initialEdges,
        },
      });
      setExecuting(false);
      navigateTo && navigateTo(`/workflow/${resp.id}`);
    } catch (error) {
      setExecuting(false);
    }
  };

  return {
    openWorkflowDialog,
    workflow,
    executing:
      Boolean(executing) ||
      isPostWorkflowLoading ||
      (workflowId ? isFetching : false),
    open: dialogVisible,
    onClose: handleOnClose,
    handleSubmit,
  };
}
