import React from "react";
import { Typography, Box } from "@mui/material";
import useId from "../../../../hooks/useId";
import { PanelProps } from "./Panel.types";

function Panel(props: PanelProps) {
  const {
    button,
    children,
    title,
    subtitle,
    titleMarginBottom,
    titleVariant,
    contentPaddingX,
    contentPaddingBottom,
    ...other
  } = props;

  const titleId = useId();

  return (
    <Box
      {...other}
      sx={{
        display: "flex",
        background: "#FFF",
        border: "1px solid #FFF",
        borderRadius: "10px",
        flexDirection: "column",
      }}
      aria-labelledby={titleId}
      component="section"
    >
      <Box
        sx={{
          display: "flex",
          padding: "24px 24px 0 24px",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              flex: 1,
            }}
            color="grey"
            fontWeight="semiBold"
            id={titleId}
            m={0}
            mb={titleMarginBottom}
            // variant={titleVariant || "h3"}
            variant="h3"
            display="block"
          >
            {title}
          </Typography>
          {subtitle && (
            <Box
              sx={{
                marginTop: 5,
              }}
            >
              {subtitle}
            </Box>
          )}
        </Box>
        <Box>{button}</Box>
      </Box>
      <Box
        sx={{
          paddingBottom: props.contentPaddingBottom ?? 6,
          paddingLeft: props.contentPaddingX ?? 6,
          paddingRight: props.contentPaddingX ?? 6,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default Panel;
