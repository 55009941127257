import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useWorkflowsBuilderPageView } from "../../../pages/WorkflowsBuilderPage/WorkflowsBuilderPage.context";
import { useDrawerInvokers } from "@tiny/lib";
import { useDebounce } from "@tiny/utils";
import { useConfigView } from "../../../Config.context";
import {
  useGetAlbertAudienceQuery,
  usePutSegmentFindFilterQuery,
  usePutSegmentFindIdsQuery,
} from "../../../app/useGeneratedApi";
import { AudienceOption } from "../TriggerForm.types";
import { AlbertAudience } from "../../../app/GeneratedApi";
interface FormValues {
  ruleType: string;
  source: string;
  event: string;
  from: string;
  audiences: AudienceOption[];
}

const useSelectRules = ({ id }: { id: string }) => {
  const { updateNode, getNode, setWorkflowData, viewMode } =
    useWorkflowsBuilderPageView();
  const { hideDrawer } = useDrawerInvokers();
  const node = getNode(id);

  const [saveRulesDisabled, setSaveRulesDisabled] = React.useState(true);
  const { config } = useConfigView();
  const isTinyEmail = config?.appName === "tinyEmail";
  const isTinyAlbert = config?.appName === "tinyAlbert";

  const [audienceSearchText, setSearchAudienceText] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [allAudiences, setAllAudiences] = React.useState<AudienceOption[]>([]);
  const [hasMoreResults, setHasMoreResults] = React.useState(true);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [filteredAlbertAudiences, setFilteredAlbertAudiences] = React.useState<
    AudienceOption[]
  >([]);

  const searchDebounced = useDebounce(audienceSearchText);

  const PAGE_SIZE = 10;

  const { data: audienceResponse, isFetching: isSegmentLoading } =
    usePutSegmentFindFilterQuery(
      { searchTextName: searchDebounced, segmentTypes: ["UPLOAD"] },
      {
        page: currentPage,
        size: PAGE_SIZE,
        sort: ["updatedAt,DESC"],
        countAllCustomers: false,
      },
      {
        keepPreviousData: true,
        enabled: isTinyEmail,
      }
    );

  const { data: selectedAudiences } = usePutSegmentFindIdsQuery(
    {
      ids: node?.data?.audiences?.map((aud: any) => aud.id),
    },
    {
      enabled: !!(isTinyEmail && node?.data?.audiences?.length),
    }
  );

  const { data: albertAudiences, isLoading: isAlbertLoading } =
    useGetAlbertAudienceQuery(
      {
        sort: "updatedAt,DESC",
      },
      {
        enabled: isTinyAlbert,
      }
    );

  const defaultValues = {
    ruleType: node?.data?.ruleType || "",
    source: node?.data?.source || "",
    event: node?.data?.event || "",
    from: "start-of-workflow",
    audiences: [] as AudienceOption[],
  };

  const methods = useForm<FormValues>({ defaultValues, mode: "onSubmit" });
  const { control, handleSubmit, watch, setValue } = methods;

  const formValues = useWatch({ control });

  React.useEffect(() => {
    if (!isTinyEmail || !audienceResponse) return;

    const content = audienceResponse?.content ?? [];

    const newAudiences = content.map((segment: any) => ({
      id: segment.id || "",
      name: segment.name || "",
      type: segment.segmentType || "",
    })) as AudienceOption[];

    setAllAudiences((prev) => {
      const uniqueAudiences = new Map(
        (currentPage === 0 ? newAudiences : [...prev, ...newAudiences]).map(
          (audience) => [audience.id, audience]
        )
      );

      return Array.from(uniqueAudiences.values());
    });
    if (audienceResponse?.totalElements !== undefined) {
      setHasMoreResults(
        content.length === PAGE_SIZE &&
          audienceResponse.totalElements > (currentPage + 1) * PAGE_SIZE
      );
    }

    setIsLoadingMore(false);
  }, [audienceResponse, currentPage, isTinyEmail]);

  React.useEffect(() => {
    if (!isTinyAlbert || !albertAudiences) return;

    const allAlbertAudiences = (albertAudiences.audiences || [])
      .filter((aud) => aud.audienceType === "FORM")
      .map((audience: AlbertAudience) => ({
        id: audience.id || "",
        name: audience.name || "",
        type: audience.audienceType || "",
        numberOfCustomers: audience.numberOfCustomers,
      })) as AudienceOption[];

    setAllAudiences(allAlbertAudiences);
    if (searchDebounced) {
      const filtered = allAlbertAudiences.filter((audience) =>
        audience.name.toLowerCase().includes(searchDebounced.toLowerCase())
      );
      setFilteredAlbertAudiences(filtered);
    } else {
      setFilteredAlbertAudiences(allAlbertAudiences);
    }
  }, [albertAudiences, searchDebounced, isTinyAlbert]);

  React.useEffect(() => {
    if (isTinyEmail) {
      setCurrentPage(0);
      setHasMoreResults(true);
    }
  }, [searchDebounced, isTinyEmail]);

  React.useEffect(() => {
    if (
      isTinyEmail &&
      selectedAudiences?.segments &&
      selectedAudiences.segments.length > 0
    ) {
      setValue(
        "audiences",
        selectedAudiences.segments.map((segment: any) => ({
          id: segment.id || "",
          name: segment.name || "",
          type: segment.type || "",
        })) as AudienceOption[]
      );
    } else if (
      isTinyAlbert &&
      node?.data?.audiences &&
      node?.data?.audiences?.length > 0 &&
      albertAudiences?.audiences
    ) {
      const selectedAudienceIds =
        node?.data?.audiences.map((aud: any) => aud.id) || [];
      const selectedAlbertAudiences = (albertAudiences.audiences || [])
        .filter((audience: AlbertAudience) =>
          selectedAudienceIds.includes(audience.id)
        )
        .map((audience: AlbertAudience) => ({
          id: audience.id,
          name: audience.name,
          type: audience.audienceType || "",
          numberOfCustomers: audience.numberOfCustomers,
        }));
      setValue("audiences", selectedAlbertAudiences as AudienceOption[]);
    }
  }, [
    selectedAudiences,
    albertAudiences,
    node,
    setValue,
    isTinyEmail,
    isTinyAlbert,
  ]);

  const handleAudienceSearch = (searchText: string) => {
    setSearchAudienceText(searchText);

    if (isTinyAlbert && allAudiences.length) {
      const filtered = allAudiences.filter((audience) =>
        audience.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredAlbertAudiences(filtered);
    }
  };

  const loadMoreAudiences = () => {
    if (isTinyEmail && hasMoreResults && !isLoadingMore && !isSegmentLoading) {
      setIsLoadingMore(true);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goBack = () => {
    setWorkflowData((prev) => ({
      ...prev,
      triggerType: undefined,
    }));
    updateNode({ id, data: {} });
  };

  const onSubmit = handleSubmit(async (data) => {
    let nodeData = {
      ruleType: data.ruleType,
      source: data.source,
      event: data?.event,
    };
    if (data.ruleType === "assigned-audience") {
      nodeData = {
        ...nodeData,
        event: "CUSTOMER_ASSIGNED_TO_SEGMENT",
        source: isTinyEmail ? "TINYEMAIL" : "TINYALBERT",
      };
    }
    updateNode({
      id,
      data: {
        ...nodeData,
        audiences: data.audiences?.map((audience) => ({
          id: audience.id,
        })),
        config: {
          configured: true,
        },
      },
    });
    hideDrawer();
  });

  React.useEffect(() => {
    const nodeAudienceIds =
      node?.data?.audiences?.map((aud: any) => aud.id) || [];
    const formAudienceIds = formValues.audiences?.map((aud) => aud.id) || [];

    const audiencesChanged =
      nodeAudienceIds.length !== formAudienceIds.length ||
      formAudienceIds.some((id) => !nodeAudienceIds.includes(id || ""));

    const otherValuesChanged =
      formValues.ruleType !== (node?.data?.ruleType || "") ||
      formValues.source !== (node?.data?.source || "") ||
      formValues.event !== (node?.data?.event || "");

    setSaveRulesDisabled(!(audiencesChanged || otherValuesChanged));
  }, [node, formValues]);

  return {
    methods,
    control,
    formValues,
    goBack,
    onSubmit,
    hideDrawer,
    saveRulesDisabled,
    viewMode,
    audiences: isTinyAlbert ? filteredAlbertAudiences : allAudiences,
    handleAudienceSearch,
    loadMoreAudiences,
    isLoadingMore,
    hasMoreResults: isTinyEmail ? hasMoreResults : false,
    isLoading: isTinyEmail ? isSegmentLoading : isAlbertLoading,
    isTinyEmail,
    isTinyAlbert,
  };
};

export default useSelectRules;
