import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const { env } = import.meta;

interface VisitorInfoProps {
  visitorInfo: {
    name: string;
    email: string;
    contactnumber: string;
  };
}

const ZohoSalesIQ: React.FC<VisitorInfoProps> = ({ visitorInfo }) => {
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const zohoObject = (window as any).$zoho || {};

    if (Object.keys(zohoObject)?.length === 0) {
      const script = document.createElement("script");
      script.src = "https://salesiq.zohopublic.com/widget";
      script.async = true;
      script.id = "zsiqscript";
      script.defer = true;
      (window as any).$zoho = {
        salesiq: {
          widgetcode: import.meta.env?.VITE_APP_ZOHO_WIDGET_KEY,
          values: {},
          ready: () => {
            zohoObject.salesiq.visitor.name(visitorInfo.name);
            zohoObject.salesiq.visitor.email(visitorInfo.email);
            zohoObject.salesiq.visitor.contactnumber(visitorInfo.contactnumber);
            setInitialized(true);
          },
        },
        ...zohoObject,
      };

      document.head.appendChild(script);
    } else {
      setInitialized(true);
    }

    return () => {
      const scriptElement = document.getElementById("zsiqscript");
      if (scriptElement) {
        document.head.removeChild(scriptElement);
      }
    };
  }, [visitorInfo.contactnumber, visitorInfo.email, visitorInfo.name]);

  useEffect(() => {
    if (!initialized) return;

    const zohoObject = (window as any).$zoho;
    if (!zohoObject?.salesiq?.floatbutton) return;

    console.log(
      "inintisi",
      initialized,
      location.pathname?.includes("/workflow/")
    );
    if (
      location.pathname?.includes("/campaign/") ||
      location.pathname?.includes("/workflow/")
    ) {
      zohoObject.salesiq.floatbutton.visible("hide");
    } else {
      zohoObject.salesiq.floatbutton.visible("show");
    }
  }, [location.pathname, initialized]);

  return null;
};

export default ZohoSalesIQ;
