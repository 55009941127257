import React from "react";
import { Box, Typography } from "@mui/material";
import { PageTitleProps } from "./types";

function PageTitle(props: PageTitleProps) {
  const { title, subTitle, sx } = props;
  return (
    <Box
      id="page-title"
      display="flex"
      justifyContent="flex-start"
      flexDirection="column"
      mb={{ xs: 5, sm: 6 }}
      mt={0}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h2"
          m={0}
          color="grey.100"
          fontWeight={"bold"}
          noWrap
        >
          {title}
        </Typography>
      </Box>
      {subTitle && (
        <Typography color="grey.70" variant="p3" display="block" mt={1}>
          {subTitle}
        </Typography>
      )}
    </Box>
  );
}

export default PageTitle;
