import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import {
  CampaignResponseView,
  WorkflowEmailTemplateView,
} from "../../../CampaignPage.types";
import { TruncateTooltip } from "../../../../../../components";

interface DetailsViewProps {
  details: WorkflowEmailTemplateView | undefined;
}

function DetailsView(props: DetailsViewProps) {
  const { details } = props;

  return (
    <>
      <Divider />
      <Box mt={6} display="flex" flexDirection="column">
        <Box display="flex" gap={20} alignItems="center" mb={4}>
          <Box flex="0 0 150px">
            <Typography variant="label1" component="div" color="grey">
              Email name
            </Typography>
          </Box>
          <Box minWidth={0}>
            <TruncateTooltip>
              <Typography
                variant="label1"
                fontWeight="bold"
                component="div"
                color="grey"
                noWrap
              >
                {details?.name}
              </Typography>
            </TruncateTooltip>
          </Box>
        </Box>
        <Box display="flex" gap={20} alignItems="center" mb={4}>
          <Box flex="0 0 150px">
            <Typography variant="label1" component="div" color="grey">
              Subject
            </Typography>
          </Box>
          <Box minWidth={0}>
            <TruncateTooltip>
              <Typography
                variant="label1"
                fontWeight="bold"
                component="div"
                color="grey"
                noWrap
              >
                {details?.subject}
              </Typography>
            </TruncateTooltip>
          </Box>
        </Box>
        {details?.previewText && (
          <Box display="flex" gap={20} alignItems="center">
            <Box flex="0 0 150px">
              <Typography variant="label1" component="div" color="grey">
                Preview text
              </Typography>
            </Box>
            <Box minWidth={0}>
              <TruncateTooltip>
                <Typography
                  variant="label1"
                  fontWeight="bold"
                  component="div"
                  color="grey"
                  noWrap
                >
                  {details?.previewText}
                </Typography>
              </TruncateTooltip>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

export default DetailsView;
