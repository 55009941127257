import {
  findNextNode,
  findPreviousNode,
  getImmediateChildNodes,
  getLinearChildrenNodes,
  getTriggerNode,
} from "../WorkflowBuilderPage.utils";
import { GraphStructure } from "./types";
import { Node } from "./types";

const areAllNodesConfigured = (
  structure: GraphStructure
): { isValid: boolean; notConfiguredNodes: Node[] } => {
  let isValid = true;
  let notConfiguredNodes = [];
  for (let node of structure.nodes) {
    if (!node?.data?.config?.configured) {
      isValid = false;
      notConfiguredNodes.push(node);
    }
  }
  return { isValid: isValid, notConfiguredNodes: notConfiguredNodes };
};

const atleastOneActionNodePresent = (structure: GraphStructure): boolean => {
  const triggerNode = getTriggerNode(structure);

  return triggerNode ? dfsValidate(triggerNode, new Set(), structure) : false;
};

const getNodeCountsByType = (
  structure: GraphStructure,
  type: Node["type"]
): number => {
  const { nodes } = structure;
  const nodeCount = nodes.filter((n) => n.type === type);
  return nodeCount?.length;
};
const dfsValidate = (
  node: Node,
  visitedNodes: Set<string>,
  structure: GraphStructure
): boolean => {
  if (visitedNodes.has(node.id)) {
    return false;
  }

  if (node.type === "EXIT") {
    return false;
  }

  visitedNodes.add(node.id);

  if (node.type === "ACTION") {
    const childNodes = getImmediateChildNodes(node, structure);
    for (const child of childNodes) {
      if (child) {
        if (child.type === "EXIT") {
          return true;
        }
        return dfsValidate(child, new Set(visitedNodes), structure);
      }
    }
    return false;
  } else if (node.type === "LOGIC") {
    const childNodes = getImmediateChildNodes(node, structure);
    if (childNodes[0] && childNodes[1]) {
      const isLeftSideEmpty = childNodes[0].type === "EXIT";
      const isRightSideEmpty = childNodes[1].type === "EXIT";
      if (isLeftSideEmpty || isRightSideEmpty) {
        let isAnyChildValid = false;

        for (const child of childNodes) {
          if (child) {
            const isChildValid: boolean = dfsValidate(
              child,
              new Set(visitedNodes),
              structure
            );
            isAnyChildValid = isAnyChildValid || isChildValid;
          }
        }

        return isAnyChildValid;
      } else {
        let areAllChildrenValid = true;
        for (const child of childNodes) {
          if (child) {
            const isChildValid = dfsValidate(
              child,
              new Set(visitedNodes),
              structure
            );
            areAllChildrenValid = areAllChildrenValid && isChildValid;
          }
        }
        return areAllChildrenValid;
      }
    }
    return false;
  } else {
    // Check the child nodes
    const childNodes = getImmediateChildNodes(node, structure);
    for (const child of childNodes) {
      if (child) {
        return dfsValidate(child, new Set(visitedNodes), structure);
      }
    }
    return false;
  }
};

const isSubsequentDelayPreset = (structure: GraphStructure) => {
  const { nodes } = structure;
  let isSubsequentDelay: boolean = true;
  nodes.map((node) => {
    if (node.type === "TIMING") {
      const nextNode = findNextNode(node.id, structure);
      const prevNode = findPreviousNode(node.id, structure);

      if (nextNode?.type === "TIMING" || prevNode?.type === "TIMING") {
        isSubsequentDelay = false;
      }
    }
  });
  return isSubsequentDelay;
};

const isSubsequentActionPreset = (structure: GraphStructure) => {
  const { nodes } = structure;
  let isSubsequentDelay: boolean = true;
  nodes.map((node) => {
    if (node.type === "ACTION") {
      const nextNode = findNextNode(node.id, structure);
      const prevNode = findPreviousNode(node.id, structure);

      if (nextNode?.type === "ACTION" || prevNode?.type === "ACTION") {
        isSubsequentDelay = false;
      }
    }
  });
  return isSubsequentDelay;
};

const isAllConditionalSplitValid = (structure: GraphStructure) => {
  const { nodes } = structure;
  const isConditionalSplitPresent = nodes.find((n) => n.type === "LOGIC");
  if (isConditionalSplitPresent) {
    const triggerNode = getTriggerNode(structure);
    const linearChildrenNodes = getLinearChildrenNodes(
      triggerNode?.id ?? "1",
      structure
    );
    return !!linearChildrenNodes.find((node) => node?.type === "TIMING");
  } else {
    return true;
  }
};

const isMoreThanFiveConditionalNode = (structure: GraphStructure) => {
  return !(getNodeCountsByType(structure, "LOGIC") > 5);
};

const isTimerAfterActionNode = (structure: GraphStructure): boolean => {
  const { nodes } = structure;
  let isValid = true;
  nodes.forEach((node) => {
    if (node.type === "ACTION") {
      const nextNode = findNextNode(node.id, structure);
      if (nextNode && nextNode.type !== "TIMING" && nextNode.type !== "EXIT") {
        isValid = false;
      }
    }
  });
  return isValid;
};

// Temporary rule
const isLogicNodePresentInAssignToAudience = (
  structure: GraphStructure
): boolean => {
  const triggerNode = getTriggerNode(structure);
  if (!triggerNode) {
    return true;
  }

  if (triggerNode.data?.event === "CUSTOMER_ASSIGNED_TO_SEGMENT") {
    const logicNodeCount = getNodeCountsByType(structure, "LOGIC");
    return logicNodeCount === 0;
  }
  return true;
};

export {
  areAllNodesConfigured,
  atleastOneActionNodePresent,
  isSubsequentDelayPreset,
  isAllConditionalSplitValid,
  isSubsequentActionPreset,
  isMoreThanFiveConditionalNode,
  isTimerAfterActionNode,
  isLogicNodePresentInAssignToAudience,
};
