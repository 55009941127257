import React from "react";
import { Container, Box, ThemeProvider } from "@mui/material";
import { createTinyTheme as createAlbertTheme } from "@tiny/theme";
import { createTinyTheme as createEmailTheme } from "@tiny/email-theme";
import { WorkflowsBuilderPage, WorkflowsPage } from "./pages";
import { useConfig } from "./Config.hooks";
import { ConfigProvider } from "./Config.context";
import { TinyWorkflowsProps } from "./App.types";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ErrorBoundary from "./customComponents/ErrorBoundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { initializeApi } from "./app/apiInstance";
import { LoadingSnackbarProvider } from "./components/LoadingSnackbar/LoadingSnackbar.context";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import {
  extractBackendError,
  extractErrorMessage,
  getErrorMessage,
  isManagedError,
} from "./utils/errorUtils";
import { TinyDialogProvider } from "@tiny/lib";

const App = ({ config: importedConfig }: TinyWorkflowsProps) => {
  const { config, setConfig } = useConfig();
  const albertTheme = createAlbertTheme();
  const emailTheme = createEmailTheme();

  const [apiInitialized, setApiInitialized] = React.useState(false);

  React.useEffect(() => {
    initializeApi(importedConfig.baseUrl);
    setApiInitialized(true);
  }, [importedConfig.baseUrl]);

  if (!apiInitialized) {
    return null;
  }

  return (
    <AppQueryClientProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ConfigProvider
          value={{
            config: {
              ...config,
              ...importedConfig,
            },
            setConfig,
          }}
        >
          <ThemeProvider
            theme={
              importedConfig.appName === "tinyEmail" ? emailTheme : albertTheme
            }
          >
            <TinyDialogProvider>
              <SnackbarProvider>
                <LoadingSnackbarProvider>
                  <ErrorBoundary>
                    {importedConfig.page === "LANDING" ? (
                      <WorkflowsPage />
                    ) : (
                      <WorkflowsBuilderPage />
                    )}
                  </ErrorBoundary>
                </LoadingSnackbarProvider>
              </SnackbarProvider>
            </TinyDialogProvider>
          </ThemeProvider>
        </ConfigProvider>
      </LocalizationProvider>
    </AppQueryClientProvider>
  );
};
export default App;

function AppQueryClientProvider({ children }: { children: React.ReactNode }) {
  const onError = React.useCallback(
    (e: any) => {
      extractBackendError(e.response).then((be) => {
        const errorCodes = be?.errorCodes;
        if (!isManagedError(errorCodes)) {
          enqueueSnackbar(extractErrorMessage(be) ?? getErrorMessage(be), {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 10000,
          });
        }
      });
    },
    [enqueueSnackbar]
  );

  const queryClient = React.useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: Infinity,
          cacheTime: 0,
          retry: false,
          refetchOnWindowFocus: false,
          onError,
        },
        mutations: {
          onError,
        },
      },
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient} {...({} as any)}>
      {children}
    </QueryClientProvider>
  );
}
