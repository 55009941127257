import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { Form, FormWatch, TextControl } from "../../../../../../components";
import { validateCampaignName } from "../../../../../../utils/validateUtils";
import { useCampaignPageView } from "../../../CampaignPage.context";
import BestPractices from "./BestPractices";
import { CampaignCreateRequestView } from "../../../CampaignPage.types";

interface DetailsFormProps {
  form: UseFormReturn<CampaignCreateRequestView, object>;
  onSubmit: (values: CampaignCreateRequestView) => Promise<void>;
}

function DetailsForm(props: DetailsFormProps) {
  const { form, onSubmit } = props;

  const { title, details } = useCampaignPageView();

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Grid container spacing={10}>
        <Grid item xs={7}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <TextControl
                name="name"
                defaultValue={details?.name || title}
                id="email-name"
                label="Email name"
                required
                maxLength={256}
                validate={validateCampaignName}
              />
            </Grid>
            <Grid item xs={12}>
              <FormWatch name="subject">
                {(subject = details?.subject) => {
                  const maxLength = 150;
                  return (
                    <TextControl
                      name="subject"
                      defaultValue={details?.subject || ""}
                      id="campaign-subject"
                      label="Subject"
                      placeholder="Example here"
                      required
                      maxLength={maxLength}
                      sx={{}}
                    />
                  );
                }}
              </FormWatch>
            </Grid>
            <Grid item xs={12}>
              <FormWatch name="previewText">
                {(previewText = details?.previewText) => {
                  const maxLength = 150;
                  return (
                    <TextControl
                      name="previewText"
                      id="campaign-preview"
                      defaultValue={details?.previewText || ""}
                      label="Preview text (Optional)"
                      placeholder="Example here"
                      maxLength={maxLength}
                    />
                  );
                }}
              </FormWatch>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <FormWatch name="subject">
            {(subject: string) => <BestPractices text={subject || ""} />}
          </FormWatch>
        </Grid>
      </Grid>
      <button hidden type="submit">
        submit
      </button>
    </Form>
  );
}

export default DetailsForm;
