import React from "react";
import { AppConfig } from "./config.types";

export function useConfig() {
  const [config, setConfig] = React.useState<AppConfig>();
  return {
    config,
    setConfig,
  };
}
