import React from "react";
import { useDrawerInvokers } from "@tiny/lib";
import { useWorkflowsBuilderPageView } from "../../../pages/WorkflowsBuilderPage/WorkflowsBuilderPage.context";
import { useTriggerFormView } from "../TiggerForm.context";

const useSelectTrigger = () => {
  const { triggers } = useWorkflowsBuilderPageView();
  const { hideDrawer } = useDrawerInvokers();

  return { triggers, hideDrawer };
};

export default useSelectTrigger;
