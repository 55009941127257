import { AutomationsOutline, Clock, Trigger } from "@tiny/icons";
import { Typography } from "@mui/material";

export const sidebarItems = [
  {
    name: (
      <Typography
        variant="label1"
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          textTransform: "uppercase",
          color: theme.palette.purple[150],
        })}
      >
        <Trigger
          sx={(theme) => ({
            fontSize: 20,
            mr: 1,
            color: theme.palette.purple[150],
          })}
        />
        ACTIONS
      </Typography>
    ),
    items: [
      {
        id: 1,
        label: "Email",
        type: "ACTION",
        data: {},
        icon: null,
        active: true,
      },
      {
        id: 2,
        label: "SMS",
        type: "ACTION",
        data: {},
        icon: null,
        active: false,
      },
      {
        id: 3,
        label: "Webhook",
        type: "ACTION",
        data: {},
        icon: null,
        active: false,
      },
    ],
  },
  {
    name: (
      <Typography
        variant="label1"
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          textTransform: "uppercase",
          color: theme.palette.yellow[150],
        })}
      >
        <Clock
          sx={(theme) => ({
            fontSize: 20,
            mr: 1,
            color: theme.palette.yellow[150],
          })}
        />
        TIMING
      </Typography>
    ),
    items: [
      {
        id: 1,
        label: "Time Delay",
        type: "TIMING",
        data: {},
        icon: null,
        active: true,
      },
    ],
  },
  {
    name: (
      <Typography
        variant="label1"
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          textTransform: "uppercase",
          color: theme.palette.fuchsia[150],
        })}
      >
        <AutomationsOutline
          sx={(theme) => ({
            fontSize: 22,
            mr: 1,
            color: theme.palette.fuchsia[150],
          })}
        />
        LOGIC
      </Typography>
    ),
    items: [
      {
        id: 1,
        label: "Conditional Split",
        type: "LOGIC",
        data: {},
        icon: null,
        active: true,
      },
    ],
  },
];
