import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";

import { WorkflowCardProps } from "../WorkflowCards.types";
import { CalendarCheck, Info } from "@tiny/icons";

function WorkflowTemplateCard({
  name,
  description,
  onActionClick,
  infoDescription,
  imagePath,
  status,
}: WorkflowCardProps) {
  const isComingSoon = status === "COMING_SOON";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        px: 6,
        py: 4,
        borderRadius: 2,
        bgcolor: "white",
      }}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" flexDirection="column" gap={2}>
          {imagePath && (
            <img
              src={imagePath}
              alt={name}
              height={60}
              width={60}
              style={{ borderRadius: 8 }}
            />
          )}
          <Typography variant="h4" color="grey.900" fontWeight={600} my={1}>
            {name}
          </Typography>
        </Box>
        <Tooltip
          title={
            <Box p={4}>
              <Typography variant="label3R">{infoDescription}</Typography>
            </Box>
          }
        >
          <Info sx={{ color: "grey.700" }} />
        </Tooltip>
      </Box>
      <Typography variant="label1R" color="grey.700" lineHeight={1.2}>
        {description}
      </Typography>
      {isComingSoon ? (
        <Typography
          variant="p2"
          color="primary.main"
          mt={4}
          sx={{ opacity: 0.8, display: "flex", alignItems: "flex-end" }}
        >
          <CalendarCheck />
          &nbsp;Coming soon
        </Typography>
      ) : (
        <Typography
          variant="p2"
          color="primary.main"
          mt={4}
          sx={{ cursor: "pointer" }}
          onClick={onActionClick}
        >
          Select ›
        </Typography>
      )}
    </Box>
  );
}

export default WorkflowTemplateCard;
