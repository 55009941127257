import React from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  SenderDetailsIdentityView,
  SenderDetailsView,
} from "../../../../../app/GeneratedApi";
import useDialog from "../../../../../hooks/useDialog";
import { useRefCallBack } from "../../../../../hooks/useRefCallBack";
import useSenderDetailsFeatures from "../../../../../hooks/useSenderDetailsFeatures";
import { isManagedError } from "../../../../../utils/errorUtils";
import { useCampaignPageView } from "../../CampaignPage.context";
import { useConfigView } from "../../../../../Config.context";

export function useSenderPanel() {
  const { setTotalSenders, message: senderFeatureMessage } =
    useSenderDetailsFeatures();

  const {
    details,
    assignSender,
    activeSenderDetails,
    reloadSenderDetails,
    senderDetails,
    notifyRequireSave,
  } = useCampaignPageView();

  const [editing, setEditing] = React.useState(false);
  const [assignSenderError, setAssignSenderError] = React.useState<string>();

  const senderDetailsId = details?.senderDetailsId || "";
  const sender = senderDetailsId
    ? senderDetails?.find((s: any) => s.id === senderDetailsId)
    : activeSenderDetails;

  const form = useForm<{ email: string; sender: SenderDetailsView }>({
    defaultValues: {
      email: sender?.email,
      sender: sender,
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { control, setValue } = form;

  const formValues = useWatch({ control });

  const submitForm = React.useCallback(
    async (values: { sender: SenderDetailsView }) => {
      if (values.sender.emailConfirmed) {
        assignSenderError && setAssignSenderError(undefined);
        await assignSender({
          senderDetailsId: values.sender.id ?? "",
        });
        setEditing(false);
        notifyRequireSave({ name: "assignSender", fn: undefined });
      } else {
        setAssignSenderError("Email is not confirmed");
      }
    },
    [assignSender, assignSenderError, notifyRequireSave]
  );

  const assign = useRefCallBack(
    React.useCallback(async () => {
      form.handleSubmit(submitForm)();
    }, [form, submitForm])
  );

  const edit = React.useCallback(() => {
    const senderDetailsId = details?.senderDetailsId || "";
    const sender = senderDetailsId
      ? senderDetails?.find((s: any) => s.id === senderDetailsId)
      : activeSenderDetails;
    form.reset({ sender, email: sender?.email });
    setEditing(true);
    notifyRequireSave({ name: "assignSender", fn: assign });
  }, [
    activeSenderDetails,
    assign,
    form,
    notifyRequireSave,
    senderDetails,
    details?.id,
  ]);

  const editDisabled = editing ? !formValues.email : !details?.subject;

  const closeButtonLabel = details?.senderDetailsId ? "Edit" : "Add";

  React.useLayoutEffect(() => {
    reloadSenderDetails();
  }, [reloadSenderDetails]);

  React.useEffect(() => {
    setTotalSenders(senderDetails?.length);
  }, [senderDetails?.length, setTotalSenders]);

  return {
    control,
    editing,
    edit,
    editDisabled,
    assign,
    form,
    assignSenderError,
    closeButtonLabel,
    submitForm,
    formValues,
    setValue,
    senderFeatureMessage,
  };
}
