import React from "react";
import { Box, CircularProgress, Container } from "@mui/material";
import { useWorkflowsBuilderPageView } from "../WorkflowsBuilderPage.context";
import { CampaignPageProvider } from "./CampaignPage.context";
import { useCampaignPage } from "./CampaignPage.hooks";
import CampaignHeader from "./CampaignHeader";
import TemplatePreviewDialog from "../../../customComponents/TemplatePreviewDialog";
import SendTestEmailDialog from "../../../customComponents/SendTestEmailDialog";
import DetailsStep from "./DetailsStep";
import DesignStep from "./DesignStep";
import { useGetEditorTokenQuery } from "../../../app/useGeneratedApi";
import { StripoProvider } from "@tiny/stripo-editor";
import { getStripoAuthToken } from "@tiny/utils/api/stripo";
import { useConfigView } from "../../../Config.context";

const CampaignBuilder = () => {
  const { campaignBuilderOpen, currentNodeId, viewMode } =
    useWorkflowsBuilderPageView();
  if (!campaignBuilderOpen) {
    return <></>;
  }

  if (viewMode) {
    return <ViewModePreviewDialog id={currentNodeId || ""} />;
  }

  return <CampaignBuilderContent id={currentNodeId || ""} />;
};

export default CampaignBuilder;

const ViewModePreviewDialog = ({ id }: { id: string }) => {
  const { closeCampaignBuilder } = useWorkflowsBuilderPageView();
  const campaignPageView = useCampaignPage({ id: id });
  const [showPreview, setShowPreview] = React.useState(true);
  const { details, emailMessage, templateHtml, templateCss } = campaignPageView;

  const { attributes, templateData, campaignTemplate, templateType } =
    emailMessage || {};

  const handleClose = () => {
    closeCampaignBuilder();
  };

  return (
    <TemplatePreviewDialog
      campaignId={id}
      mainActionName="Close"
      onClose={handleClose}
      onMainActionClick={handleClose}
      open={showPreview}
      templateAttributes={attributes}
      renderWithEjs={templateType === "CUSTOM"}
      templateData={templateData}
      templateHtml={templateHtml || campaignTemplate?.html}
      templateCss={templateCss || campaignTemplate?.css}
      templateName={details?.name}
      subjectText={details?.subject}
      previewText={details?.previewText}
    />
  );
};

const CampaignBuilderContent = ({ id }: { id: string }) => {
  const campaignPageView = useCampaignPage({ id: id });
  const {
    details,
    activeStep,
    activityId,
    campaignId,
    closePreviewDialog,
    isCampaignLoading,
    closeSendEmailTest,
    emailMessage,
    previewDialogOpen,
    sendEmailTestOpen,
    templateHtml,
    templateCss,
    id: workflowTemplateID,
  } = campaignPageView;
  const { campaignTemplate, templateData, attributes, templateType } =
    emailMessage;

  const { config } = useConfigView();

  const stripoTokenQuery = useGetEditorTokenQuery({
    enabled: config?.appName === "tinyEmail",
  });

  const getAuthToken = React.useCallback(() => {
    return stripoTokenQuery.refetch().then((d) => d.data?.token) || "";
  }, [stripoTokenQuery]);

  const authTokenCall =
    config?.appName === "tinyEmail"
      ? getAuthToken
      : getStripoAuthToken({
          userId: config?.accountId,
        });

  return (
    <CampaignPageProvider value={campaignPageView}>
      <StripoProvider
        value={{
          accountId: config?.accountId || "12344",
          emailId: workflowTemplateID || "12344",
          userFullName: "Test",
          getAuthToken: authTokenCall,
          templateState: {
            html: templateHtml,
            css: templateCss,
          },
          updateTemplateState: () => {},
        }}
      >
        <Container
          sx={(theme) => ({
            position: "fixed",
            m: 0,
            p: 0,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: theme.palette.grey[20],
            minWidth: "100vw",
            minHeight: "100vh",
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          })}
          disableGutters
        >
          <CampaignHeader />
          {isCampaignLoading ? (
            <Box
              sx={{
                flex: "1 1 auto",
                background: "transparent",
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100vh - 72px)",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                flex: "1 1 auto",
                overflowY: "auto",
                overflowX: "hidden",
                width: "100vw",
                height: "calc(100vh - 72px)",
              }}
            >
              {activeStep === "details" && <DetailsStep />}
              {activeStep === "design" && <DesignStep />}
            </Box>
          )}
          {previewDialogOpen && (
            <TemplatePreviewDialog
              campaignId={campaignId}
              mainActionName="Close"
              onClose={closePreviewDialog}
              onMainActionClick={closePreviewDialog}
              open={previewDialogOpen}
              templateAttributes={attributes}
              renderWithEjs={templateType === "CUSTOM"}
              templateData={templateData}
              templateHtml={templateHtml || campaignTemplate?.html}
              templateCss={templateCss || campaignTemplate?.css}
              templateName={details?.name}
            />
          )}
          {sendEmailTestOpen && (
            <SendTestEmailDialog
              workflowTemplateId={workflowTemplateID}
              onClose={closeSendEmailTest}
              open={sendEmailTestOpen}
            />
          )}
        </Container>
      </StripoProvider>
    </CampaignPageProvider>
  );
};
