import React from "react";
import useBreakpoints from "../../hooks/useBreakpoints";
import { useWorkflowsPage } from "./WorkflowsPage.hooks";
import { PageTabItem, workflowsNav } from "./WorkflowsPage.helpers";
import { WorkflowsNav } from "./WorkflowsPage.types";
import { NavItem } from "../../components/PageTabs/types";
import PageTitle from "../../components/PageTitle";
import PageTabs from "../../components/PageTabs";
import WorkflowList from "../../customComponents/WorkflowList";
import { Box, Typography } from "@mui/material";
import WorkflowTemplateSelection from "../../customComponents/WorkflowTemplateSelection/WorkflowTemplateSelection";

function WorkflowsPage() {
  const { mdUp } = useBreakpoints();

  const {
    activeNavItem,
    isWorkflowsLoading,
    onNavChange,
    workflowStatuses,
    numberOfWorkflows,
  } = useWorkflowsPage();

  const nav =
    numberOfWorkflows >= 1
      ? workflowsNav
      : isWorkflowsLoading
      ? [workflowsNav[0]]
      : undefined;

  const tabValue = nav
    ? nav.findIndex((item) => item.name === activeNavItem)
    : 0;

  const changeTab = (value: number) => {
    if (nav) {
      onNavChange(nav[value].name);
    }
  };

  return (
    <div>
      <Box display={"flex"} justifyContent={"space-between"}>
        <PageTitle title="Workflows" />
      </Box>
      <Box mb={8}>
        <WorkflowTemplateSelection />
      </Box>
      {numberOfWorkflows && nav ? (
        <>
          <PageTabs tabValue={tabValue} changeTab={changeTab} nav={nav} />
          <WorkflowList
            pageName="Workflows"
            bgcolor="white"
            pt={mdUp ? 4 : 3}
            pr={mdUp ? 6 : 4}
            pb={mdUp ? 4 : 3}
            pl={mdUp ? 6 : 4}
            mt={4}
            borderRadius={mdUp ? 2 : 0}
            workflowStatuses={workflowStatuses}
            showChip
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default WorkflowsPage;
