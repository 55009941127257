import React from "react";
import { QuitWorkflowsView } from "./QuitWorkflows.types";
import { useConfigView } from "../../../../Config.context";
import { useWorkflowsBuilderPageView } from "../../WorkflowsBuilderPage.context";

export function useQuitWorkflows(): QuitWorkflowsView {
  const {
    quitWorkflowsVisible,
    hideQuitWorkflows,
    workflowData,
    saveWorkflow,
  } = useWorkflowsBuilderPageView();
  const { config } = useConfigView();

  const quit = React.useCallback(() => {
    if (config?.quitApp) {
      config.quitApp();
    }
    return;
  }, []);

  const quitWithoutSave = React.useCallback(() => {
    hideQuitWorkflows();
    quit();
  }, [hideQuitWorkflows, quit]);

  const quitWithSave = React.useCallback(async () => {
    saveWorkflow(workflowData);
    hideQuitWorkflows();
    quit();
  }, [hideQuitWorkflows, workflowData]);

  return {
    quitWorkflowsVisible,
    hideQuitWorkflows,
    quitWithoutSave,
    quitWithSave,
  };
}
