import React, { ChangeEvent } from "react";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  Autocomplete,
  Chip,
} from "@mui/material";

import { ChevronBigDown } from "@tiny/icons";
import { AutocompleteFieldProps } from "./AutocompleteField.types";
import { extractAutocompleteProps } from "./Autocomplete.helpers";
import useRandom from "../../hooks/useRandom";
import TextFieldBase from "../TextFieldBase";

function AutocompleteField(props: AutocompleteFieldProps): JSX.Element {
  const {
    onChangeValue,
    inputAutoComplete,
    inputAriaLabel,
    disableInputChange,
    disabled,
    size,
    info,
    infoIcon,
    toolTipModifiers,
    getOptionLabel,
    multiple,
    ...other
  } = props;

  const autoCompleteRandom = useRandom();

  const [{ onChange, ...autocompleteProps }, textFieldProps] =
    //@ts-ignore
    extractAutocompleteProps(other);

  const handleChange = (
    event: ChangeEvent<{ [key: string]: any }>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => {
    if (onChange) {
      //  @ts-ignore
      onChange(event, value, reason, details);
    }
    if (onChangeValue) {
      onChangeValue(value);
    }
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const {
      InputProps: { ref, className, endAdornment, ...InputPropsOther },
      ...otherInputProps
    } = params;

    const inputProps = {
      ...otherInputProps.inputProps,
      autoComplete: inputAutoComplete || autoCompleteRandom,
      ["aria-label"]: inputAriaLabel,
      readOnly: disableInputChange,
    };

    return (
      <TextFieldBase
        {...textFieldProps}
        {...otherInputProps}
        sx={(theme) => ({
          textfield: {
            "& .MuiAutocomplete-clearIndicator": {
              padding: "6px",
              color: theme.palette.grey[600],
            },
            "& .MuiAutocomplete-clearIndicator svg": {
              width: 16,
              height: 16,
            },
            "& .MuiAutocomplete-endAdornment": {
              paddingRight: 12,
            },
            "& .MuiInputBase-root": {
              paddingRight: 36,
            },
            "& fieldset": {
              boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1) !important",
            },
          },
          textfieldSmall: {
            "& .MuiAutocomplete-endAdornment": {
              paddingRight: 8,
            },
          },
          textfieldDisabled: {
            "& fieldset": {
              boxShadow: "none !important",
            },
          },
          textfieldInfo: {
            "& .MuiAutocomplete-endAdornment": {
              paddingRight: 40,
            },
          },
          textfieldMultiple: {
            "& .MuiInputBase-root": {
              flexWrap: "wrap",
              paddingTop: 3,
              paddingLeft: 4,
              paddingBottom: 3,
              paddingRight: 68,
              minHeight: 40,
              height: "auto",
            },
            "& .MuiAutocomplete-input": {
              width: 0,
              margin: 0,
              padding: "0 0 0 4px",
              height: 20,
            },
            "& .MuiAutocomplete-tag": {
              fontSize: 14,
            },
            "&$textfieldSmall .MuiInputBase-root": {
              minHeight: 36,
            },
          },
        })}
        InputProps={{
          ...InputPropsOther,
          endAdornment: <>{endAdornment}</>,
          ref,
        }}
        inputProps={inputProps}
        size={size}
      />
    );
  };

  return (
    <Autocomplete
      {...autocompleteProps}
      disabled={disabled}
      onChange={handleChange}
      renderInput={renderInput}
      size={size}
      multiple={multiple}
      getOptionLabel={getOptionLabel}
      popupIcon={
        <ChevronBigDown
          sx={(theme) => ({
            arrow: {
              width: 20,
              height: 20,
              padding: "2px",
              marginTop: "2px",
              cursor: "pointer",
              color: theme.palette.grey[600],
            },
            arrowSmall: {
              width: 20,
              height: 20,
              position: "relative",
            },
            arrowDisabled: {
              cursor: "default",
              color: theme.palette.grey[400],
            },
          })}
        />
      }
      renderTags={(value: any[], getTagProps) =>
        value.map((option: any, index: number) => (
          <Chip
            size={size}
            label={getOptionLabel?.(option)}
            sx={(theme) => ({
              height: 28,
              margin: "2px 2px",
              borderRadius: 4,
              background: theme.palette.grey[200],
              maxWidth: "calc(100% - 36px)",
              "& .MuiChip-deleteIcon": {
                width: 16,
                height: 16,
              },
              sizeSmall: {
                fontSize: 12,
              },
            })}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }
    />
  );
}

export default AutocompleteField;
