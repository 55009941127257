import React from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  InputLabel,
} from "@mui/material";
import { ChevronBigLeft } from "@tiny/icons";
import { Controller, FormProvider } from "react-hook-form";
import useConditionalSplitForm from "./ConditionalSplitForm.hooks";

const ConditionalSplitForm = (props: { id: string }) => {
  const {
    goBack,
    onSubmit,
    methods,
    formValues,
    control,
    saveRulesDisabled,
    viewMode,
  } = useConditionalSplitForm(props);

  const ruleTypes = [
    { value: "shopping-activity", label: "Shopping Activity", active: true },
  ];

  const eventTypes = [
    { value: "CHECKOUT_STARTED", label: "Checkout Started" },
    { value: "ORDER_PLACED", label: "Order Placed" },
    { value: "ORDER_FULFILLED", label: "Order Fulfilled" },
  ];

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        style={{
          height: "100%",
        }}
      >
        <Box
          sx={{
            py: 4,
            px: 6,
            display: "flex",
            flexDirection: "column",
            width: { xs: "400px", xl: "25vw" },
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 2,
              }}
            >
              <ChevronBigLeft
                sx={{
                  color: "primary.125",
                }}
                onClick={() => goBack()}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Typography variant="h3">Conditional split setup</Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              flex: 1,
              display: "flex",
              gap: 8,
              mt: 8,
              flexDirection: "column",
            }}
          >
            {/* Rule Type Selection */}
            <Controller
              name="ruleType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel
                    sx={(theme) => ({
                      backgroundColor: theme.palette.background.paper,
                      px: 2,
                    })}
                    id="activity-select-label"
                    shrink
                  >
                    Activity Type
                  </InputLabel>
                  <Select
                    size="small"
                    fullWidth
                    label="Activity Type"
                    placeholder="Select activity type"
                    displayEmpty
                    renderValue={(item) => {
                      if (!item?.length) return "Select activity type";
                      return ruleTypes.find((type) => type.value === item)
                        ?.label;
                    }}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    {ruleTypes.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        disabled={!option.active}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />

            {formValues.ruleType === "shopping-activity" && (
              <Controller
                name="event"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel
                      sx={(theme) => ({
                        backgroundColor: theme.palette.background.paper,
                        px: 2,
                      })}
                      id="event-select-label"
                      shrink
                    >
                      Event
                    </InputLabel>
                    <Select
                      size="small"
                      fullWidth
                      label="Event"
                      placeholder="Select event"
                      displayEmpty
                      renderValue={(item) => {
                        if (!item?.length) return "Select event";
                        return eventTypes.find((type) => type.value === item)
                          ?.label;
                      }}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    >
                      {eventTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}

            {formValues.event && (
              <Controller
                name="from"
                control={control}
                defaultValue="start-of-workflow"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel
                      sx={(theme) => ({
                        backgroundColor: theme.palette.background.paper,
                        px: 2,
                      })}
                      id="from-select-label"
                      shrink
                    >
                      From
                    </InputLabel>
                    <Select
                      size="small"
                      fullWidth
                      label="From"
                      disabled
                      value={field.value}
                    >
                      <MenuItem value="start-of-workflow">
                        Start of Workflow
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            <Button onClick={goBack}>Cancel</Button>
            <Button
              variant="contained"
              type="submit"
              disabled={saveRulesDisabled || viewMode}
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default ConditionalSplitForm;
