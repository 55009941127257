import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { Node, Edge } from "../types";
import { createNewEdge, createNewNode } from "../Helpers/commons";
import { useWorkflowsBuilderPageView } from "../../WorkflowsBuilderPage.context";

interface DragAndDropHookReturn {
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  handleDropEvent: (event: React.DragEvent<HTMLDivElement>) => void;
}

const useDragAndDrop = (id: string): DragAndDropHookReturn => {
  const { workflowData, setGraphData } = useWorkflowsBuilderPageView();
  const { nodes, edges } = workflowData.structure;

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const handleDropEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    try {
      const droppedData = JSON.parse(
        event.dataTransfer.getData("application/reactflow")
      );
      if (typeof droppedData.type !== "string") {
        return;
      }

      const selectedEdge = edges.find((edge: Edge) => edge.id === id);
      if (!selectedEdge) return;
      let { source, target } = selectedEdge;

      let newNodes: Node[] = [];
      let newEdges: Edge[] = [];

      const sourceNode = nodes.find((node: Node) => node.id === source);
      if (!sourceNode) return;

      if (droppedData.type === "LOGIC") {
        // Add Timer node if source node is not a Timer node
        if (sourceNode.type !== "LOGIC" && sourceNode.type !== "TIMING") {
          const timerNode = createNewNode(
            uuid(),
            "TIMING",
            { x: sourceNode.position.x, y: sourceNode.position.y + 100 },
            {
              label: "Time Delay",
              days_delay: 0,
              minutes_delay: 0,
              hours_delay: 4,
              config: { configured: true },
            }
          );

          const timerEdge = createNewEdge(
            source,
            timerNode.id,
            selectedEdge.sourceHandle ? selectedEdge.sourceHandle : null
          );

          newNodes.push(timerNode);
          newEdges.push(timerEdge);

          source = timerNode.id;
        }

        // Add the conditional node and its related edges
        const newNode = createNewNode(
          uuid(),
          droppedData.type,
          { x: 0, y: 0 },
          {
            label: droppedData.label,
            config: { configured: false },
          }
        );
        const exitNode = createNewNode(
          uuid(),
          "EXIT",
          { x: 0, y: 0 },
          {
            label: "Exit",
            config: {
              configured: true,
            },
          }
        );
        newNodes.push(newNode, exitNode);

        const newEdge1 = createNewEdge(
          source,
          newNode.id,
          selectedEdge.sourceHandle ? selectedEdge.sourceHandle : null
        );
        const newEdge2 = createNewEdge(newNode.id, target, "left");
        const newEdge3 = createNewEdge(newNode.id, exitNode.id, "right");
        newEdges.push(newEdge1, newEdge2, newEdge3);
      } else {
        const newNode = createNewNode(
          uuid(),
          droppedData.type,
          { x: 0, y: 0 },
          {
            label: droppedData.label,
            config: { configured: false },
          }
        );
        newNodes.push(newNode);

        const newEdge = createNewEdge(
          source,
          newNode.id,
          selectedEdge.sourceHandle ? selectedEdge.sourceHandle : null
        );
        const newEdge2 = createNewEdge(newNode.id, target, null);
        newEdges.push(newEdge, newEdge2);
      }

      setIsDragging(false);

      const edgeIndex = edges.findIndex((edge: Edge) => edge.id === id);
      const nodeIndex = nodes.findIndex((node: Node) => node.id === source);
      edges.splice(edgeIndex, 1, ...newEdges);
      nodes.splice(nodeIndex + 1, 0, ...newNodes);
      setGraphData({
        nodes: [...nodes],
        edges: [...edges],
      });
    } catch (error) {
      console.error("Failed to parse dropped data:", error);
    }
  };

  return { isDragging, setIsDragging, handleDropEvent };
};

export default useDragAndDrop;
