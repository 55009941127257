import React from "react";
import useDialog from "../../../hooks/useDialog";
import { useRefCallBack } from "../../../hooks/useRefCallBack";
import {
  createCampaignInitialValues,
  getCampaignSteps,
  getHeaderActions,
  getHeaderControlAction,
  resolveEjsTags,
} from "./CampaignPage.helpers";
import {
  ActivityDetailsView,
  CampaignCreateRequestView,
  WorkflowEmailTemplateView,
  CampaignSaveAction,
  Step,
  WorkflowEmailTemplateUpdateRequestView,
} from "./CampaignPage.types";
import {
  EditorType,
  Template,
  TemplateType,
} from "../../../models/template/template.types";
import { extractTemplateData } from "../../../models/template/templateUtils";
import {
  SegmentView,
  SenderDetailsIdentityView,
  SenderDetailsView,
} from "../../../app/GeneratedApi";
import { useConfigView } from "../../../Config.context";
import { defaultTemplateAttributes } from "./defaultConfig";
import { useWorkflowsBuilderPageView } from "../WorkflowsBuilderPage.context";
import {
  useGetSenderDetailsDefaultQuery,
  useGetSenderDetailsQuery,
  useGetWorkflowsEmailTemplateQuery,
  usePostWorkflowsEmailTemplateMutation,
  usePutWorkflowsEmailTemplateMutation,
} from "../../../app/useGeneratedApi";
import { StripoInstance } from "@tiny/stripo-editor/Stripo.types";

export function useCampaignPage({ id: currentNodeId }: { id: string }) {
  const { closeCampaignBuilder, getNode, updateNode, workflowId } =
    useWorkflowsBuilderPageView();

  const { config } = useConfigView();
  const appName = config?.appName || "";

  const activityTemplateAttributesData = defaultTemplateAttributes;
  const [campaignData, setCampaignData] =
    React.useState<WorkflowEmailTemplateView>(createCampaignInitialValues());
  const [activeStep, setActiveStep] = React.useState<Step>("details");
  const [detailsCompleted, setDetailsCompleted] =
    React.useState<boolean>(false);
  const [designCompleted, setDesignCompleted] = React.useState<boolean>(false);
  const [audienceCompleted, setAudienceCompleted] =
    React.useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<ActivityDetailsView>();
  const [requireSave, setRequireSave] = React.useState<CampaignSaveAction>({});
  const [sendEmailTestOpen, setSendEmailTestOpen] = React.useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = React.useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = React.useState(false);
  const [saveTemplateDialogOpen, setSaveTemplateDialogOpen] =
    React.useState(false);
  const [templateLoaded, setTemplateLoaded] = React.useState(false);
  const [templateEditing, setTemplateEditing] = React.useState(false);
  const [templateHistoryVisible, setTemplateHistoryVisible] =
    React.useState(false);
  const [templateType, setTemplateType] = React.useState<TemplateType>();
  const [templateEditorType, setTemplateEditorType] =
    React.useState<EditorType>("DRAG_AND_DROP");
  const [screenLoading, setScreenLoading] = React.useState(false);

  const nodeData = getNode(currentNodeId);
  const [id, setId] = React.useState(nodeData?.data?.template_id);

  const [selectedAudiences, setSelectedAudiences] = React.useState<
    SegmentView[]
  >([]);

  const campaignStatus = campaignData?.status;
  const title = campaignData?.name || "";
  const isCampaignCreated = !!id || campaignData?.subject;
  const activityId = "";
  const templateHtml = selectedTemplate?.templateHtml;
  const templateCss = selectedTemplate?.templateCss;

  const stripoRef = React.useRef<StripoInstance>(null);
  const compressRef = React.useRef(false);
  const smartAssistRef = React.useRef(false);

  const [isSaving, setIsSaving] = React.useState(false);

  const openSendEmailTest = React.useCallback(async () => {
    setSendEmailTestOpen(true);
  }, []);

  const closeSendEmailTest = React.useCallback(() => {
    setSendEmailTestOpen(false);
  }, []);

  const openPreviewDialog = React.useCallback(() => {
    setPreviewDialogOpen(true);
  }, []);

  const openSavetemplateDialog = React.useCallback(() => {
    setSaveTemplateDialogOpen(true);
  }, []);

  const closePreviewDialog = React.useCallback(() => {
    setPreviewDialogOpen(false);
  }, []);

  const openScheduleDialog = React.useCallback(() => {
    setScheduleDialogOpen(true);
  }, []);

  const closeScheduleDialog = React.useCallback(() => {
    setScheduleDialogOpen(false);
  }, []);

  const closeSaveTemplateDialog = React.useCallback(() => {
    setSaveTemplateDialogOpen(false);
  }, []);

  const [refetchTemplateSections, setRefetchTemplateSections] = React.useState<
    string[]
  >([]);

  const [shouldCreateHistoryPoint, setShouldCreateHistoryPoint] =
    React.useState(false);

  const steps = React.useMemo(() => {
    const areDetailsCompleted =
      !!campaignData?.senderDetailsId && !!campaignData?.subject;
    const designCompleted = !!campaignData?.templateHtml;
    return getCampaignSteps(
      id,
      areDetailsCompleted,
      designCompleted,
      audienceCompleted,
      smartAssistRef.current
    );
  }, [
    campaignData,
    detailsCompleted,
    designCompleted,
    audienceCompleted,
    id,
    smartAssistRef,
  ]);

  const firstIncompleteStep = React.useMemo(
    () => steps.find((s) => !s.completed)?.step,
    [steps]
  );
  const continueDetails = React.useCallback(() => {
    setActiveStep("design");
  }, []);

  const {
    data: campaignFetchedData,
    refetch: refetchCampaign,
    isLoading: isCampaignLoading,
  } = useGetWorkflowsEmailTemplateQuery(id!, {
    enabled: !!id,
    initialData: !id ? createCampaignInitialValues() : undefined,
    useErrorBoundary: true,
    onSuccess: (data: any) => {
      setCampaignData((prev) => ({
        ...prev,
        ...data,
      }));
      if (data?.subject) {
        setCampaignData((prev) => ({ ...prev, ...data }));
        if (data?.templateHtml && data?.templateCss) {
          setDetailsCompleted(true);
          setActiveStep("design");
          setSelectedTemplate({ ...data });
          setTemplateEditing(true);
          setTemplateEditorType(data?.editorType ?? "DRAG_AND_DROP");
        }
      } else {
        setCampaignData(createCampaignInitialValues());
      }
    },
  });

  const {
    mutateAsync: postWorkflowMutateAsync,
    isLoading: isPostWorkflowLoading,
  } = usePostWorkflowsEmailTemplateMutation();

  const {
    mutateAsync: putWorkflowMutateAsync,
    isLoading: isPutWorkflowLoading,
  } = usePutWorkflowsEmailTemplateMutation();

  const attributes = activityTemplateAttributesData?.attributes;
  const campaignTemplate = campaignData.activityId
    ? {
        activityId: campaignData.activityId,
        html: campaignData.templateHtml,
        css: campaignData.templateCss,
        editorType: campaignData.editorType ?? "DRAG_AND_DROP",
      }
    : null;

  const nextStepDisabled = React.useMemo(() => {
    const activeCampaignStep = steps.find((cs) => cs.step === activeStep);
    if (["details", "audience"].includes(activeStep)) {
      return !activeCampaignStep?.completed;
    } else if (activeStep === "design") {
      return selectedTemplate ? !templateLoaded : true;
    } else {
      return true;
    }
  }, [activeStep, selectedTemplate, steps, templateLoaded]);

  const changeTemplate = useRefCallBack(
    React.useCallback(() => {
      setTemplateEditing(false);
    }, [])
  );

  const headerActions = React.useMemo(() => {
    return getHeaderActions(
      activeStep,
      !!selectedTemplate,
      templateLoaded,
      activityId,
      {
        changeTemplate,
        openSendEmailTest,
        openPreviewDialog,
        openSavetemplateDialog,
      }
    );
  }, [
    activeStep,
    activityId,
    changeTemplate,
    campaignData?.status,
    openPreviewDialog,
    openSavetemplateDialog,
    openSendEmailTest,
    selectedTemplate,
    templateLoaded,
  ]);

  const saveStep = React.useCallback(() => {
    Object.keys(requireSave).forEach((k) => requireSave[k]?.());
  }, [requireSave]);

  const quitCampaign = closeCampaignBuilder;

  const toggleTemplateHistory = React.useCallback(() => {
    setTemplateHistoryVisible((prev) => !prev);
  }, []);

  const clearRequireSave = React.useCallback(() => {
    setRequireSave({});
  }, []);

  const saveDetails = async (
    newCampaignData: WorkflowEmailTemplateUpdateRequestView
  ) => {
    await saveCampaign({ ...campaignData, ...newCampaignData });
    setCampaignData((prev) => ({
      ...prev,
      ...newCampaignData,
    }));
  };

  const {
    data: senderDetailsData,
    refetch: refetchSenderDetails,
    isLoading: isSenderDetailsLoading,
  } = appName === "tinyEmail"
    ? useGetSenderDetailsQuery({ enabled: true })
    : useGetSenderDetailsDefaultQuery({ enabled: true });

  const assignSender = async ({
    senderDetailsId,
  }: {
    senderDetailsId: string;
  }) => {
    await saveCampaign({ ...campaignData, senderDetailsId });
  };

  const saveCampaign = async (
    details: WorkflowEmailTemplateUpdateRequestView
  ) => {
    if (isSaving) {
      return;
    }

    try {
      setIsSaving(true);
      if (id) {
        await putWorkflowMutateAsync({
          id,
          data: {
            ...details,
          },
        });
        updateNode({
          id: currentNodeId,
          data: {
            ...nodeData?.data,
            name: details.name,
          },
        });
      } else {
        const data = await postWorkflowMutateAsync({
          data: {
            nodeId: currentNodeId,
            workflowId,
            ...details,
            name: details?.name || "",
            subject: details?.subject || "",
          },
        });
        updateNode({
          id: currentNodeId,
          data: {
            name: details.name,
            template_id: data.id,
          },
        });
        setId(data.id);
      }
      await refetchCampaign();
    } finally {
      setIsSaving(false);
    }
  };

  const notifyRequireSave = React.useCallback(
    (action: { name: string; fn?: () => void }) => {
      setRequireSave((actions) => ({ ...actions, [action.name]: action.fn }));
    },
    []
  );

  const saveTemplate = async (
    template: ActivityDetailsView,
    force?: boolean
  ) => {
    await saveCampaign({
      ...campaignData,
      ...(template?.editorType === "DRAG_AND_DROP" && {
        activityId: template?.id,
      }),
      templateCss: template?.templateCss,
      templateHtml: template?.templateHtml,
      editorType: template?.editorType ?? "DRAG_AND_DROP",
    });
  };

  const getAndSaveTemplate = React.useCallback(
    async (force?: boolean) => {
      return new Promise<void>((resolve, reject) => {
        if (!selectedTemplate?.id) {
          resolve();
          return;
        }
        stripoRef.current?.getTemplate(async (html: string, css: string) => {
          const data: WorkflowEmailTemplateUpdateRequestView = {
            activityId: selectedTemplate.id,
            templateHtml: html,
            templateCss: css,
            editorType: selectedTemplate.editorType,
          };
          try {
            await saveTemplate({ ...data }, force);
            resolve();
          } catch (e: any) {
            reject(e);
          }
        });
      });
    },
    [saveTemplate, selectedTemplate]
  );

  const selectTemplate = async (
    template: Template,
    saved?: boolean,
    type?: TemplateType
  ) => {
    setTemplateType(type);
    const nextEditorType = (template as any).editorType;
    setTemplateEditorType(nextEditorType);
    const templateId = template.id;
    const nextTemplate = await resolveEjsTags(
      template,
      templateId,
      nextEditorType
    );
    await saveTemplate(nextTemplate);
    setTemplateEditing(true);
    if (template.id !== campaignTemplate?.activityId) {
      setShouldCreateHistoryPoint(true);
    }
  };

  const handleTemplateLoaded = useRefCallBack(() => {
    setScreenLoading(true);
    setTemplateLoaded(true);
    if (shouldCreateHistoryPoint) {
      stripoRef.current?.createVersionHistoryPointAsync();
      setShouldCreateHistoryPoint(false);
    }
    setScreenLoading(false);
  });

  const handleTemplateUnLoaded = React.useCallback(() => {
    setTemplateLoaded(false);
  }, []);

  const handleTemplateChange = React.useCallback(() => {
    getAndSaveTemplate();
  }, [getAndSaveTemplate]);

  const hideTemplateHistory = React.useCallback(() => {
    setTemplateHistoryVisible(false);
  }, []);

  const selectCustomTemplate = React.useCallback(
    async (html: string, css: string) => {
      const nextTemplate: ActivityDetailsView = {
        ...campaignData,
        name: campaignData?.name,
        templateHtml: html,
        templateCss: css,
        editorType: "HTML",
        id: "temp_id",
      };
      setTemplateType(undefined);
      setTemplateEditorType("HTML");
      await saveTemplate(nextTemplate);
      setSelectedTemplate(nextTemplate);
      setShouldCreateHistoryPoint(true);
      setTemplateEditing(true);
    },
    [campaignData]
  );
  const templateData = React.useMemo(
    () => extractTemplateData(attributes),
    [attributes]
  );

  const continueDesign = React.useCallback(async () => {
    try {
      compressRef.current = true;
      await getAndSaveTemplate(true);
      setDesignCompleted(true);
      setActiveStep("schedule");
    } finally {
      return;
    }
  }, [getAndSaveTemplate, id]);

  const emailMessage = React.useMemo(
    () => ({
      templateType,
      campaignTemplate,
      attributes,
      templateData,
    }),
    [attributes, campaignTemplate, templateData, templateType]
  );

  const gotoNextStep = React.useCallback(() => {
    if (activeStep === "details") {
      if (designCompleted) {
        setActiveStep("schedule");
      } else {
        continueDetails();
      }
    } else if (activeStep === "design") {
      continueDesign();
    } else {
      return;
    }
  }, [activeStep, continueDesign, continueDetails, firstIncompleteStep, id]);

  const headerControlAction = React.useMemo(() => {
    return getHeaderControlAction(activeStep, nextStepDisabled, {
      gotoNextStep,
      saveDesign: async () => {
        await getAndSaveTemplate();
        updateNode({
          id: currentNodeId,
          data: {
            ...nodeData?.data,
            config: {
              configured: true,
            },
          },
        });
        closeCampaignBuilder();
      },
    });
  }, [activeStep, nextStepDisabled]);

  const activeSenderDetails = React.useMemo(() => {
    const campaignSenderId = campaignData?.senderDetailsId;
    const filter = (item: SenderDetailsView) =>
      campaignSenderId ? item.id === campaignSenderId : !!item.defaults;
    return senderDetailsData?.senderDetailses?.find(filter);
  }, [campaignData?.senderDetailsId, senderDetailsData?.senderDetailses]);

  const reloadSenderDetails = useRefCallBack(
    React.useCallback(async () => {
      await refetchSenderDetails();
    }, [refetchSenderDetails])
  );

  const senderDetails = React.useMemo(
    () => senderDetailsData?.senderDetailses,
    [senderDetailsData?.senderDetailses]
  );

  React.useEffect(() => {
    if (nodeData?.data?.template_id) {
      setId(nodeData?.data?.template_id);
      refetchCampaign();
    } else {
      setId(undefined);
      setCampaignData(createCampaignInitialValues());
      setActiveStep("details");
      setSelectedTemplate(undefined);
      setTemplateEditing(false);
    }
  }, [nodeData]);

  return {
    details: campaignData,
    isCampaignLoading,
    firstIncompleteStep,
    steps,
    clearRequireSave,
    setActiveStep,
    headerActions,
    headerControlAction,
    quitCampaign,
    templateEditing,
    templateLoaded,
    title,
    toggleTemplateHistory,
    campaignStatus,
    campaignData,
    activeStep,
    activityId,
    campaignId: "",
    closePreviewDialog,
    closeSaveTemplateDialog,
    closeSendEmailTest,
    closeScheduleDialog,
    emailMessage,
    openPreviewDialog,
    openSavetemplateDialog,
    openScheduleDialog,
    openSendEmailTest,
    previewDialogOpen,
    refetchCampaign: () => {},
    requireSave,
    saveTemplateDialogOpen,
    sendEmailTestOpen,
    selectedTemplate,
    templateHtml,
    templateCss,
    saveStep,
    setDetailsCompleted,
    saveCampaign,
    saveDetails,
    isCampaignCreated,
    notifyRequireSave,
    handleTemplateChange,
    handleTemplateLoaded,
    handleTemplateUnLoaded,
    hideTemplateHistory,
    selectTemplate,
    stripoRef,
    templateEditorType,
    templateHistoryVisible,
    campaignTemplate,
    refetchTemplateSections,
    selectCustomTemplate,
    scheduleDialogOpen,
    screenLoading,
    setScreenLoading,
    assignSender,
    assignSenderLoading: false,
    activeSenderDetails,
    reloadSenderDetails,
    senderDetails,
    isSenderDetailsLoading,
    id,
    isSaving,
  };
}
