import React, { useEffect } from "react";
import { SenderDetailsView } from "../../../../../app/GeneratedApi";

import { Form, TruncateTooltip } from "../../../../../components";
import {
  Alert,
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useBreakpoints from "../../../../../hooks/useBreakpoints";
import { useCampaignPageView } from "../../CampaignPage.context";
import EditPanel from "../../EditPanel";
import { useSenderPanel } from "./SenderPanel.hooks";
import { Controller } from "react-hook-form";
import { ChevronDown } from "@tiny/icons";

function SenderPanel(): JSX.Element {
  const { lgUp } = useBreakpoints();

  const {
    details,
    senderDetails,
    activeSenderDetails,
    assignSenderLoading,
    reloadSenderDetails,
  } = useCampaignPageView();

  const {
    control,
    editing,
    assign,
    edit,
    form,
    assignSenderError,
    closeButtonLabel,
    editDisabled,
    submitForm,
    formValues,
    setValue,
  } = useSenderPanel();

  const buttonVariant = React.useMemo(
    () => (editing || !details?.senderDetailsId ? "contained" : "outlined"),
    [details?.senderDetailsId, editing]
  );
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <EditPanel
        buttonDisabled={editDisabled}
        buttonLoading={false}
        closedButtonLabel={closeButtonLabel}
        buttonVariant={buttonVariant}
        empty={!details?.senderDetailsId}
        mt={lgUp ? 8 : 4}
        onClose={() => assign()}
        onOpen={edit}
        open={editing}
        openedButtonLabel="Save"
        title="Sender"
        subtitle={
          <Typography variant="p2" mt={-2} mb={0} color="grey" component="p">
            Who is sending this email?
          </Typography>
        }
      >
        {editing ? (
          <Box mt={6}>
            <Form form={form} onSubmit={submitForm}>
              <Grid container spacing={5}>
                {assignSenderError && (
                  <Grid item xs={12}>
                    <Alert severity="warning">{assignSenderError}</Alert>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Controller
                    name="sender"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        options={senderDetails || []}
                        value={value}
                        limitTags={3}
                        filterSelectedOptions
                        popupIcon={<ChevronDown />}
                        onChange={(event, newValue) => {
                          setValue("email", newValue?.email ?? "");
                          onChange(newValue);
                        }}
                        componentsProps={{
                          paper: {
                            sx: {
                              fontSize: 14,
                            },
                          },
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Name"
                            placeholder="Sender"
                            variant="outlined"
                          />
                        )}
                        getOptionLabel={(option: any) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          return option?.name || "";
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        label="Email address"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                          value: formValues.email,
                        }}
                        placeholder="Email Address"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Form>
          </Box>
        ) : (
          details?.senderDetailsId &&
          activeSenderDetails && (
            <>
              <Divider sx={{ mb: 6, mt: 6 }} />
              <Box display="flex" sx={{ gridGap: 20 }}>
                <Box minWidth={0}>
                  <TruncateTooltip>
                    <Typography variant="label1" color="grey">
                      {activeSenderDetails?.name}
                    </Typography>
                  </TruncateTooltip>
                </Box>
                <Box minWidth={0}>
                  <TruncateTooltip>
                    <Typography variant="label1" color="grey" fontWeight="bold">
                      {activeSenderDetails?.email}
                    </Typography>
                  </TruncateTooltip>
                </Box>
              </Box>
            </>
          )
        )}
      </EditPanel>
    </>
  );
}

export default SenderPanel;
