import React from "react";

export interface Pageable {
  page?: number;

  size?: number;

  sort?: string[];
}

interface UseSearchableResult {
  handlePageChange: (page: number) => void;
  handleSearchChange: (value: string) => void;
  handleSizeChange: (size: number) => void;
  handleSortChange: (sort: string[]) => void;
  pageable: Required<Pageable>;
  search: string;
  searchDebounced: string;
  changePage: (page: number) => void;
  changeSearch: (value: string) => void;
  changeSize: (size: number) => void;
  changeSort: (sort: string[]) => void;
}

function useSearchable(
  pageableDefault: Required<Pageable>,
  pageName: string
): UseSearchableResult {
  const storagePageSize = 5;

  const [state, setState] = React.useState({
    search: "",
    searchDebounced: "",
    pageable: { ...pageableDefault, size: storagePageSize },
  });

  const handleSearchChange = React.useCallback((value: string) => {
    setState((prev) => ({
      ...prev,
      search: value,
    }));
  }, []);

  const handlePageChange = React.useCallback((page: number) => {
    setState((prev) => ({
      ...prev,
      pageable: {
        ...prev.pageable,
        page,
      },
    }));
  }, []);

  const handleSizeChange = React.useCallback((size: number) => {
    setState((prev) => ({
      ...prev,
      pageable: {
        ...prev.pageable,
        page: 0,
        size,
      },
    }));
  }, []);

  const handleSortChange = React.useCallback((sort: string[]) => {
    setState((prev) => ({
      ...prev,
      pageable: {
        ...prev.pageable,
        page: 0,
        sort,
      },
    }));
  }, []);

  React.useEffect(() => {
    const handler = window.setTimeout(() => {
      if (state.searchDebounced === state.search) {
        return;
      }
      setState((prev) => ({
        ...prev,
        searchDebounced: state.search,
        pageable: {
          ...prev.pageable,
          page: 0,
        },
      }));
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [state.search, state.searchDebounced]);

  const changePage = handlePageChange;
  const changeSearch = handleSearchChange;
  const changeSize = handleSizeChange;
  const changeSort = handleSortChange;

  return {
    handlePageChange,
    handleSearchChange,
    handleSizeChange,
    handleSortChange,
    pageable: state.pageable,
    search: state.search,
    searchDebounced: state.searchDebounced,
    changePage,
    changeSearch,
    changeSize,
    changeSort,
  };
}

export default useSearchable;
