import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import {
  CampaignTestEmailsView,
  CampaignRemainedTestEmailsView,
  IdsView,
  ActivityFilterView,
  PageActivityIdentityView,
  EmailActivityTemplateFilterView,
  PageEmailActivityTemplateIdentityView,
  TemplateTagsView,
  SenderDetailsesView,
  EditorTokenView,
  ActivityMultipleDeleteView,
  SegmentIdentitiesView1,
  SegmentFilterView,
  SegmentPageSegmentView,
  CreateWorkflowResponseView,
  WorkflowCreateRequestView,
  WorkflowDetailsView,
  PageWorkflowInfoView,
  WorkflowFilterView,
  WorkflowEmailTemplateCreateRequestView,
  WorkflowEmailTemplateCreateResponseView,
  WorkflowEmailTemplateUpdateRequestView,
  WorkflowEmailTemplateUpdateResponseView,
  WorkflowEmailTemplateView,
  WorkflowCloneRequestView,
  WorkflowTemplateFilterView,
  PageWorkflowTemplateInfoView,
  WorkflowTemplateView,
  AlbertAudienceResponseView,
} from "./GeneratedApi";
import { useConfigView } from "./../Config.context";
import { api } from "./apiInstance";

export interface ErrorEntity {
  errorCode: string;
  errorMessage: string;
}

export interface Error {
  message?: string;
  response?: {
    status?: number;
    data?: {
      message?: string;
      messageDetails?: string;
      errorCodes?: ErrorEntity[];
    };
  };
}

export function useGetWorkflowTemplateDetailsByIdQuery(
  id: string,

  options?: UseQueryOptions<WorkflowTemplateView, Error>
): UseQueryResult<WorkflowTemplateView, Error> {
  const key = ["GET", `/workflow/template/${id}`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.getWorkflowTemplateById(id);
      return result.data;
    },
    options
  );
}

export function usePutWorkflowTemplatesGetQuery(
  data: WorkflowTemplateFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: UseQueryOptions<PageWorkflowTemplateInfoView, Error>
): UseQueryResult<PageWorkflowTemplateInfoView, Error> {
  const key = ["PUT", "/workflow/template/", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putWorkflowTemplateFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutWorkflowsFindFilterQuery(
  data: WorkflowFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: UseQueryOptions<PageWorkflowInfoView, Error>
): UseQueryResult<PageWorkflowInfoView, Error> {
  const key = ["PUT", "/workflow", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putWorkflowFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function useGetWorkflowsEmailTemplateQuery(
  id: string,
  options?: UseQueryOptions<WorkflowEmailTemplateView, Error>
): UseQueryResult<WorkflowEmailTemplateView, Error> {
  const key = ["GET", "/workflow-email-template", id, options];
  return useQuery(
    key,
    async () => {
      const result = await api.getWorkflowEmailTemplate(id);
      return result.data;
    },
    options
  );
}

export function usePostWorkflowsEmailTemplateMutation(
  options?: UseMutationOptions<
    WorkflowEmailTemplateCreateResponseView,
    Error,
    {
      data: WorkflowEmailTemplateCreateRequestView;
    },
    unknown
  >
): UseMutationResult<
  WorkflowEmailTemplateCreateResponseView,
  Error,
  {
    data: WorkflowEmailTemplateCreateRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ data }: { data: WorkflowEmailTemplateCreateRequestView }) => {
      const result = await api.postWorkflowEmailTemplate(data);
      return result.data;
    },
    options
  );
}

export function usePutWorkflowsEmailTemplateMutation(
  options?: UseMutationOptions<
    WorkflowEmailTemplateUpdateResponseView,
    Error,
    {
      id: string;
      data: WorkflowEmailTemplateUpdateRequestView;
    },
    unknown
  >
): UseMutationResult<
  WorkflowEmailTemplateUpdateResponseView,
  Error,
  {
    id: string;
    data: WorkflowEmailTemplateUpdateRequestView;
  },
  unknown
> {
  return useMutation(
    async ({
      id,
      data,
    }: {
      id: string;
      data: WorkflowEmailTemplateUpdateRequestView;
    }) => {
      const result = await api.putWorkflowEmailTemplate(id, data);
      return result.data;
    },
    options
  );
}

export function usePutActivityFindFilterQuery(
  data: ActivityFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: UseQueryOptions<PageActivityIdentityView, Error>
): UseQueryResult<PageActivityIdentityView, Error> {
  const key = ["PUT", "/account-html-template/find/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putActivityFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function usePutActivityTemplateEmailFindFilterQuery(
  data: EmailActivityTemplateFilterView,

  query: { page?: number; size?: number; sort?: string[] },

  options?: UseQueryOptions<PageEmailActivityTemplateIdentityView, Error>
): UseQueryResult<PageEmailActivityTemplateIdentityView, Error> {
  const key = ["PUT", "/html-template/email/find/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putActivityTemplateEmailFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function useGetSenderDetailsQuery(
  options?: UseQueryOptions<SenderDetailsesView, Error>
): UseQueryResult<SenderDetailsesView, Error> {
  const key = ["GET", "/sender-details"];
  const { config } = useConfigView();
  const appName = config?.appName || "";
  return useQuery(
    key,
    async () => {
      const result = await api.getSenderDetails(undefined, appName);
      return result.data;
    },
    options
  );
}

export function useGetSenderDetailsDefaultQuery(
  options?: UseQueryOptions<SenderDetailsesView, Error>
): UseQueryResult<SenderDetailsesView, Error> {
  const key = ["GET", "/sender-details/default"];
  const { config } = useConfigView();
  const appName = config?.appName || "";
  return useQuery(
    key,
    async () => {
      const result = await api.getSenderDetailsDefault(undefined, appName);
      return { senderDetailses: [result.data] } as SenderDetailsesView;
    },
    options
  );
}

export function useGetTemplateTagQuery(
  options?: UseQueryOptions<TemplateTagsView, Error>
): UseQueryResult<TemplateTagsView, Error> {
  const key = ["GET", "/template-tag"];
  return useQuery(
    key,
    async () => {
      const result = await api.getTemplateTag();
      return result.data;
    },
    options
  );
}

export function useGetEditorTokenQuery(
  options?: UseQueryOptions<EditorTokenView, Error>
): UseQueryResult<EditorTokenView, Error> {
  const key = ["GET", "/editor/token"];
  return useQuery(
    key,
    async () => {
      const result = await api.getEditorToken();
      return result.data;
    },
    options
  );
}

export function usePutSegmentFindIdsQuery(
  data: IdsView,

  options?: UseQueryOptions<SegmentIdentitiesView1, Error>
): UseQueryResult<SegmentIdentitiesView1, Error> {
  const key = ["PUT", "/segment/find/ids", data];
  return useQuery(
    key,
    async () => {
      const result = await api.putSegmentFindIds(data);
      return result.data;
    },
    options
  );
}

export function usePutSegmentFindFilterQuery(
  data: SegmentFilterView,

  query: {
    page?: number;
    size?: number;
    sort?: string[];
    countAllCustomers?: boolean;
  },

  options?: UseQueryOptions<SegmentPageSegmentView, Error>
): UseQueryResult<SegmentPageSegmentView, Error> {
  const key = ["PUT", "/segment/find/filter", data, query];
  return useQuery(
    key,
    async () => {
      const result = await api.putSegmentFindFilter(data, query);
      return result.data;
    },
    options
  );
}

export function useGetAlbertAudienceQuery(
  query: { sort: string },

  options?: UseQueryOptions<AlbertAudienceResponseView, Error>
): UseQueryResult<AlbertAudienceResponseView, Error> {
  const key = ["GET", "/audience", query];
  return useQuery(
    key,
    async () => {
      const result = await api.useGetAlbertAudience(query);
      return result.data;
    },
    options
  );
}

export function usePutWorkflowByIdMutation(
  options?: UseMutationOptions<
    WorkflowDetailsView,
    Error,
    {
      id: string;
      data: WorkflowCreateRequestView;
    },
    unknown
  >
): UseMutationResult<
  WorkflowDetailsView,
  Error,
  {
    id: string;
    data: WorkflowCreateRequestView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: WorkflowCreateRequestView }) => {
      const result = await api.putWorkflowById(id, data);
      return result.data;
    },
    options
  );
}

export function useDeleteWorkflowByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.deleteWorkflowById(id);
    return result.data;
  }, options);
}

export function usePutWorkflowPauseByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.putWorkflowPauseById(id);
    return result.data;
  }, options);
}

export function usePutWorkflowActivateByIdMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;
  },
  unknown
> {
  return useMutation(async ({ id }: { id: string }) => {
    const result = await api.putWorkflowActivateById(id);
    return result.data;
  }, options);
}

export function usePutCampaignTestEmailsByIdMutation(
  options?: UseMutationOptions<
    CampaignRemainedTestEmailsView,
    Error,
    {
      id: string;

      data: CampaignTestEmailsView;
    },
    unknown
  >
): UseMutationResult<
  CampaignRemainedTestEmailsView,
  Error,
  {
    id: string;

    data: CampaignTestEmailsView;
  },
  unknown
> {
  return useMutation(
    async ({ id, data }: { id: string; data: CampaignTestEmailsView }) => {
      const result = await api.putWorkflowsTestEmailsById(id, data);
      return result.data;
    },
    options
  );
}

export function usePutActivityFavoriteByIdAndIsFavoriteMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      isFavorite: boolean;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    isFavorite: boolean;
  },
  unknown
> {
  return useMutation(
    async ({ id, isFavorite }: { id: string; isFavorite: boolean }) => {
      const result = await api.putActivityFavoriteByIdAndIsFavorite(
        id,
        isFavorite
      );
      return result.data;
    },
    options
  );
}

export function usePutActivityTemplateEmailFavoriteByIdAndIsFavoriteMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      id: string;

      isFavorite: boolean;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    id: string;

    isFavorite: boolean;
  },
  unknown
> {
  return useMutation(
    async ({ id, isFavorite }: { id: string; isFavorite: boolean }) => {
      const result =
        await api.putActivityTemplateEmailFavoriteByIdAndIsFavorite(
          id,
          isFavorite
        );
      return result.data;
    },
    options
  );
}

export function usePostWorkflowMutation(
  options?: UseMutationOptions<
    CreateWorkflowResponseView,
    Error,
    {
      data: WorkflowCreateRequestView;
    },
    unknown
  >
): UseMutationResult<
  CreateWorkflowResponseView,
  Error,
  {
    data: WorkflowCreateRequestView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: WorkflowCreateRequestView }) => {
    const result = await api.postWorkflow(data);
    return result.data;
  }, options);
}

export function useGetWorkflowByIdQuery(
  id: string,
  options?: UseQueryOptions<WorkflowDetailsView, Error>
): UseQueryResult<WorkflowDetailsView, Error> {
  const key = ["GET", `/workflow/${id}`, id];
  return useQuery(
    key,
    async () => {
      const result = await api.getWorkflowById(id);
      return result.data;
    },
    options
  );
}

export function usePostWorkflowCloneMutation(
  options?: UseMutationOptions<
    CreateWorkflowResponseView,
    Error,
    {
      data: WorkflowCloneRequestView;
    },
    unknown
  >
): UseMutationResult<
  CreateWorkflowResponseView,
  Error,
  {
    data: WorkflowCloneRequestView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: WorkflowCloneRequestView }) => {
    const result = await api.postWorkflowClone(data);
    return result.data;
  }, options);
}

export function useDeleteActivityMutation(
  options?: UseMutationOptions<
    void,
    Error,
    {
      data: ActivityMultipleDeleteView;
    },
    unknown
  >
): UseMutationResult<
  void,
  Error,
  {
    data: ActivityMultipleDeleteView;
  },
  unknown
> {
  return useMutation(async ({ data }: { data: ActivityMultipleDeleteView }) => {
    const result = await api.deleteActivity(data);
    return result.data;
  }, options);
}
