import React from "react";

import { Typography, Box, List } from "@mui/material";
import { CheckBold as TickIcon } from "@tiny/icons";
import { FeatureListProps, Feature } from "./FeaturesList.types";

function RequirementList(props: FeatureListProps) {
  const { list, component = "ul", ...other } = props;

  return (
    <Box
      {...other}
      component={component}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        padding: 0,
      }}
    >
      {list.map((item, index) => (
        <List
          key={index}
          sx={{
            display: "flex",
            flex: `0 0 ${100 / (props.columns || 1)}%`,
            paddingTop: "12px",
            alignItems: "center",
            gap: 2,
          }}
        >
          <TickIcon color={item.enabled ? "primary" : "disabled"} />
          <Typography variant="p2" color="grey" fontWeight="regular">
            {item.name}
          </Typography>
        </List>
      ))}
    </Box>
  );
}

export default RequirementList;
