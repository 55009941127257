import React from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import { CaretRight, Info2Outline, ChevronRight } from "@tiny/icons";

type SelectTriggerCardProps = {
  title: string;
  subtitle: string;
  active: boolean;
  onClick: VoidFunction;
};

const SelectTriggerCard = ({
  title,
  subtitle,
  active,
  onClick,
}: SelectTriggerCardProps) => {
  return (
    <Box
      sx={{
        p: 4,
        backgroundColor: "#F0F2F8",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">{title}</Typography>
          <Info2Outline color="action" />
        </Box>
        <Typography variant="p2" color="grey.700">
          {subtitle}
        </Typography>
      </Box>
      <Box
        sx={{
          pt: 2,
        }}
      >
        {active ? (
          <Button
            sx={{
              pl: 0,
              display: "flex",
              flexDirection: "row",
              gap: 0.5,
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => onClick()}
          >
            <Typography
              sx={{
                fontWeight: 400,
              }}
            >
              Select
            </Typography>
            <ChevronRight />
          </Button>
        ) : (
          <Typography
            sx={(theme) => ({
              color: theme.palette.primary[100],
            })}
          >
            Coming Soon
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SelectTriggerCard;
