import React, { useEffect } from "react";
import { useIsFetching, useIsMutating } from "react-query";
import { useSnackbar as useNotiStackBar } from "notistack";

export function useLoadingSnackbar() {
  const [show, setShow] = React.useState(true);
  const [uiDisabled, setUiDisabled] = React.useState(false);
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const timer = React.useRef<any>();

  const showSnackbar = React.useCallback(() => setShow(true), []);

  const hideSnackbar = React.useCallback(() => {
    setShow(false);
    setUiDisabled(true);
    timer.current = setTimeout(() => {
      setUiDisabled(false);
    }, 3000);
  }, []);

  const open = React.useMemo(() => {
    showSnackbar();
    return !uiDisabled && show && isFetching + isMutating > 0;
  }, [isFetching, isMutating, uiDisabled]);

  React.useEffect(() => {
    return clearTimeout(timer.current);
  }, []);

  const { closeSnackbar, enqueueSnackbar } = useNotiStackBar();
  useEffect(() => {
    if (open) {
      enqueueSnackbar("Loading...", {
        variant: "default",
        persist: true,
        key: "loading",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    } else {
      closeSnackbar("loading");
    }
  }, [open]);

  return { show, showSnackbar, hideSnackbar };
}
