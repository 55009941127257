import React from "react";
import { Box, Button, Icon, IconButton, Typography } from "@mui/material";
import { CloseBig } from "@tiny/icons";
import useSelectTrigger from "./SelectTrigger.hooks";
import SelectTriggerCard from "./SelectTriggerCard";

const SelectTrigger = () => {
  const { hideDrawer, triggers } = useSelectTrigger();
  return (
    <Box
      sx={{
        py: 4,
        px: 5,
        display: "flex",
        flexDirection: "column",
        width: { xs: "320px", xl: "20vw" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h2">Select a Trigger</Typography>
          <Typography variant="p2" color="grey.600">
            Click to select workflow trigger{" "}
          </Typography>
        </Box>
        <IconButton onClick={() => hideDrawer()}>
          <CloseBig />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          mt: 6,
        }}
      >
        {triggers.map((trigger, index) => (
          <SelectTriggerCard
            key={trigger.title}
            title={trigger.title}
            subtitle={trigger.subtitle}
            active={trigger.active}
            onClick={trigger.onclick}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SelectTrigger;
