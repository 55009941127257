import React, { useCallback } from "react";
import useDialog from "../../hooks/useDialog";
import { GraphStructure, Workflow } from "./WorkflowsBuilder/types";
import { ElkNode } from "elkjs";
import elkLayout from "./WorkflowsBuilder/graph";
import { getIdFromUrl } from "./WorkflowBuilderPage.utils";
import {
  areAllNodesConfigured,
  atleastOneActionNodePresent,
  isAllConditionalSplitValid,
  isLogicNodePresentInAssignToAudience,
  isMoreThanFiveConditionalNode,
  isSubsequentActionPreset,
  isSubsequentDelayPreset,
  isTimerAfterActionNode,
} from "./WorkflowsBuilder/workflowValidator";
import {
  useGetWorkflowByIdQuery,
  usePutWorkflowActivateByIdMutation,
  usePutWorkflowByIdMutation,
  usePutWorkflowPauseByIdMutation,
} from "../../app/useGeneratedApi";
import { useConfigView } from "../../Config.context";
import { enqueueSnackbar } from "notistack";
import { useDialogInvokers } from "@tiny/lib";
import WorkflowEditConfirmationDialogContent from "../../customComponents/WorkflowEditPausedDialog";

export const useWorkflowsBuilderPage = () => {
  const workflowId = getIdFromUrl();
  const { config } = useConfigView();
  const { quitApp = () => {}, navigateTo = () => {} } = config || {};

  const { requestSmallDialog, exitDialog } = useDialogInvokers();

  const {
    data: workflowDataResponse,
    isLoading: isWorkflowDataResponseLoading,
    refetch: refetchWorkflow,
    error: getWorkflowError,
  } = useGetWorkflowByIdQuery(workflowId);

  const {
    mutateAsync: updateWorkflowMutate,
    isLoading: isUpdateWorkflowLoading,
  } = usePutWorkflowByIdMutation();

  const isLoading = isWorkflowDataResponseLoading || isUpdateWorkflowLoading;

  const [workflowData, setWorkflowData] = React.useState<Workflow>({
    structure: { nodes: [], edges: [] },
    id: "",
    name: "",
    description: "",
    template_id: "",
    status: "DRAFT",
  });

  const [currentNodeId, setCurrentNodeId] = React.useState<
    string | undefined
  >();
  const clearCurrentNodeId = () => setCurrentNodeId(undefined);

  const [downloadEnabled, setDownloadEnabled] = React.useState(false);
  const [viewMode, setViewMode] = React.useState(false);
  const [campaignBuilderOpen, setCampaignBuilderOpen] = React.useState(false);
  const {
    mutateAsync: activateWorkflowsMutate,
    isLoading: activateWorkflowsLoading,
  } = usePutWorkflowActivateByIdMutation();

  const {
    mutateAsync: pauseWorkflowsMutate,
    isLoading: pauseWorkflowsLoading,
  } = usePutWorkflowPauseByIdMutation();

  const pauseScheduledWorkflow = React.useCallback(async (id: string) => {
    try {
      await pauseWorkflowsMutate({ id });
      setWorkflowData((prev) => ({
        ...prev,
        status: "PAUSED",
      }));
      setViewMode(false);
      exitDialog();
    } catch (error) {}
  }, []);

  const activateWorkflow = React.useCallback(async (id: string) => {
    try {
      await activateWorkflowsMutate({ id });
      setWorkflowData((prev) => ({
        ...prev,
        status: "ACTIVE",
      }));
      navigateTo("/main/workflows");
    } catch (error) {}
  }, []);

  const quitWithoutSaving = () => {
    if (quitApp) {
      quitApp();
    }
  };
  const {
    dialogVisible: quitWorkflowsVisible,
    hideDialog: hideQuitWorkflows,
    showDialog: showQuitWorkflows,
  } = useDialog();

  //Component functions
  const saveWorkflow = async (data: Workflow) => {
    try {
      await updateWorkflowMutate({ id: workflowId, data });
    } catch (error) {}
  };

  const openCampaignBuilder = (id: string) => {
    setCurrentNodeId(id);
    setCampaignBuilderOpen(true);
  };

  const closeCampaignBuilder = () => {
    clearCurrentNodeId();
    setCampaignBuilderOpen(false);
  };

  const showPauseConfirmation = (id: string) => {
    requestSmallDialog({
      titleProps: { children: "Please confirm", variant: "label1R" },
      contentProps: {
        dividers: false,
        sx: {
          border: "none",
        },
        children: (
          <WorkflowEditConfirmationDialogContent
            onContinue={() => pauseScheduledWorkflow(id)}
            onCancel={exitDialog}
          />
        ),
      },
    });
  };

  const getHeaderControlAction = () => {
    return {
      disabled: false,
      type: "text",
      text: "Actions",
      onClick: () => {},
      children: [
        {
          text: "Activate",
          onClick: () => activateWorkflow(workflowId),
          hidden: false,
          disabled:
            workflowData.status === "ACTIVE" ||
            !getRestrictionOptions().every((n) => n.isValid),
        },
        {
          text: "Pause",
          onClick: () => showPauseConfirmation(workflowId),
          hidden: false,
          disabled: workflowData.status !== "ACTIVE",
        },
      ],
    };
  };

  // Macro Workflow Builder functions

  const setGraphData = (graphData: GraphStructure) => {
    elkLayout(graphData.nodes, graphData.edges)
      .then((graph: any) => {
        const nodesForFlow =
          graph?.children?.map((node: ElkNode) => ({
            ...graphData.nodes.find((n) => n.id === node.id),
            position: { x: node.x, y: node.y },
          })) || [];

        const updatedGraph = {
          ...workflowData,
          structure: {
            nodes: nodesForFlow,
            edges: graph.edges,
          },
        };
        setWorkflowData(updatedGraph);
      })
      .catch((error) => {
        console.error("Error updating graph:", error);
      });
  };

  const triggers = [
    {
      title: "Rules",
      subtitle: "Set your own rules for trigger.",
      active: true,
      onclick: () => {
        setWorkflowData((prev) => ({
          ...prev,
          triggerType: "RULE",
        }));
      },
    },
    {
      title: "Webhooks",
      subtitle: "Add your webhook for trigger.",
      active: false,
      onclick: () => {},
    },
  ];

  const updateNode = ({
    id,
    data,
    updateLabel = false,
  }: {
    id: string;
    data: any;
    updateLabel?: boolean;
  }) => {
    const updatedNodes = [...workflowData.structure.nodes];
    const index = workflowData.structure?.nodes?.findIndex(
      (node) => node.id === id
    );
    updatedNodes[index] = {
      ...updatedNodes[index],
      data: {
        ...updatedNodes[index].data,
        ...data,
        ...(!updateLabel && { label: updatedNodes[index].data.label }),
      },
    };

    if (index >= 0) {
      setWorkflowData((prev) => ({
        ...prev,
        structure: {
          ...prev.structure,
          nodes: updatedNodes,
        },
      }));
    } else {
      console.log("error saving");
    }
  };

  const getNode = (nodeId: string) => {
    return workflowData.structure?.nodes?.find((node) => node.id === nodeId);
  };

  const getRestrictionOptions = useCallback(() => {
    const atleastOneActionNodePresentRule = atleastOneActionNodePresent(
      workflowData.structure
    );
    const { isValid, notConfiguredNodes } = areAllNodesConfigured(
      workflowData.structure
    );
    const isSubsequentDelayPresetRule = isSubsequentDelayPreset(
      workflowData.structure
    );

    const isSubsequentActionPresetRule = isSubsequentActionPreset(
      workflowData.structure
    );
    const isAllConditionalSplitValidRule = isAllConditionalSplitValid(
      workflowData.structure
    );

    const isMoreThanFiveConditionalNodeRule = isMoreThanFiveConditionalNode(
      workflowData.structure
    );

    const isTimerAfterActionRule = isTimerAfterActionNode(
      workflowData.structure
    );

    const isLogicNodePresentInAssignToAudienceRule =
      isLogicNodePresentInAssignToAudience(workflowData.structure);

    return [
      {
        label: `Node(s) [ ${notConfiguredNodes
          ?.map((node) => node.type)
          .join(", ")} ] are not configured!`,
        isValid: isValid,
      },
      {
        label: "Each flow must end with atleast one ACTION node",
        isValid: atleastOneActionNodePresentRule,
      },
      {
        label: "There should be at least one TIMER node before LOGIC node",
        isValid: isAllConditionalSplitValidRule,
      },
      {
        label: "Subsequent TIMING nodes cannot be applied.",
        isValid: isSubsequentDelayPresetRule,
      },
      {
        label: "Subsequent Action nodes cannot be applied.",
        isValid: isSubsequentActionPresetRule,
      },
      {
        label:
          "Only Five LOGIC ( Conditional ) Nodes can be present in a workflow.",
        isValid: isMoreThanFiveConditionalNodeRule,
      },
      {
        label: "A Timer Node is required between two Action Nodes",
        isValid: isTimerAfterActionRule,
      },
      {
        label:
          "Logic nodes cannot be used with [ User added form Form submission]",
        isValid: isLogicNodePresentInAssignToAudienceRule,
      },
    ];
  }, [workflowData]);

  React.useEffect(() => {
    if (workflowDataResponse) {
      setWorkflowData((prevState) => {
        return { ...prevState, ...workflowDataResponse };
      });
      if (workflowDataResponse.status === "ACTIVE") {
        setViewMode(true);
      }
      if (workflowDataResponse.status !== "DRAFT") {
        setDownloadEnabled(true);
      }
    }
  }, [workflowDataResponse]);

  React.useEffect(() => {
    if (workflowData.id && !viewMode) {
      saveWorkflow(workflowData);
    }
  }, [workflowData]);

  React.useEffect(() => {
    if (getWorkflowError?.message) {
      enqueueSnackbar(`Error Fetching workflow with id ${workflowId}.`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        autoHideDuration: 10000,
      });
    }
  }, [getWorkflowError]);

  const goBackToWorkflowPage = () => {
    navigateTo("/main/workflows");
  };
  return {
    workflowId,
    currentNodeId,
    campaignBuilderOpen,
    closeCampaignBuilder,
    downloadEnabled,
    getHeaderControlAction,
    hideQuitWorkflows,
    openCampaignBuilder,
    quitWithoutSaving,
    quitWorkflowsVisible,
    saveWorkflow,
    showQuitWorkflows,
    setGraphData,
    workflowData,
    setWorkflowData,
    refetchWorkflow,
    triggers,
    isLoading,
    updateNode,
    setViewMode,
    viewMode,
    getNode,
    getRestrictionOptions,
    goBackToWorkflowPage,
  };
};
