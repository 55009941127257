export interface ResponseHeaders {
  "content-disposition"?: string;
  "content-length"?: string;
  "content-type"?: string;
}

export function downloadFileData(data: any, headers: ResponseHeaders): void {
  const contentDisposition =
    headers?.["content-disposition"]?.split("fileName=");
  const fileName =
    (contentDisposition?.length || 0) > 1 && contentDisposition?.[1];
  const url = window.URL.createObjectURL(data);
  const a = document.createElement("a");
  a.href = url;
  if (fileName) {
    a.download = fileName;
    a.click();
  }
}
