import React from "react";
import { WorkflowsCardsProps } from "./WorkflowCards.types";
import WorkflowCard from "./WorkflowTemplateCard/WorkflowTemplateCard";
import { Box, Typography } from "@mui/material";
import { useWorkflowTemplateSelection } from "./WorkflowTemplateSelection.hooks";
import { PlusCircleOutline } from "@tiny/icons";
import { CreateWorkflowDialog } from "../CreateWorkflow/CreateWorkflow";
import useCreateWorkflow from "../CreateWorkflow/CreateWorkflow.hooks";
import { CreateCard } from "./WorkflowTemplateCard/CreateWorkflowCard";
import ComponentLoader from "../ComponentLoader/ComponentLoader";

function WorkflowTemplateSelection() {
  const { templates, isWorkflowTemplatesLoading } =
    useWorkflowTemplateSelection();
  const createWorkflow = useCreateWorkflow();

  if (isWorkflowTemplatesLoading) {
    return <ComponentLoader height="400px" />;
  }

  return (
    <Box>
      <Typography
        variant="h4"
        fontWeight="semiBold"
        mb={4}
        color="grey.100"
        noWrap
      >
        Pick from predefined workflow
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "1fr",
          sm: "1fr 1fr",
          md: "repeat(3, minmax(200px, 1fr))",
        }}
        gap={4}
        justifyContent="center"
      >
        <CreateCard openWorkflowDialog={createWorkflow.openWorkflowDialog} />
        {templates?.map((workflow) => (
          <WorkflowCard
            key={workflow.id}
            name={workflow.name}
            description={workflow.description}
            infoDescription={workflow.infoDescription}
            imagePath={workflow.imagePath}
            status={workflow.status}
            onActionClick={() => createWorkflow.openWorkflowDialog(workflow.id)}
          />
        ))}
      </Box>
      <CreateWorkflowDialog {...createWorkflow} />
    </Box>
  );
}

export default WorkflowTemplateSelection;
