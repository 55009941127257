import React from "react";
import {
  Tabs,
  HtmlPreview,
  MobilePreview,
  TruncateTooltip,
} from "../../components";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseBig, CloseSmall } from "@tiny/icons";
import { useTemplatePreviewDialog } from "./TemplatePreviewDialog.hooks";
import { TemplatePreviewDialogProps } from "./TemplatePreviewDialog.types";

function TemplatePreviewDialog(props: TemplatePreviewDialogProps) {
  const {
    mainActionName,
    onMainActionClick,
    open,
    onClose,
    templateName,
    subjectText,
    previewText,
  } = props;

  const { activeTab, setActiveTab, handleExited, html, css } =
    useTemplatePreviewDialog(props);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      TransitionProps={{ onExited: handleExited }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TruncateTooltip arrow>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflowWrap: "break-word",
              }}
              variant="h3"
            >
              {templateName}
            </Typography>
          </TruncateTooltip>
          <IconButton onClick={onClose}>
            <CloseSmall />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{ mt: subjectText || previewText ? 0 : 3, overflow: "hidden" }}
      >
        {subjectText && (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflowWrap: "break-word",
              my: 1,
            }}
            variant="label1R"
            color="grey.90"
            component="p"
          >
            Subject: {subjectText}
          </Typography>
        )}
        {previewText && (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflowWrap: "break-word",
              my: 1,
              mb: 4,
            }}
            variant="label1R"
            color="grey.90"
            component="p"
          >
            Preview: {previewText}
          </Typography>
        )}
        <Tabs value={activeTab} onChange={setActiveTab}>
          <Tab label="Desktop" />
          <Tab label="Mobile" />
        </Tabs>
        <Box mt={6} display="flex" justifyContent="center">
          {activeTab === 0 ? (
            <HtmlPreview
              html={html}
              css={css}
              width="100%"
              height="500px"
              contentWidth={800}
              scrollable
            />
          ) : (
            <MobilePreview
              width="260px"
              height="500px"
              html={html}
              css={css}
              contentWidth={360}
              scrollable
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={onMainActionClick}>
          {mainActionName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TemplatePreviewDialog;
