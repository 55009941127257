import React, { useEffect } from "react";
import { Box, Chip, MenuItem, Typography } from "@mui/material";
import { FlagFill, MoreHorizontal } from "@tiny/icons";
import { Handle, NodeProps, Position } from "reactflow";
import { useDrawerInvokers } from "@tiny/lib";
import { NodeData } from "../types";
import { MenuIconButton } from "../../../../components";
import TriggerForm from "../../../../customComponents/TriggerForm";

const TriggerNode = ({ data, id }: NodeProps<NodeData>) => {
  const { showDrawer } = useDrawerInvokers();
  const { configured } = data.config;

  useEffect(() => {
    if (!configured) {
      showDrawer({
        children: <TriggerForm triggerId={id} />,
      });
    }
  }, [configured]);

  const eventMapper = (event: "CUSTOMER_ASSIGNED_TO_SEGMENT" | string) => {
    const eventLabels: { [key: string]: string } = {
      CUSTOMER_ASSIGNED_TO_SEGMENT: "User Added from Form Submission",
    };
    return (
      eventLabels[event as keyof typeof eventLabels] || event?.replace("_", " ")
    );
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          background: configured ? theme.palette.common.white : "transparent",
          border: `1px solid ${theme.palette.grey[80]}`,
          padding: 3,
          paddingBottom: 4,
          borderRadius: 3,
          display: "flex",
          flexDirection: "column",
          width: "400px",
          cursor: "pointer",
        })}
        onClick={(e) => {
          showDrawer({
            children: <TriggerForm triggerId={id} />,
          });
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mr: -1 }}
        >
          <Box display="flex" alignItems="center">
            <FlagFill
              sx={(theme) => ({
                fontSize: 20,
                mr: 1,
                color: theme.palette.primary[125],
              })}
            />
            <Typography
              variant="label1"
              color="grey.80"
              sx={(theme) => ({
                textTransform: "uppercase",
                color: theme.palette.primary[125],
              })}
            >
              {data.label} {"›"} {eventMapper(data.event || "")}
            </Typography>
          </Box>
          <MenuIconButton
            icon={<MoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          >
            <MenuItem
              onClick={(e) => {
                showDrawer({
                  children: <TriggerForm triggerId={id} />,
                });
              }}
            >
              Edit
            </MenuItem>
          </MenuIconButton>
        </Box>
        <Box mt={2}>
          {configured ? (
            <Chip
              sx={(theme) => ({
                height: theme.spacing(5),
                backgroundColor: theme.palette.primary[100],
                color: theme.palette.common.white,
                mr: 1,
                fontSize: theme.spacing(3),
                lineHeight: theme.spacing(3),
                "& .MuiChip-label": { fontWeight: 700 },
              })}
              label="Configured"
            />
          ) : (
            <Chip
              sx={(theme) => ({
                height: theme.spacing(5),
                backgroundColor: theme.palette.yellow[150],
                color: theme.palette.common.white,
                mr: 1,
                fontSize: theme.spacing(3),
                lineHeight: theme.spacing(3),
                "& .MuiChip-label": { fontWeight: 700 },
              })}
              label="Not-Configured"
            />
          )}
        </Box>
      </Box>

      <Handle
        type="source"
        position={Position.Bottom}
        style={{ backgroundColor: "transparent", border: "none" }}
      />
    </>
  );
};

export default TriggerNode;
