import { useDrawerInvokers } from "@tiny/lib";
import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useWorkflowsBuilderPageView } from "../../pages/WorkflowsBuilderPage/WorkflowsBuilderPage.context";

const useTimerDelayForm = ({ id }: { id: string }) => {
  const { updateNode, getNode, viewMode } = useWorkflowsBuilderPageView();
  const [saveTimerDisabled, setSaveTimerDisabled] = React.useState(true);
  const { hideDrawer } = useDrawerInvokers();
  const node = getNode(id);

  const methods = useForm({
    defaultValues: {
      days: node?.data?.days_delay || 0,
      hours: node?.data?.hours_delay || 0,
      minutes: node?.data?.minutes_delay || 0,
    },
    mode: "onSubmit",
  });
  const { handleSubmit, control } = methods;
  const goBack = () => {
    hideDrawer();
  };

  const formValues = useWatch({ control });
  const onSubmit = handleSubmit(async (data) => {
    updateNode({
      id,
      data: {
        days_delay: data.days,
        hours_delay: data.hours,
        minutes_delay: 0,
        config: {
          configured: true,
        },
      },
    });
    hideDrawer();
  });

  React.useEffect(() => {
    const incomingState = JSON.stringify({
      days: node?.data?.days_delay,
      hours: node?.data?.hours_delay,
      minutes: node?.data?.minutes_delay,
    });
    const currentState = JSON.stringify({
      days: formValues?.days,
      hours: formValues?.hours,
      minutes: formValues?.minutes,
    });
    if (incomingState === currentState) {
      setSaveTimerDisabled(true);
    } else {
      setSaveTimerDisabled(false);
    }
  }, [node, formValues]);

  return {
    goBack,
    onSubmit,
    methods,
    formValues,
    control,
    saveTimerDisabled,
    viewMode,
  };
};

export default useTimerDelayForm;
