import React from "react";

import { PageTabesProps } from "./types";
import Tabs from "../Tabs";
import Tab from "../Tab";

function PageTabs(props: PageTabesProps) {
  const { tabValue, changeTab, navInfo, nav } = props;
  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={changeTab}
        variant="scrollable"
        info={navInfo}
      >
        {nav.map((item) => (
          <Tab key={item.name} label={item.name} disabled={item.disabled} />
        ))}
      </Tabs>
    </div>
  );
}

export default PageTabs;
