import React from "react";

import { usePutWorkflowsFindFilterQuery } from "../../app/useGeneratedApi";
import { mapToWorkflowStatuses } from "./WorkflowsPage.helpers";

export function useWorkflowsPage() {
  const [activeNavItem, setActiveNavItem] = React.useState("All");

  const onNavChange = (value: string) => {
    setActiveNavItem(value);
  };

  const workflowStatuses = React.useMemo(
    () => mapToWorkflowStatuses(activeNavItem),
    [activeNavItem]
  );

  const { data, isLoading: isWorkflowsLoading } =
    usePutWorkflowsFindFilterQuery(
      {},
      {},
      {
        keepPreviousData: true,
      }
    );

  const numberOfWorkflows = data?.totalElements || 0;
  return {
    activeNavItem,
    isWorkflowsLoading,
    onNavChange,
    workflowStatuses,
    numberOfWorkflows,
  };
}
