import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Controller, FormProvider } from "react-hook-form";
import useSelectRules from "./SelectRules.hooks";
import { ChevronBigLeft } from "@tiny/icons";
import AudienceSelector from "./AudienceSelector/AudienceSelector";

const SelectRules = ({ id }: { id: string }) => {
  const {
    methods,
    control,
    formValues,
    goBack,
    onSubmit,
    viewMode,
    hideDrawer,
    saveRulesDisabled,
    audiences,
    handleAudienceSearch,
    loadMoreAudiences,
    isLoadingMore,
    hasMoreResults,
    isLoading,
    isTinyEmail,
    isTinyAlbert,
  } = useSelectRules({ id });

  const ruleTypes = [
    { value: "shopping-activity", label: "Shopping Activity", active: true },
    {
      value: "assigned-audience",
      label: "User Added from Form Submission",
      active: true,
    },
  ];

  const sources = [{ value: "SHOPIFY", label: "Shopify" }];

  const eventTypes = [
    { value: "CHECKOUT_STARTED", label: "Checkout Started" },
    { value: "ORDER_PLACED", label: "Order Placed" },
    { value: "ORDER_FULFILLED", label: "Order Fulfilled" },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} style={{ height: "100%" }}>
        <Box
          sx={{
            py: 4,
            px: 6,
            display: "flex",
            flexDirection: "column",
            width: { xs: "320px", xl: "20vw" },
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <IconButton
              sx={{ px: 0 }}
              onClick={() => {
                viewMode ? hideDrawer() : goBack();
              }}
            >
              <ChevronBigLeft color="success" />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography variant="h2">Rules</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              mt: 6,
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 7,
              }}
            >
              <Grid item xs={12}>
                <Controller
                  name="ruleType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        sx={(theme) => ({
                          backgroundColor: theme.palette.background.paper,
                          px: 2,
                        })}
                        id="rule-select-label"
                        shrink
                      >
                        Rule Type
                      </InputLabel>
                      <Select
                        size="small"
                        fullWidth
                        label="Activity Type"
                        placeholder="Select activity type"
                        displayEmpty
                        renderValue={(item) => {
                          if (!item.length) return "Select activity type";
                          return ruleTypes.find((type) => type.value === item)
                            ?.label;
                        }}
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                      >
                        {ruleTypes.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            disabled={!option.active}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              {formValues.ruleType === "shopping-activity" && (
                <>
                  <Grid item xs={12}>
                    <Controller
                      name="source"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel
                            sx={(theme) => ({
                              backgroundColor: theme.palette.background.paper,
                              px: 2,
                            })}
                            id="source-select-label"
                            shrink
                          >
                            Source
                          </InputLabel>
                          <Select
                            size="small"
                            fullWidth
                            label="Source"
                            placeholder="Select source"
                            displayEmpty
                            renderValue={(item) => {
                              if (!item.length) return "Select source";
                              return sources.find((type) => type.value === item)
                                ?.label;
                            }}
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                          >
                            {sources.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  {formValues.source === "SHOPIFY" && (
                    <Grid item xs={12}>
                      <Controller
                        name="event"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel
                              sx={(theme) => ({
                                backgroundColor: theme.palette.background.paper,
                                px: 2,
                              })}
                              id="event-select-label"
                              shrink
                            >
                              Event
                            </InputLabel>
                            <Select
                              size="small"
                              fullWidth
                              label="Event"
                              placeholder="Select event"
                              displayEmpty
                              renderValue={(item) => {
                                if (!item.length) return "Select event";
                                return eventTypes.find(
                                  (type) => type.value === item
                                )?.label;
                              }}
                              value={field.value}
                              onChange={(e) => field.onChange(e.target.value)}
                            >
                              {eventTypes.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  )}

                  {formValues.event && (
                    <Grid item xs={12}>
                      <Controller
                        name="from"
                        control={control}
                        defaultValue="start-of-workflow"
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel
                              sx={(theme) => ({
                                backgroundColor: theme.palette.background.paper,
                                px: 2,
                              })}
                              id="from-select-label"
                              shrink
                            >
                              From
                            </InputLabel>
                            <Select
                              size="small"
                              fullWidth
                              label="From"
                              disabled
                              value={field.value}
                            >
                              <MenuItem value="start-of-workflow">
                                Start of Workflow
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}

              {formValues.ruleType === "assigned-audience" && (
                <Grid item xs={12}>
                  <Controller
                    name="audiences"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AudienceSelector
                        value={value || []}
                        onChange={onChange}
                        options={audiences}
                        onInputChange={handleAudienceSearch}
                        isLoading={isLoadingMore || isLoading}
                        hasMore={hasMoreResults}
                        onScrollEnd={loadMoreAudiences}
                        placeholder="Search Audience"
                        label="Audience"
                        enableInfiniteScroll={isTinyEmail}
                      />
                    )}
                  />
                </Grid>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            <Button
              onClick={() => {
                viewMode ? hideDrawer() : goBack();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={
                (formValues.ruleType === "shopping-activity" &&
                  !formValues.event) ||
                (formValues.ruleType === "assigned-audience" &&
                  (!formValues.audiences ||
                    formValues.audiences.length === 0)) ||
                saveRulesDisabled ||
                viewMode
              }
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default SelectRules;
