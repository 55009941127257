import React from "react";
import { ErrorMessageProps } from "./ErrorMessage.types";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { CloseBig } from "@tiny/icons";
function ErrorMessage(props: ErrorMessageProps): JSX.Element {
  const { message, title } = props;

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Box
            sx={(theme) => ({
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: 120,
              height: 120,
              borderRadius: 999,
              "&:before": {
                content: "''",
                position: "absolute",
                width: 80,
                height: 80,
                background: "#fff",
                borderRadius: 999,
              },
              "& > svg, > img": {
                position: "relative",
                width: 40,
                height: 40,
                color: theme.palette.red[125],
              },
            })}
          >
            <CloseBig />
          </Box>
          <Typography
            variant="h1"
            align="center"
            color={"red.125"}
            m={0}
            mt={5}
            fontWeight="bold"
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Typography variant="h4R" mt={2} align="center" color="grey">
            {message}
          </Typography>
        </Box>
        <Box
          sx={{ paddingTop: "12px", display: "flex", justifyContent: "center" }}
        >
          <Button variant={"contained"} onClick={refreshPage}>
            Refresh
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ErrorMessage;
