import { WorkflowInfoView } from "../../../app/GeneratedApi";

export function getChipColor(status: WorkflowInfoView["status"]) {
  switch (status) {
    case "DRAFT":
      return "yellow";
    case "ACTIVE":
      return "primary";
    case "PAUSED":
      return "red";
    default:
      return "grey";
  }
}
