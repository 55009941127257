import React from "react";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import { ErrorBoundaryProps } from "./ErrorBoundary.types";
import { ErrorBoundary as ErrorBoundaryPackage } from "react-error-boundary";

function ErrorBoundary(props: ErrorBoundaryProps) {
  const { children } = props;

  const fallback = () => {
    return <ErrorMessage title="Error" message="Something went wrong!" />;
  };

  return (
    <ErrorBoundaryPackage fallback={fallback()}>
      {children}
    </ErrorBoundaryPackage>
  );
}

export default ErrorBoundary;
