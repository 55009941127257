import { Feature } from "../../../../../../../components/FeaturesList/FeaturesList.types";
import { isBlankString } from "../../../../../../../utils/stringUtils";

export function getCampaignSubjectdRequirements(subject: string): Feature[] {
  return [
    {
      name: "Try to use no more than 9 words",
      enabled: !isBlankString(subject) && hasMaxWords(subject, 9),
    },
    {
      name: "Avoid using more than 60 characters",
      enabled: !isBlankString(subject) && hasMaxLength(subject, 60),
    },
    {
      name: "Avoid using more than 3 punctuation marks",
      enabled: !isBlankString(subject) && hasMaxSpecialChar(subject, 3),
    },
    {
      name: "Avoid using all caps in subject ",
      enabled: !isBlankString(subject) && hasSomeLowerCase(subject),
    },
    {
      name: "Personalize subject using merge tags for e.g. [CUSTOMER_FIRST_NAME]",
      enabled: true,
    },
  ];
}

export function hasMaxWords(str: string, maxCount: number) {
  const parts = str.split(" ");
  return parts.length <= maxCount;
}

export function hasMaxLength(str: string, maxLength = 64) {
  return str.length <= maxLength;
}

export function hasMaxSpecialChar(str: string, count: number) {
  const exp = /[!@#$%^&*()_+\-={};':"\\|,.<>/?]/g;
  const matches = str.match(exp) || [];
  return matches.length <= count;
}

export function hasSomeLowerCase(str: string) {
  const regex = /[a-z]+/g;
  return regex.test(str);
}
