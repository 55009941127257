import React from "react";

import { useQuitWorkflows } from "./QuitWorkflows.hooks";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Divider,
  Box,
  IconButton,
} from "@mui/material";
import { CloseBig } from "@tiny/icons";

function QuitWorkflows() {
  const {
    quitWorkflowsVisible,
    hideQuitWorkflows,
    quitWithoutSave,
    quitWithSave,
  } = useQuitWorkflows();

  return (
    <Dialog
      open={quitWorkflowsVisible}
      onClose={hideQuitWorkflows}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 0,
            m: 0,
          }}
        >
          <Typography variant="h3">Quit workflow</Typography>
          <IconButton onClick={hideQuitWorkflows}>
            <CloseBig />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="inherit" color="grey">
          Would you like to save the workflow?
        </Typography>
        <Divider
          sx={{
            mt: 4,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={quitWithoutSave}>
          No thanks
        </Button>
        <Button variant="contained" onClick={quitWithSave}>
          Yes, save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QuitWorkflows;
