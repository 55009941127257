import React from "react";
import { useInfiniteQuery } from "react-query";
import {
  EditorType,
  Template,
  TemplateTag,
  TemplateType,
} from "../../models/template/template.types";
import { api } from "../../app/apiInstance";

const FETCH_SIZE = 18;

export default function useInfiniteTemplatesQuery(
  saved: boolean | undefined,
  type: TemplateType | undefined,
  search: string,
  selectedTags: TemplateTag[],
  isFavorite: boolean | undefined,
  editorType: EditorType | undefined,
  enabled: boolean
) {
  const tagIds = React.useMemo(() => {
    return selectedTags.map((tag) => tag.id!);
  }, [selectedTags]);

  const keySaved = React.useMemo(() => {
    return ["templates", "saved", search, tagIds, isFavorite, editorType];
  }, [isFavorite, search, tagIds, editorType]);

  const fetchSaved = React.useCallback(
    async ({ pageParam = 0 }) => {
      const res = await api.putActivityFindFilter(
        { searchNameText: search, tagIds, isFavorite, editorType },
        { page: pageParam, sort: ["updatedAt,DESC"], size: FETCH_SIZE }
      );
      return res.data;
    },
    [isFavorite, search, tagIds, editorType]
  );

  const options = React.useMemo(() => {
    return {
      getNextPageParam: (lastPage: any) => {
        if (lastPage.last) {
          return undefined;
        }
        const page = (lastPage.pageable as any)?.pageNumber;
        if (page !== undefined) {
          return page + 1;
        }
        return false;
      },
      enabled: enabled && saved,
    };
  }, [enabled, saved]);

  const querySaved = useInfiniteQuery(keySaved, fetchSaved, options);

  const keyOther = React.useMemo(() => {
    return ["templates", "other", type, search, tagIds, isFavorite];
  }, [isFavorite, search, tagIds, type]);

  const fetchOther = React.useCallback(
    async ({ pageParam = 0 }) => {
      const res = await api.putActivityTemplateEmailFindFilter(
        { searchNameText: search, tagIds, types: [type!], isFavorite },
        { page: pageParam, sort: ["updatedAt,DESC"], size: FETCH_SIZE }
      );
      return res.data;
    },
    [isFavorite, search, tagIds, type]
  );

  const queryOther = useInfiniteQuery(keyOther, fetchOther, options);

  const query = saved ? querySaved : queryOther;
  const fetchMore = query.fetchNextPage;
  const hasMore = query.hasNextPage || false;
  const total = query.data?.pages?.[0]?.totalElements;
  const loading = query.isLoading;
  const pages = query.data?.pages;
  const refetch = query.refetch;
  const isEmpty =
    query.isSuccess &&
    (pages?.length === 0 || pages?.[0].content?.length === 0);

  const templates = React.useMemo(() => {
    const result: Template[] = [];
    pages?.forEach((page) => {
      page.content?.forEach((template) => {
        result.push(template);
      });
    });
    return result;
  }, [pages]);

  return {
    fetchMore,
    templates,
    hasMore,
    total,
    loading,
    refetch,
    isEmpty,
  };
}
