import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useWorkflowsBuilderPageView } from "../../pages/WorkflowsBuilderPage/WorkflowsBuilderPage.context";
import { useDrawerInvokers } from "@tiny/lib";
import { NodeData } from "../../pages/WorkflowsBuilderPage/WorkflowsBuilder/types";

export const transformNodeToFormValues = (nodeData: NodeData | undefined) => {
  if (nodeData?.condition) {
    const baseCondition =
      nodeData.condition.conditions[1]?.conditions[0]?.conditions[0];
    return {
      ruleType: baseCondition?.attributeType || "",
      event: baseCondition?.attributeName || "",
      from: "start-of-workflow",
    };
  }
};

const useConditionalSplitForm = ({ id }: { id: string }) => {
  const { updateNode, getNode, setWorkflowData, viewMode } =
    useWorkflowsBuilderPageView();
  const { hideDrawer } = useDrawerInvokers();
  const node = getNode(id);
  const [saveRulesDisabled, setSaveRulesDisabled] = React.useState(true);

  const methods = useForm({
    defaultValues: transformNodeToFormValues(node?.data),
    mode: "onChange",
  });

  const { handleSubmit, control } = methods;
  const formValues = useWatch({ control });

  const goBack = () => {
    setWorkflowData((prev) => ({
      ...prev,
      triggerType: undefined,
    }));
    hideDrawer();
  };

  const onSubmit = handleSubmit(async (data) => {
    const nodeData = {
      condition: {
        type: "combined",
        conditionType: "AND",
        conditions: [
          {
            type: "combined",
            conditionType: "AND",
            conditions: [],
          },
          {
            type: "combined",
            conditionType: "OR",
            conditions: [
              {
                type: "combined",
                conditionType: "AND",
                conditions: [
                  {
                    type: "base",
                    attributeType: data.ruleType,
                    attributeName: data.event,
                    criteria: "SINCE_BEGINING",
                  },
                ],
              },
            ],
          },
        ],
      },
    };

    updateNode({
      id,
      data: {
        ...nodeData,
        config: {
          configured: true,
        },
      },
    });
    hideDrawer();
  });

  React.useEffect(() => {
    const incomingState = JSON.stringify({
      ruleType: node?.data?.ruleType || "",
      event: node?.data?.event || "",
      from: "start-of-workflow",
    });

    const currentState = JSON.stringify({
      ruleType: formValues.ruleType || "",
      event: formValues.event || "",
      from: "start-of-workflow",
    });

    setSaveRulesDisabled(incomingState === currentState);
  }, [node, formValues]);

  return {
    goBack,
    onSubmit,
    methods,
    formValues,
    control,
    saveRulesDisabled,
    viewMode,
  };
};

export default useConditionalSplitForm;
