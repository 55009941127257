import { useDrawerInvokers } from "@tiny/lib";
import React, { useEffect } from "react";
import { TimerType } from "./TriggerForm.types";

const useTriggerForm = () => {
  const [selectedTimerType, setSelectedTimerType] = React.useState<
    string | undefined
  >("");

  const [showSelectTrigger, setShowSelectTrigger] =
    React.useState<boolean>(false);

  const timerTypes: TimerType[] = [
    {
      label: "One Time",
      value: "one-time",
      active: true,
    },
    {
      label: "Trigger on recurring basis",
      value: "recurring-basis",
      active: true,
    },
    {
      label: "Trigger on customer property",
      value: "customer-property",
      active: false,
    },
  ];

  const { hideDrawer, showDrawer } = useDrawerInvokers();
  return {
    showSelectTrigger,
    setShowSelectTrigger,
    hideDrawer,
    showDrawer,
    selectedTimerType,
    setSelectedTimerType,
    timerTypes,
  };
};

export default useTriggerForm;
