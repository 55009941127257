import React from "react";
import { usePutWorkflowTemplatesGetQuery } from "../../app/useGeneratedApi";

export function useWorkflowTemplateSelection() {
  const {
    data,
    refetch: reloadCampaigns,
    isLoading: isWorkflowTemplatesLoading,
  } = usePutWorkflowTemplatesGetQuery(
    {
      pageNumber: 1,
      size: 10,
    },
    {},
    {
      keepPreviousData: true,
    }
  );

  return {
    templates: data?.content,
    reloadCampaigns,
    isWorkflowTemplatesLoading,
  };
}
