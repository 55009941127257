import React from "react";
import { WorkflowInfoView } from "../../../app/GeneratedApi";
import MoreButton from "../../../components/MoreButton";
import useBreakpoints from "../../../hooks/useBreakpoints";
import WorkflowStatus from "../WorkflowStatus/WorflowStatus";
import { useWorkflowRow } from "./WorkflowRow.hooks";
import { formatDate } from "../../../utils/dateUtils";
import { workflowDateFormat } from "../WorkflowList.helpers";
import {
  Box,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import {
  ChevronBigDown,
  ChevronBigUp,
  Copy,
  Edit,
  TrashFull,
} from "@tiny/icons";
import TruncateTooltip from "../../../components/TruncateTooltip";
import { useConfigView } from "../../../Config.context";

export interface WorkflowRowProps {
  item: WorkflowInfoView;
  onPause: (id: string) => void;
  onStart: (id: string) => void;
  onDelete: (id: string) => void;
  onClone: (id: string) => void;
  onExport: (item: WorkflowInfoView) => void;
  onSelectRow: (id: string) => void;
  isSelected: (id: string) => boolean;
  showChip?: boolean;
  mutationLoading: boolean;
  showDetails?: boolean;
  isClickable?: boolean;
}

function WorkflowRow(props: WorkflowRowProps) {
  const { item, showChip, mutationLoading, showDetails, isClickable } = props;
  const { mdUp } = useBreakpoints();
  const {
    workflowLink,
    checked,
    detailsOpen,
    moreActions,
    selectWorkflow,
    toggleDetails,
    editEnabled,
  } = useWorkflowRow(props);
  const { config } = useConfigView();
  const { navigateTo } = config || {};

  let templateName = null;
  if (mdUp) {
    templateName = isClickable ? (
      <Box
        sx={{ cursor: "pointer" }}
        onClick={() => navigateTo && navigateTo(workflowLink)}
      >
        <TruncateTooltip arrow>
          <Typography
            variant="label1R"
            color="primary"
            sx={{
              display: "inline-block",
              verticalAlign: "center",
              maxWidth: 280,
              mr: 2,
            }}
          >
            {item.name}
          </Typography>
        </TruncateTooltip>
      </Box>
    ) : (
      <Typography
        variant="label1R"
        color="primary"
        sx={{
          display: "inline-block",
          verticalAlign: "center",
          maxWidth: 280,
          mr: 2,
        }}
        noWrap
      >
        {item.name}
      </Typography>
    );
  } else {
    if (editEnabled) {
      templateName = (
        <Typography variant="label1R" color="grey" sx={{ mr: 2 }}>
          {item.name}
        </Typography>
      );
    } else {
      templateName = isClickable ? (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => navigateTo && navigateTo(workflowLink)}
        >
          <Typography variant="label1R" color="primary" sx={{ mr: 2 }}>
            {item.name}
          </Typography>
        </Box>
      ) : (
        <Typography variant="label1R" color="primary" sx={{ mr: 2 }}>
          {item.name}
        </Typography>
      );
    }
  }

  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        padding: "12px 0 12px 0",
        "&::before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: -24,
          right: -24,
          borderTop: "1px solid",
          borderColor: (theme) => theme.palette.grey[200],
        },
        "&:last-child::after": {
          content: "''",
          position: "absolute",
          bottom: 0,
          left: -24,
          right: -24,
          borderTop: "1px solid",
          borderColor: (theme) => theme.palette.grey[200],
        },
      }}
    >
      <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
        {/* <Box sx={{ width: 32 }}>
          <Checkbox
            color="primary"
            checked={checked}
            onChange={selectWorkflow}
            size="small"
          />
        </Box> */}
        <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
          {templateName}
          {showChip && <WorkflowStatus status={item.status} size="small" />}
          <br />
          <Box display="inline-flex" alignItems="center">
            {!mdUp && (
              <IconButton
                onClick={toggleDetails}
                sx={{
                  width: 24,
                  height: 24,
                  padding: 0,
                  marginLeft: 8,
                  "& svg": {
                    width: 16,
                    height: 16,
                    color: (theme) => theme.palette.primary.main,
                  },
                }}
              >
                {detailsOpen ? <ChevronBigUp /> : <ChevronBigDown />}
              </IconButton>
            )}
          </Box>
        </Box>
        {mdUp && showDetails && (
          <Box sx={{ flexBasis: "44%" }}>
            <Details item={item} />
          </Box>
        )}
        <Box sx={{ position: "relative" }}>
          <MoreButton
            actions={moreActions}
            clickParam={item}
            disabled={mutationLoading}
            iconMap={{
              Edit: <Edit />,
              Delete: <TrashFull />,
              Clone: <Copy />,
            }}
          />
        </Box>
      </Box>
      {!mdUp && (
        <Collapse in={detailsOpen}>
          <Box sx={{ position: "relative", paddingTop: 4, paddingLeft: 8 }}>
            <Details item={item} />
          </Box>
        </Collapse>
      )}
    </Box>
  );
}

export interface DetailsProps {
  item: WorkflowInfoView;
}

function Details(props: DetailsProps) {
  const { item } = props;
  return (
    <Grid container spacing={6}>
      <Grid item xs={6} md={6}>
        <Typography
          variant="label1R"
          color="grey.100"
          fontWeight={500}
          component="div"
          sx={{ mb: 1 }}
        >
          Rule
        </Typography>
        <Typography variant="label3R" color="grey.80" component="div" mt={2}>
          Trigger
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <Typography
          variant="label1R"
          color="grey.100"
          component="div"
          fontWeight={500}
          sx={{ mb: 1 }}
        >
          {item?.updatedAt && formatDate(item?.updatedAt, workflowDateFormat)}
        </Typography>
        <Typography variant="label3R" color="grey.80" component="div" mt={2}>
          Last updated
        </Typography>
      </Grid>
    </Grid>
  );
}

export default React.memo(WorkflowRow);
